import * as React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { useEffect } from "react"
import { DateTime } from "luxon"
import { LoaderState } from "kui-crm"
import useExpensesStore from "./store"
import Loading from "../../../../components/common/Loading"
import ExpensesTabsPanel from "./components/common/ExpensesTabsPanel"
import { getQueryParam } from "../../../../utils/service/functions"

function ApartmentExpenses() {
  const {
    overviewStore,
    selectedContract,
    fetchAvailableContracts,
    fetchLastOpenPeriod,
    periodStore,
    date,
    loader,
    updateExpensesPage,
    setDate,
  } = useExpensesStore()
  const defaultDate = getQueryParam("date")

  useEffect(() => {
    if (overviewStore.id && !defaultDate) {
      fetchLastOpenPeriod(overviewStore.id)
    }
    if (defaultDate) {
      const formattedDate = DateTime.fromFormat(defaultDate, "MM.yy")

      setDate(formattedDate)
    }
    return () => updateExpensesPage()
  }, [])

  useEffect(() => {
    if (overviewStore.id && date) {
      fetchAvailableContracts(overviewStore.id, date)
    }
  }, [overviewStore.id, date])

  useEffect(() => {
    if (overviewStore.id && selectedContract && date) {
      periodStore.getPeriodInfo(overviewStore.id, selectedContract.id, date)
    }
  }, [selectedContract?.id, date])

  if (loader.isLoading) return <Loading />

  return (
    <div key="static">
      <Box pt={3} pb={3}>
        <ExpensesTabsPanel />
      </Box>

      <LoaderState loader={loader} onlyError />
    </div>
  )
}

export default observer(ApartmentExpenses)

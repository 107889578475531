import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import {
  Loader,
  Paginator,
  SortingFilter,
  MultistepForm,
  toNumber,
} from "kui-utils"
import { ServiceContractModel } from "kui-crm/types"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import ServiceContractStore from "./ServiceContractStore"
import {
  FetchServiceContracts,
  PatchServiceContractParams,
} from "../../../types/api/service_contracts"
import ServiceContractsAgent from "../../../agent/ServiceContracts"
import { ServiceContractCreationFields } from "../forms/CreateServiceContractForm/types"
import {
  clearNotValidFields,
  transformToSnakeCase,
} from "../../../utils/service/mapper"
import { matchesAPISubjectRoles } from "../../../utils/content/matches"
import { getApiUtilitiesPayerParams } from "../../../utils/service/apiMapper"

const SCFormStages = [
  "apartment",
  "info",
  "common_terms",
  "additional_terms",
  "payers",
] as const

class ServiceContractsStore implements MultipleEntitiesStoreInterface {
  contracts: ServiceContractStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<
    ServiceContractCreationFields,
    typeof SCFormStages
  >

  constructor() {
    this.contracts = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.creationForm = new MultistepForm<
      ServiceContractCreationFields,
      typeof SCFormStages
    >(null, SCFormStages)
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<FetchServiceContracts>(
      ServiceContractsAgent.all(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    runInAction(() => {
      if (!err && res) {
        const mapper = (contract: ServiceContractModel) =>
          new ServiceContractStore(contract)

        this.contracts = this.paginator.getPageResponse<
          ServiceContractModel,
          ServiceContractStore
        >(res, this.contracts, mapper)
      } else {
        this.loader.setError("fetch service contracts", err)
      }
      this.loader.endLoading()
    })
  }

  createServiceContract = async (data: ServiceContractCreationFields) => {
    this.creationLoader.startLoading("creation service contract")

    const body: PatchServiceContractParams =
      ServiceContractsStore.getRequestBodyForCreationSC(data)

    const [err, res] = await to(ServiceContractsAgent.create(body))
    runInAction(() => {
      if (!err && res) {
        this.contracts = [...this.contracts, new ServiceContractStore(res)]
      } else {
        this.creationLoader.setError("creation service contract", err)
      }
      this.creationLoader.endLoading()
    })

    return res?.id
  }

  static getRequestBodyForCreationSC = (data: ServiceContractCreationFields) =>
    clearNotValidFields({
      ...transformToSnakeCase(data),
      owner_id: data.owner.id,
      min_rent_price: toNumber(data.minRentPrice),
      max_rent_price: toNumber(data.maxRentPrice),
      penalty_in_preparation: toNumber(data.penaltyInPreparation),
      apartment_id: data.apartment.id,
      start_date: data.startDate.toISODate(),
      sign_date: data.signDate.toISODate(),
      end_date: data.endDate?.toISODate(),
      number: data.number || null,
      advertising_period: data.advertisingPeriod,
      inspection_interval_month: data.inspections,
      notify_period_month: data.moratorium,
      taxes_type: data.agentType,
      utility_payers: data.utilityPayers.map((payer) =>
        getApiUtilitiesPayerParams(payer)
      ),
      fixed_costs: data.fixedCosts?.map((expense) => ({
        name: expense.name,
        value: toNumber(expense.cost),
        payer: matchesAPISubjectRoles[expense.payer],
      })),
      allowed_pets: data.petsAllowed,
      allowed_children: data.childrenAllowed,
      allowable_price_deviation: data.allowablePriceDeviation,
      long_term_type: data.rentPeriodType.includes("any")
        ? ["short", "medium", "long"]
        : data.rentPeriodType,
      folder_number: data.folderNumber,
    }) as PatchServiceContractParams
}

export default ServiceContractsStore

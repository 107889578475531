import React, { useState } from "react"
import { useWatch } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { GeolocationParams } from "kui-crm/types"
import getApartmentParamsInputs from "../../../../pages/ApartmentPage/forms/description/DescriptionFieldsWithMap/fields"
import Container from "../../../ui/Container"
import { ApartmentParamsInputType } from "../../../../pages/ApartmentPage/forms/description/ApartmentDescriptionForm/types"
import MetroBlock from "../../../../pages/ApartmentPage/forms/description/MetroBlock"
import DescriptionFields from "./DescriptionFields"
import { ApartmentDescriptionFieldsProps } from "./types"

const ApartmentDescriptionFields = (props: ApartmentDescriptionFieldsProps) => {
  const { form, disabled } = props
  const [coords, setCoords] = useState<GeolocationParams | null>({
    lat: Number(form.getValues("geolocation.lat")),
    lng: Number(form.getValues("geolocation.lng")),
  })

  const address = useWatch({
    control: form.control,
    name: "apartment.address",
  })
  const mainFields = getApartmentParamsInputs(address, setCoords)

  return (
    <Container>
      <Box pt={3}>
        <Grid container spacing={3}>
          {mainFields.map(({ size, ...input }: ApartmentParamsInputType) => (
            <Grid item xs={size} key={input.name}>
              <InputByType form={form} disabled={disabled} {...input} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <MetroBlock coords={coords} form={form} isEditing={!disabled} />
          </Grid>
        </Grid>
        <DescriptionFields form={form} disabled={disabled} />
      </Box>
    </Container>
  )
}

export default ApartmentDescriptionFields

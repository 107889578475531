import React, { useState } from "react"
import { observer } from "mobx-react"
import MultistepForm from "../../../../../common/MultistepForm"
import ImpairmentInfoStep from "./ImpairmentInfoStep"
import ImpairmentImagesStep from "./ImpairmentImagesStep"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import { ImpairmentsInspectionFormFields } from "./types"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"

const ImpairmentsInspectionForm = (props: InspectionFormProps) => {
  const { handleClose, shift } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const [impairments, setImpairments] = useState([0])

  const addImpairment = () => {
    setImpairments((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const handleSubmit = async () => {
    const inspectionId = await inspectionsStore.createImpairmentsInspection(
      formStore.fields as ImpairmentsInspectionFormFields
    )
    if (inspectionId) handleClose(inspectionId)
  }

  return (
    <MultistepForm stepShift={shift} form={formStore}>
      {impairments.map((impairment) => [
        <ImpairmentInfoStep key={`info_${impairment}`} index={impairment} />,
        <ImpairmentImagesStep
          key={`images_${impairment}`}
          index={impairment}
          addStep={addImpairment}
          handleSubmit={handleSubmit}
        />,
      ])}
    </MultistepForm>
  )
}

export default observer(ImpairmentsInspectionForm)

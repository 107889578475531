import React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps } from "kui-crm"
import RCCheckOutDateForm from "../../forms/RCCheckOutDateForm"
import { RCCheckOutDateFormFields } from "../../forms/RCCheckOutDateForm/types"
import useRCStore from "../../store"

const RCCheckOutDateModal = ({ open, handleClose }: ModalProps) => {
  const { contractInfoStore } = useRCStore()

  const handleSubmit = (data: RCCheckOutDateFormFields) => {
    contractInfoStore.saveDateOfLeavingApartment(data)
    handleClose()
  }

  return (
    <Modal open={open} handleClose={handleClose} title="Check-out date">
      <RCCheckOutDateForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(RCCheckOutDateModal)

import { MeterTypes, ResourceTypes } from "kui-crm"

const getMeterLabel = (resource: ResourceTypes, type?: MeterTypes) =>
  `${
    resource === "water" || (resource === "electricity" && type)
      ? `${type} `
      : ""
  }${resource} meter`

export default getMeterLabel

import React from "react"
import _ from "lodash"
import { ResourceTypes } from "kui-crm/types"
import { Link } from "kui-crm"
import { TariffReplacementRowProps } from "./types"
import TableRow from "../../../../components/ui/TableRow"
import TableCellWithLabel from "../../../../components/common/TableCellWithLabel"
import TextOverflow from "../../../../components/common/TextOverflow"
import { TariffsOfTariffGroup } from "../../types/store/tariffOverview"
import { Routes } from "../../../../types/route"

const getResourceCells = (resource: ResourceTypes | null) => {
  switch (resource) {
    case "electricity":
      return [
        { label: "One-part", name: "T1", width: "10%" },
        { label: "Two-part", name: "T2", width: "10%" },
        { label: "Three-part", name: "T3", width: "10%" },
      ]
    case "water":
      return [
        { label: "Cold", name: "cold", width: "10%" },
        { label: "Hot", name: "hot", width: "10%" },
        { label: "Sewer", name: "sewerage", width: "10%" },
      ]
    default:
      return [{ label: "", name: "value", width: "30%" }]
  }
}

function TariffReplacementRow({
  isDark,
  replacement,
}: TariffReplacementRowProps) {
  const tariffsCells = getResourceCells(replacement.resource)

  return (
    <TableRow isDark={isDark}>
      <TableCellWithLabel
        isFirst
        title={replacement.codeName}
        label="Tariff group"
        width="16%"
      />
      <TableCellWithLabel
        title={
          <Link
            href={`${Routes.tariffsGroups}/${replacement.resource}/${replacement.id}`}
          >
            <TextOverflow>{replacement.name || "-"}</TextOverflow>
          </Link>
        }
        label="Name"
        width="19%"
      />
      <TableCellWithLabel
        title={_.capitalize(replacement.status)}
        label="Status"
        width="10%"
      />
      {tariffsCells.map((cell) => (
        <TableCellWithLabel
          title={
            replacement.tariffs[cell.name as keyof TariffsOfTariffGroup] || "-"
          }
          label={cell.label || _.capitalize(replacement.resource)}
          width={cell.width}
        />
      ))}
      <TableCellWithLabel
        title={replacement.startDate?.toFormat("dd.MM.yyyy") || "-"}
        label="Start date"
        width="12%"
      />
      <TableCellWithLabel
        isLast
        title={replacement.endDate?.toFormat("dd.MM.yyyy") || "-"}
        label="End date"
      />
    </TableRow>
  )
}

export default TariffReplacementRow

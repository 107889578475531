import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { Link } from "kui-crm"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import TableCellMenu from "../../../../../../../../components/common/TableCellMenu"
import { ArchiveMeterRowProps } from "./types"
import LinkButton from "../../../../../../../../components/common/LinkButton"
import TableRowDivider from "../../../../../../../../components/common/TableRowDivider"
import { Routes } from "../../../../../../../../types/route"

function ArchiveMeterRow(props: ArchiveMeterRowProps) {
  const { counter, disabled } = props

  const handleDeactivate = () => {
    counter.activate()
  }

  return (
    <>
      <StyledRow data-testid="archive_counter_row">
        <StyledCell data-testid="counter_title" isFirst>
          <Link
            href={`${Routes.meteringDevices}/${counter.resource}/${counter.id}`}
          >
            {counter.archivedTitle}
          </Link>
        </StyledCell>
        <TableCell colSpan={2} />
        <TableCell data-testid="tariff_group">
          <StyledButton
            href={`${Routes.tariffsGroups}/${counter.resource}/${counter.tariff?.id}`}
          >
            {counter.tariff?.id}
          </StyledButton>
        </TableCell>
        <TableCell colSpan={5} />
        <StyledCell data-testid="total_price">
          {counter.totalPrice?.toLocaleString()} ₽
        </StyledCell>
        <TableCellMenu
          align="right"
          disabled={disabled}
          options={[
            {
              label: "Activate",
              handleClick: handleDeactivate,
            },
          ]}
        />
      </StyledRow>
      <TableRowDivider />

      <LoaderState loader={counter.loader} />
    </>
  )
}

export default observer(ArchiveMeterRow)

const StyledRow = styled(TableRow)`
  td:first-child {
    padding-left: 27px;
  }
  td:last-child {
    padding-right: 15px;
  }
`

const StyledCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.grey.fiftyP};
`

const StyledButton = styled(LinkButton)`
  p {
    color: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

import * as yup from "yup"
import {
  BasicApartmentInfoFieldsSchema,
  SecurityApartmentInfoFieldsSchema,
  RoomsApartmentInfoFieldsSchema,
  DetailApartmentInfoFieldsSchema,
} from "kui-crm_actions"

const InitialInspectionInfoSchema = yup.object({
  ...BasicApartmentInfoFieldsSchema,
  ...SecurityApartmentInfoFieldsSchema,
  ...RoomsApartmentInfoFieldsSchema,
  ...DetailApartmentInfoFieldsSchema,
})

export default InitialInspectionInfoSchema

import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import { getLinkCards, getListCards } from "./content"
import DashboardLinkCard from "../../../components/common/DashboardLinkCard"
import useDashboardStore from "../../../store"

const AccountantDashboardCards = () => {
  const { accountantDashboard } = useDashboardStore()

  if (!accountantDashboard) return null

  if (accountantDashboard.actionLoader.isLoading)
    return <Loading height="80vh" />

  const linkCards = getLinkCards(accountantDashboard)
  const listCards = getListCards(accountantDashboard)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} container spacing={4}>
        {linkCards.map((card) => (
          <Grid item key={card.label}>
            <DashboardLinkCard {...card} />
          </Grid>
        ))}
      </Grid>
      {listCards.map(({ card, key }) => (
        <Grid item xs={4} key={key}>
          {card}
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(AccountantDashboardCards)

import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import useInspectionStore from "../../store"
import InspectionTitle from "../../components/InspectionTitle"
import ApartmentDescriptionFields from "../../../../../../components/forms/apartment/ApartmentDescriptionFields"
import InspectionRoomsPhoto from "../../components/InspectionRoomsPhoto"
import { InventoryInspectionInfoFields } from "./types"
import ApartmentMetersFields from "../../components/ApartmentMetersFields"
import ApartmentPaymentFields from "./ApartmentPaymentFields"
import InventoryInspectionStore from "../../store/variations/InventoryInspectionStore"
import InspectionFillingList from "../../components/InspectionFillingList"
import InventoryInspectionInfoSchema from "./schema"

const InventoryInspectionInfo = () => {
  const { editor, inspectionInfo, patchInspection } = useInspectionStore()
  const inspection = inspectionInfo as InventoryInspectionStore
  const form = useForm<InventoryInspectionInfoFields>({
    defaultValues: {
      ...inspection?.descriptionFields,
      ...inspection?.paymentFields,
      ...inspection?.metersInfo,
      apartment: {
        address: inspection?.descriptionFields?.address,
      },
      roomsImages: inspection?.roomsImages,
      fillingList: inspection?.fillingList,
      dropboxLink: inspection?.dropboxLink,
    },
    resolver: yupResolver(InventoryInspectionInfoSchema),
  })
  const disabled = !editor.isEditing

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(patchInspection, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <>
      <InspectionTitle form={form} />
      <ApartmentDescriptionFields form={form as any} disabled={disabled} />
      <ApartmentMetersFields form={form as any} />
      <ApartmentPaymentFields form={form} />
      <InspectionFillingList form={form as any} />
      <InspectionRoomsPhoto form={form} />
    </>
  )
}

export default observer(InventoryInspectionInfo)

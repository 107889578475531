import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import {
  Paginator,
  SortingFilter,
  Loader,
  resHandler,
  toNumber,
} from "kui-utils"
import { FileBodyRequest } from "kui-crm/types"
import { ApartmentDocumentFormFields } from "../../components/forms/ApartmentDocumentForm/types"
import {
  ApartmentDocumentParams,
  ApartmentDocumentStatus,
} from "../../types/store/apartmentDocumentsStore"
import RegistriesAgent from "../../../../agent/Registries"
import {
  ApartmentDocumentModel,
  ApartmentDocumentResponse,
  PostApartmentDocumentModel,
} from "../../types/api/apartmentDocuments"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import UserLiteStore from "../../../../store/templates/UserLite"
import { matchesAPIDocumentsStatuses } from "../../../../utils/content/matches"
import FileStore from "../../../../store/templates/File"
import { uploadNewFile } from "../../../../utils/agent/uploadFiles"

class ApartmentDocumentsRegistryStore {
  list: ApartmentDocumentParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  apiEndpoint: string

  constructor(endpoint: string) {
    this.list = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.apiEndpoint = endpoint
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<ApartmentDocumentResponse>(
      RegistriesAgent.getDocumentsRegistry(
        this.apiEndpoint,
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (document: ApartmentDocumentModel) =>
        ApartmentDocumentsRegistryStore.getApartmentDocumentParams(document)

      this.list = this.paginator.getPageResponse<
        ApartmentDocumentModel,
        ApartmentDocumentParams
      >(res, this.list, mapper)
    } else {
      this.loader.setError("fetch documents", err)
    }
    this.loader.endLoading()
  }

  createApartmentDocument = async (data: ApartmentDocumentFormFields) => {
    this.creationLoader.startLoading()

    const file = await uploadNewFile(this.creationLoader, data.file)
    const body = ApartmentDocumentsRegistryStore.getApartmentDocumentBody(
      data,
      file
    )

    const response = await to(
      RegistriesAgent.createDocument(this.apiEndpoint, body)
    )

    resHandler(response, this.creationLoader, this.paginator.refresh)
  }

  static getApartmentDocumentParams = (
    document: ApartmentDocumentModel
  ): ApartmentDocumentParams => ({
    id: document.id,
    folder: document.apartment?.folder_number || "",
    apartment: ApartmentsStore.getApartmentLinkParams(document.apartment),
    document: document.document
      ? FileStore.initFromDocumentModel(document.document)
      : null,
    client: document.apartment?.owner
      ? UserLiteStore.initFromLiteUserModel(document.apartment.owner)
      : null,
    number: document.number,
    price: document.amount ? Number(document.amount) : null,
    endDate: document.expiration_date
      ? DateTime.fromISO(document.expiration_date)
      : null,
    status: matchesAPIDocumentsStatuses[
      document.status
    ] as ApartmentDocumentStatus,
  })

  static getApartmentDocumentBody = (
    data: ApartmentDocumentFormFields,
    document: FileBodyRequest | null
  ): PostApartmentDocumentModel => ({
    apartment: data.apartment.id,
    document,
    start_date: data.startDate?.toISODate(),
    expiration_date: data.endDate?.toISODate(),
    number: data.number,
    amount: toNumber(data.price),
  })
}

export default ApartmentDocumentsRegistryStore

import * as React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { useEffect } from "react"
import { LoaderState } from "kui-crm"
import ExpensesPayments from "./ExpensesPayments"
import ExpensesSummaryCalculations from "./ExpensesSummaryCalculations"
import ExpensesFooter from "./ExpensesFooter"
import Loading from "../../../../../../../components/common/Loading"
import useExpensesStore from "../../../store"

function ExpensesSummaryTab() {
  const { overviewStore, periodStore, summaryStore, selectedContract, date } =
    useExpensesStore()

  useEffect(() => {
    if (!periodStore.id && overviewStore.id && selectedContract && date)
      periodStore.getPeriodInfo(overviewStore.id, selectedContract.id, date)
  }, [periodStore.id])

  useEffect(() => {
    if (overviewStore.id && periodStore.id && selectedContract?.id) {
      summaryStore.initSummaryTab(
        overviewStore.id,
        selectedContract.id,
        periodStore.id
      )
    } else {
      summaryStore.cleanSummaryTab()
    }
    return () => {
      summaryStore.loader.startLoading()
      periodStore.cleanPeriodId()
    }
  }, [periodStore.id, overviewStore.id, selectedContract?.id])

  if (summaryStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Box pb={8} pt={1}>
      <ExpensesPayments />
      <ExpensesSummaryCalculations />
      <ExpensesFooter />

      <LoaderState loader={summaryStore.loader} onlyError />
      <LoaderState loader={summaryStore.actionLoader} />
      <LoaderState loader={summaryStore.paymentSummaryStore.loader} />
    </Box>
  )
}

export default observer(ExpensesSummaryTab)

import React from "react"
import InitialInspectionForm from "./variations/InitialInspectionForm"
import AppraisalInspectionForm from "./variations/AppraisalInspectionForm"
import ImpairmentsInspectionForm from "./variations/ImpairmentsInspectionForm"
import InventoryInspectionForm from "./variations/InventoryInspectionForm"
import TransferInspectionForm from "./variations/TransferInspectionForm"
import RegularInspectionForm from "./variations/RegularInspectionForm"

const generateInspectionSteps = (
  handleClose: (id?: number | null) => void,
  shift: number
) => {
  const props = {
    handleClose,
    shift,
  }
  return {
    initial: <InitialInspectionForm {...props} />,
    appraisal: <AppraisalInspectionForm {...props} />,
    impairments: <ImpairmentsInspectionForm {...props} />,
    inventory: <InventoryInspectionForm {...props} />,
    transfer: <TransferInspectionForm {...props} />,
    regular: <RegularInspectionForm {...props} />,
  }
}

export default generateInspectionSteps

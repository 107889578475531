import * as React from "react"
import { css } from "@emotion/react"
import { useLocation } from "react-router"
import styled from "@emotion/styled"
import { Link } from "kui-crm"
import { HeaderNavLinkProps, HeaderNavLinkStylesProps } from "./types"

function HeaderNavLink(props: HeaderNavLinkProps) {
  const { children, route } = props
  const location = useLocation()
  const pathname =
    location.pathname.match(/\/(\w|-)+/)?.[0] || location.pathname

  return (
    <StyledButton type="button" isActive={pathname === route}>
      <Link href={route} stopPropagation>
        {children}
      </Link>
    </StyledButton>
  )
}

export default HeaderNavLink

const activeCSS = ({ isActive }: HeaderNavLinkStylesProps) =>
  isActive &&
  css`
    color: white;
  &::after {
    content: "";
    height: 3px;
    display: block;
    position: absolute;
    border-radius: 0px 0px 4px 4px;
    background-color: rgb(245, 124, 0);
    width: 100%;
    top: -23px;
    left: 1px;
  },
`

const StyledButton = styled.button<HeaderNavLinkStylesProps>`
  display: flex;
  align-items: center;
  margin-right: 28px;
  position: relative;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.grey.fourty};
  user-select: none;
  ${activeCSS};
`

import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { ApartmentDocumentCreationModalProps } from "./types"
import ApartmentDocumentForm from "../../../../../../components/forms/apartment/ApartmentDocumentForm"
import useApartmentStore from "../../../../store"
import { ApartmentDocumentFields } from "../../../../../../components/forms/apartment/ApartmentDocumentForm/types"

const ApartmentDocumentCreationModal = (
  props: ApartmentDocumentCreationModalProps
) => {
  const { variant, open, handleClose } = props
  const { overviewStore } = useApartmentStore()

  const handleSubmit = (data: ApartmentDocumentFields) => {
    overviewStore.addApartmentDocument(variant, data)
    handleClose()
  }

  return (
    <Modal title={`Add ${variant}`} open={open} handleClose={handleClose}>
      <ApartmentDocumentForm variant={variant} handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(ApartmentDocumentCreationModal)

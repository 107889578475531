import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import InspectionLinkButton from "../InspectionLinkButton"
import useInspectionStore from "../../store"
import RegularInspectionStore from "../../store/variations/RegularInspectionStore"
import TransferInspectionStore from "../../store/variations/TransferInspectionStore"
import InventoryInspectionStore from "../../store/variations/InventoryInspectionStore"

const InspectionLinks = () => {
  const { inspectionInfo } = useInspectionStore()
  const inspection = inspectionInfo as
    | RegularInspectionStore
    | TransferInspectionStore
    | InventoryInspectionStore

  return (
    <StyledWrapper container spacing={2}>
      <InspectionLinkButton
        id={inspection.impairmentsInspectionId}
        type="impairments"
      />
      <InspectionLinkButton
        id={inspection.appraisalInspectionId}
        type="appraisal"
      />
    </StyledWrapper>
  )
}

export default observer(InspectionLinks)

const StyledWrapper = styled(Grid)`
  margin-left: 24px;
  width: fit-content;
`

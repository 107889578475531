const meterReadingHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder", label: "Folder", width: "5%" },
  { id: "object", label: "Object", width: "25%" },
  { id: "number", label: "Number", width: "14%" },
  { id: "type", label: "Type", width: "14%" },
  { id: "date", label: "Check date", width: "9%" },
  { id: "value", label: "Value", width: "17%", withoutSort: true },
  { id: "file", label: "File", width: "6%", withoutSort: true },
  { id: "", label: "", width: "5%", withoutSort: true },
]

export default meterReadingHeadCells

import React, { useRef } from "react"
import { observer } from "mobx-react"
import { MultistepForm as MultistepFormStore } from "kui-utils"
import { Modal } from "kui-crm"
import { ImpairmentModalProps } from "./types"
import MultistepForm from "../../../../../../../components/common/MultistepForm"
import ImpairmentInfoForm from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/ImpairmentsInspectionForm/ImpairmentInfoForm"
import UploadFilesForm from "../../../../../../../components/forms/common/UploadFilesForm"
import {
  ImpairmentImagesFields,
  ImpairmentInfoFormFields,
} from "../ImpairmentInfoFields/types"

const ImpairmentModal = (props: ImpairmentModalProps) => {
  const { handleSubmit, ...other } = props
  const formStoreRef = useRef(
    new MultistepFormStore<ImpairmentInfoFormFields>()
  )
  const formStore = formStoreRef.current

  const handleFormSubmit = (data: ImpairmentImagesFields) => {
    handleSubmit(formStore.fields as ImpairmentInfoFormFields)
  }

  return (
    <Modal title="Add impairment" {...other}>
      <MultistepForm form={formStore}>
        <ImpairmentInfoForm formStore={formStore} />
        <UploadFilesForm
          name="images"
          defaultValues={(formStore.fields?.images || []) as any}
          handleSubmit={handleFormSubmit}
          variant="images"
        />
      </MultistepForm>
    </Modal>
  )
}

export default observer(ImpairmentModal)

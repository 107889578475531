import { LiteUserModel } from "kui-crm/types"
import { ClientModel } from "../../types/api/clients"
import { serviceContractMock } from "../service_contracts/data"

export const userReject = {
  non_field_errors: ["Failed to create user."],
}

export const postUser = {
  id: 1,
  phone: "+79000000000",
  middle_name: "",
}

export const linkApartmentMock = {
  id: 1,
  address: "",
  main_image: null,
  object_name: "",
}

export const partialUserMock: ClientModel = {
  id: 190,
  client_type: "personal",
  role: "landlord",
  email: "test@mail.ru",
  first_name: "Ivan",
  last_name: "Ivanov",
  middle_name: "Ivanovich",
  full_name: "Ivan Ivanov Ivanovich",
  phone: "790000000000",
  passport_info: [],
  rental_contracts: [],
  service_contracts: [],
}

export const fullUserMock: ClientModel = {
  id: 190,
  client_type: "personal",
  role: "tenant",
  email: "test@mail.ru",
  first_name: "Ivan",
  last_name: "Ivanov",
  middle_name: "Ivanovich",
  full_name: "Ivan Ivanov Ivanovich",
  phone: "790000000000",
  date_joined: "2019-09-20",
  passport_info: [
    {
      gender: "M",
      birthday: "1990-10-20",
      birthday_place: "Mockow",
      series_passport: "4020",
      number_passport: "800200",
      passport_id_of_authority: "id",
      passport_name_of_authority: "name",
      issue_date: "2015-06-20",
      registration_address: "Moscow, Testovskaya",
      registration_date: "2000-05-20",
    },
  ],
  rental_contracts: [
    {
      id: 1,
      installment: 1,
      auto_prolongate: true,
      rental_type: "long",
      planned_leave_date: "",
      renter_payment_status: "paid",
      next_inspection: "",
      renter: {
        id: 191,
        email: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        full_name: "Alex Petrov Ivanovich",
        phone: "",
      },
      owner: {
        id: 190,
        email: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        full_name: "Ivan Ivaniv Ivanovich",
        phone: "",
      },
      renter_id: 191,
      number: "number",
      apartment: linkApartmentMock,
      sign_date: "2021-05-20",
      start_date: "2021-05-20",
      month_payment: "50000",
      initial_commission: "100",
      security_payment: "100",
      termination_penalty: "5000",
      notify_period_month: 2,
      closing_conditions: {},
    },
  ],
  service_contracts: [serviceContractMock],
}

export const usersMock = {
  count: 1,
  next: null,
  previous: null,
  results: [
    {
      id: 190,
      email: "testing@mail.ru",
      first_name: "Ivan",
      last_name: "Ivanov",
      middle_name: "Ivanovich",
      full_name: "Ivan Ivanov Ivanovich",
      phone: "790000000000",
      passport_info: [],
      notes: [],
      rental_contracts: [],
      owned_apartments: [],
    },
    {
      id: 191,
      email: "test@mail.ru",
      first_name: "Ksenia",
      last_name: "Fomenko",
      middle_name: "Alexandrovna",
      full_name: "Ksenia Fomenko Alexandrovna",
      phone: "790000000000",
      passport_info: [],
      notes: [],
      rental_contracts: [],
      owned_apartments: [],
    },
    {
      id: 192,
      email: "test@mail.ru",
      first_name: "Inna",
      last_name: "Foniva",
      middle_name: "Dmitrievna",
      full_name: "Inna Foniva Dmitrievna",
      phone: "790000000000",
      passport_info: [],
      notes: [],
      rental_contracts: [],
      owned_apartments: [],
    },
  ],
}

export const userEmploymentMock = {
  company_name: "The Best",
  company_ITN: "6757823548",
  start_date: "2022-10-15",
  position: "Junior designer",
  income: 30000,
  phone: "79112005236",
  email: "test@mail.ru",
}

export const userBankAccMock = {
  ITN: "1234567890",
  bank_name: "JSC «ALFA-BANK», 100 Velyka Vasylkivska str. Kyiv, Ukraine",
  swift: "ALFAUAUK",
  bank_id: "1234567890",
  correspondent_account: "301018104000000000225",
  personal_account: "301018104000000000225",
}

export const userLiteMock: LiteUserModel = {
  id: 1,
  email: "bla@mail.ru",
  first_name: "Ivan",
  last_name: "Ivanov",
  middle_name: "Ivanovich",
  full_name: "Ivan Ivanov Ivanovich",
  phone: "79112003040",
}

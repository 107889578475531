import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import CompanyStore from "./CompanyStore"
import { CompanyDashboardModal } from "../../types/api/companies"
import DashboardAgent from "../../../../agent/Dashboard"
import DashboardPageStore from "../DashboardPageStore"

class CompaniesStore {
  value: number | null

  companies: CompanyStore[]

  paginator: Paginator

  loader: Loader

  archiveCompanies: CompanyStore[]

  archivePaginator: Paginator

  archiveLoader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.value = null
    this.companies = []
    this.archiveCompanies = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.archivePaginator = new Paginator(5)
    this.archiveLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchCompanies = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getCompanies(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.companies = this.paginator.getPageResponse(
          res,
          this.companies,
          this.getCompanyParams
        )
      })
    })
  }

  fetchArchive = async () => {
    this.archiveLoader.startLoading()

    const response = await to(
      DashboardAgent.getCompanies(
        this.archivePaginator.limit,
        this.archivePaginator.offset,
        this.dashboardStore.periodFilter,
        true
      )
    )

    runInAction(() => {
      resHandler(response, this.archiveLoader, (res) => {
        this.value = res.count
        this.archiveCompanies = this.archivePaginator.getPageResponse(
          res,
          this.archiveCompanies,
          this.getCompanyParams
        )
      })
    })
  }

  removeFromCompaniesList = (id: number) => {
    this.companies = this.companies.filter((company) => company.id !== id)
  }

  removeFromArchiveList = (id: number) => {
    this.archiveCompanies = this.archiveCompanies.filter(
      (company) => company.id !== id
    )
  }

  getCompanyParams = (company: CompanyDashboardModal) =>
    new CompanyStore(company, this)
}

export default CompaniesStore

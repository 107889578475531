import _ from "lodash"
import { ObjectType } from "kui-crm/types"

type FieldValueType = Exclude<any, null | undefined>

export const clearNotValidFields = (
  data: ObjectType,
  withNull?: boolean
): { [key: string]: FieldValueType } => {
  Object.keys(data).forEach((key) => {
    const settings = withNull ? data[key] !== null : true
    if (
      settings &&
      (typeof data[key] === "undefined" ||
        data[key] === null ||
        (typeof data[key] === "object" && Object.keys(data[key]).length === 0))
    ) {
      delete data[key]
    }
  })

  return data
}

export const transformToSnakeCase = (data: ObjectType) =>
  _.mapKeys(data, (value, key) => _.snakeCase(key))

export const transformToCamelCase = (data: ObjectType) =>
  _.mapKeys(data, (value, key) => _.camelCase(key))

const appendToFormData = (formData: FormData, key: string, value: any) => {
  if (value instanceof Blob) {
    formData.append(key, value)
  } else if (typeof value === "string") {
    formData.append(key, value)
  } else {
    formData.append(key, JSON.stringify(value))
  }
}

export const generateFormDataBody = (
  data: { [key: string]: any },
  withArray?: boolean
) => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    const value = data[key]

    if (typeof value !== "undefined") {
      if (value instanceof Array && !withArray) {
        value.forEach((item) => {
          appendToFormData(formData, key, item)
        })
      } else appendToFormData(formData, key, value)
    }
  })

  return formData
}

export const formatError = (data: ObjectType | null) => {
  if (data && typeof data === "object") {
    return JSON.stringify(data)
  }
  return ""
}

export const setDefinedValue = <S>(
  store: S,
  name: keyof S,
  value: any,
  formattedValue?: any
) => {
  if (typeof value !== "undefined") store[name] = formattedValue || value
}

type SettingType = {
  [key: string]: boolean
}
type CallbacksType = {
  [key: keyof SettingType]: () => Promise<void>
}
export const callPromisesBySettings = async (
  settings: SettingType,
  callbacks: CallbacksType
) => {
  const promises = Object.keys(settings)
    .filter((key) => settings[key])
    .map((key) => callbacks[key]())
  return Promise.allSettled(promises)
}

import { InputsModalGroupFields } from "../../../../components/common/InputsModalGroupWithTitle/types"

const scCommonTermsGroupsFields: InputsModalGroupFields[] = [
  {
    title: "Price terms",
    fields: [
      {
        label: "Minimum rent price",
        name: "minRentPrice",
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
      {
        label: "Maximum rent price",
        name: "maxRentPrice",
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
    ],
  },
  {
    title: "Commissions terms",
    fields: [
      {
        label: "Initial commission",
        name: "initialCommission",
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Maintenance commission",
        name: "maintenanceCommission",
        type: "number",
        cornerLabel: "%",
      },
    ],
  },
  {
    title: "Maintenance terms",
    fields: [
      {
        label: "Advertsing period",
        name: "advertisingPeriod",
        type: "number",
        cornerLabel: "month",
      },
      {
        label: "Allowable price deviation",
        name: "allowablePriceDeviation",
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Inspections",
        name: "inspections",
        type: "number",
        cornerLabel: "month",
        size: 12,
      },
    ],
  },
]

export default scCommonTermsGroupsFields

import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import { ApartmentModel } from "kui-crm/types"
import ApartmentFillingListStore from "./ApartmentFillingList"
import ApartmentPageStore from "../ApartmentPageStore"
import { ApartmentDescriptionParams } from "../../../../types/store/apartments"

class ApartmentDescriptionStore {
  apartmentStore: ApartmentPageStore

  fields: ApartmentDescriptionParams | null = null

  withChildren: boolean = false

  withPets: boolean = false

  fillingStore: ApartmentFillingListStore

  loader: Loader

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.fillingStore = new ApartmentFillingListStore()
    this.loader = new Loader()
    makeAutoObservable(this, { apartmentStore: false })
  }

  initDescriptionTab = async (apartmentId: number) => {
    this.loader.startLoading()
    await Promise.all([
      this.fillingStore.fetchFilling(apartmentId),
      this.apartmentStore?.metroStore.getAllMetroStations(),
    ])
    this.loader.endLoading()
  }

  updateDescriptionInfo = async (apartment: ApartmentModel) => {
    this.fields = await this.apartmentStore.getApartmentDescriptionInfo(
      apartment
    )
  }
}

export default ApartmentDescriptionStore

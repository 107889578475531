import React from "react"
import { Box, Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import SadBunny from "../../../../../../../components/icons/SadBunny"

const ExpensesEmptyPlug = () => (
  <StyledWrapper container direction="column">
    <Box mb={2}>
      <SadBunny />
    </Box>
    <Caption size="l" weight={500} color="fiftyP">
      No active contracts with tenants
    </Caption>
  </StyledWrapper>
)

export default ExpensesEmptyPlug

const StyledWrapper = styled(Grid)`
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 365px);
`

import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { PaginationListProps } from "./types"
import Loading from "../../../../../components/common/Loading"
import ScrollingContainer from "../../../../../components/common/ScrollingContainer"

const PaginationList = (props: PaginationListProps) => {
  const { loader, paginator, fetchAll, children, isOpen } = props

  useEffect(() => {
    if (paginator?.isFetching && !paginator?.isFinishPage && isOpen) {
      if (fetchAll) fetchAll()
    }
  }, [fetchAll, paginator?.isFetching, isOpen])

  const onScrollEnd = () => {
    if (!paginator?.isFinishPage) {
      paginator?.startFetching()
    }
  }

  return (
    <>
      <StyledContentWrapper
        className="PaginationList"
        onScrollEnd={onScrollEnd}
      >
        {children}
        {loader?.isLoading && <StyledLoading height="auto" />}
      </StyledContentWrapper>
      <LoaderState loader={loader} onlyError />
    </>
  )
}

PaginationList.defaultProps = {
  isOpen: true,
}

export default observer(PaginationList)

const StyledContentWrapper = styled(ScrollingContainer)`
  max-height: 146px;
`

const StyledLoading = styled(Loading)`
  position: sticky;
  bottom: 30px;
`

import requests from "./index"
import { DashboardSettingsModel } from "../types/api/dashboard"
import {
  WorkIndicatorResponse,
  WorkIndicatorType,
} from "../pages/DashoboardPage/types/api/common"
import {
  AutoMetersResponse,
  MetersVerificationsResponse,
} from "../pages/DashoboardPage/types/api/meters"
import { ContractTypes } from "../types/common"
import { ClosingContractsResponse } from "../pages/DashoboardPage/types/api/contracts"
import { CompaniesDashboardResponse } from "../pages/DashoboardPage/types/api/companies"
import { ApartmentServicesResponse } from "../pages/DashoboardPage/types/api/apartments"
import {
  ImpairmentInspectionsResponse,
  UpcomingInspectionResponse,
} from "../pages/DashoboardPage/types/api/inspections"

const DashboardAgent = {
  getSettings: () => requests.get("/dashboard/settings"),
  editSettings: (data: DashboardSettingsModel) =>
    requests.patch("/dashboard/settings", data),
  getWorkIndicators: (type: WorkIndicatorType, filter: string) =>
    requests.get<WorkIndicatorResponse>(
      `/dashboard/reminders/count?type=${type}${filter}`
    ),
  getAutoMeters: (limit: number, offset: number, filter: string) =>
    requests.get<AutoMetersResponse>(
      `/dashboard/reminders/auto-metering-devices?limit=${limit}&offset=${offset}${filter}`
    ),
  getMetersVerifications: (limit: number, offset: number, filter: string) =>
    requests.get<MetersVerificationsResponse>(
      `/dashboard/reminders/meters-require-verification?limit=${limit}&offset=${offset}${filter}`
    ),
  getContracts: (
    type: ContractTypes,
    limit: number,
    offset: number,
    filter: string
  ) =>
    requests.get<ClosingContractsResponse>(
      `/dashboard/reminders/closing-${type}-contracts?limit=${limit}&offset=${offset}${filter}`
    ),
  getUpcomingInspections: (filter: string) =>
    requests.get<UpcomingInspectionResponse>(
      `/dashboard/reminders/upcoming-inspections?${filter}`
    ),
  getCompanies: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<CompaniesDashboardResponse>(
      `/dashboard/reminders/companies-with-wrong-requisites?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getApartmentsServices: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<ApartmentServicesResponse>(
      `/dashboard/reminders/objects-without-services?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getImpairmentsInspections: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<ImpairmentInspectionsResponse>(
      `/dashboard/reminders/inspections-require-impairments?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),

  archiveDashboardItem: (id: number) =>
    requests.post(`/dashboard/reminders/${id}/archive`),
  unArchiveDashboardItem: (id: number) =>
    requests.post(`/dashboard/reminders/${id}/unarchive`),
}

export default DashboardAgent

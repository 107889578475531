import { InspectionTypes } from "kui-crm_actions"
import { addToArrayByCondition } from "kui-utils"
import { InputParams, LinkField } from "kui-crm"
import {
  InspectionAndValuationTypeValues,
  InspectionTypeValues,
  TransferInspectionTypeValues,
} from "../../../../../utils/content/listsOptions"

const getInspectionCommonStepFields = (
  withType?: boolean,
  type?: InspectionTypes,
  onlyInspections?: boolean
): InputParams<any>[] => [
  { label: "Inspection date", name: "date", variant: "date" },
  ...addToArrayByCondition(withType, {
    label: "Inspection type",
    name: "type",
    variant: "select",
    options: onlyInspections
      ? InspectionTypeValues
      : InspectionAndValuationTypeValues,
  }),
  ...addToArrayByCondition(type === "transfer", {
    label: "Transfer type",
    name: "transferType",
    variant: "select",
    options: TransferInspectionTypeValues,
  }),
  {
    label: "Dropbox link",
    name: "dropboxLink",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default getInspectionCommonStepFields

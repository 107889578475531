const closeRentalContractInputs = [
  {
    label: "Termination Agreement",
    name: "terminationAgreement",
    variant: "file",
  },
  {
    label: "Close date",
    name: "closeDate",
    variant: "date",
  },
]

export default closeRentalContractInputs

import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { CabinetUserModel } from "../../types/api/cabinet"
import CabinetAgent from "../../agent/Cabinet"
import RootStore from "../Root"

class CabinetStore {
  rootStore: RootStore

  user: CabinetUserModel | null

  err: Error | null

  isLoading: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.user = null
    this.err = null
    this.isLoading = false
    makeAutoObservable(this, { rootStore: false })
  }

  getUser = async () => {
    this.err = null
    this.isLoading = true
    const [err, res] = await to(CabinetAgent.getUser())
    runInAction(() => {
      if (!err && res) {
        this.user = res
      } else {
        this.err = err
      }
    })
  }

  get fullName() {
    return this.user?.first_name || this.user?.last_name
      ? `${this.user?.first_name ?? ""} ${this.user?.last_name ?? ""}`
      : this.user?.username || ""
  }
}

export default CabinetStore

const inspectionsTableHeadCells = [
  { label: "№", width: "7%" },
  { label: "Date", width: "9%" },
  { label: "Type", width: "8%" },
  { label: "Comment", width: "15%" },
  { label: "Document", width: "13%", withSpacing: true },
  { label: "Damages", width: "8%", withSpacing: true },
  { label: "Wear&tear", width: "11%" },
  { label: "Appraisal date", width: "11%" },
  { label: "Min price", width: "8%", withSpacing: true },
  { label: "Max price", width: "10%" },
]

export default inspectionsTableHeadCells

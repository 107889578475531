import { LinkField } from "kui-crm"
import { InputFile } from "kui-complex"
import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { ImpairmentInfoFormFields } from "./types"
import { ImpairmentsTypeValues } from "../../../../../../../utils/content/listsOptions"
import ImpairmentDeleteButton from "../ImpairmentDeleteButton"

const getImpairmentInfoFields = (
  index: number,
  disabled: boolean,
  handleDelete: () => void
): InputParams<ImpairmentInfoFormFields>[] => [
  {
    label: "Description",
    name: `impairments.${index}.description`,
    isTextArea: true,
  },
  {
    label: "Solution",
    name: `impairments.${index}.solution`,
    isTextArea: true,
  },
  {
    name: "",
    variant: "custom",
    CustomInput: ImpairmentDeleteButton,
    disabled,
    onClick: handleDelete,
  },
  {
    label: "Type",
    name: `impairments.${index}.type`,
    variant: "select",
    options: ImpairmentsTypeValues,
    size: 2,
  },
  {
    label: "Price",
    name: `impairments.${index}.price`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    cornerLabel: "₽",
    size: 2,
  },
  {
    label: "Link",
    name: `impairments.${index}.link`,
    variant: "custom",
    CustomInput: LinkField,
  },
  {
    label: "Proof",
    name: `impairments.${index}.proofFile`,
    variant: "custom",
    CustomInput: InputFile,
  },
]

export default getImpairmentInfoFields

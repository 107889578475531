import React, { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { InputWithController } from "kui-complex"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import styled from "@emotion/styled"
import ImpairmentsInspectionHeader from "./ImpairmentsInspectionHeader"
import { ImpairmentInfoFormFields } from "./ImpairmentInfoFields/types"
import { ImpairmentsInspectionInfoFields } from "./types"
import Container from "../../../../../../components/ui/Container"
import useInspectionStore from "../../store"
import ImpairmentsInspectionStore from "../../store/variations/ImpairmentsInspectionStore"
import ImpairmentsInspectionInfoSchema from "./schema"
import ImpairmentsGroup from "./ImpairmentsGroup"

const ImpairmentsInspectionInfo = () => {
  const { inspectionInfo, editor, patchInspection } = useInspectionStore()
  const inspection = inspectionInfo as ImpairmentsInspectionStore
  const disabled = !editor.isEditing

  const form = useForm<ImpairmentsInspectionInfoFields>({
    defaultValues: {
      dropboxLink: inspection?.dropboxLink,
      impairments: inspection
        ? ImpairmentsInspectionStore.getImpairmentsFormFields(
            inspection.impairments
          )
        : [],
    },
    resolver: yupResolver(ImpairmentsInspectionInfoSchema),
  })
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "impairments",
  })
  const damages = fields.filter((impairment) => impairment.type === "damages")
  const wear = fields.filter((impairment) => impairment.type === "wear_tear")

  const addImpairment = (data: ImpairmentInfoFormFields) => append(data)

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(patchInspection, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  const groupProps = { form, disabled, remove }

  console.log(inspection)

  return (
    <>
      <ImpairmentsInspectionHeader form={form} handleSubmit={addImpairment} />
      <Container>
        <StyledWrapper container spacing={3}>
          <ImpairmentsGroup
            title="Damages"
            impairments={damages}
            {...groupProps}
          />
          <ImpairmentsGroup
            title="Wear&tear"
            impairments={wear}
            {...groupProps}
          />
        </StyledWrapper>

        <Box pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <InputWithController
                isTextArea
                label="FAQ"
                name="faq"
                form={form}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default observer(ImpairmentsInspectionInfo)

const StyledWrapper = styled(Grid)`
  padding-top: 24px;
`

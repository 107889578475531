import React from "react"
import { observer } from "mobx-react"
import {
  InputWithMask,
  InputWithMonthPicker,
  InputPhoneWithForm,
  InputWithController,
} from "kui-complex"
import { Heading, Box, Grid } from "kui-basic"
import { UserTaxFieldsProps } from "./types"
import useClientStore from "../../../../store"

function ClientTaxFields({ form }: UserTaxFieldsProps) {
  const { editor } = useClientStore()
  const disabled = !editor.isEditing

  return (
    <>
      <Box mb={2}>
        <Heading size="h4">Tax data</Heading>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputWithController
            name="companyName"
            label="Company name"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithController
            name="companyITN"
            label="Company ITN"
            placeholder="XXXXXXXXXX"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithMonthPicker
            name="startDate"
            label="Start date"
            placeholder="MM.YYYY"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithController
            name="position"
            label="Position"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithMask
            name="income"
            label="Income"
            cornerLabel="₽ / month"
            form={form}
            disabled={disabled}
            mask={Number}
            thousandsSeparator=" "
            scale={0}
          />
        </Grid>
        <Grid item xs={6}>
          <InputPhoneWithForm
            name="workPhone"
            label="Work phone"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithController
            name="workEmail"
            label="Work Email"
            placeholder="@work.com"
            form={form}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default observer(ClientTaxFields)

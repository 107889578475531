import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import useDashboardStore from "../../../store"
import { getListCards } from "./content"

const HousingInspectorDashboardCards = () => {
  const { housingInspectorDashboard } = useDashboardStore()

  if (!housingInspectorDashboard) return null

  const cards = getListCards(housingInspectorDashboard)

  return (
    <Grid container spacing={4}>
      {cards.map(({ card, key, size }) => (
        <Grid item xs={size} key={key}>
          {card}
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(HousingInspectorDashboardCards)

import React, { useEffect, useRef, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DateTime } from "luxon"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { FormBottom, InputByType, LoaderState } from "kui-crm"
import { Loader, resHandler } from "kui-utils"
import { ServiceMainInfoFormFields, ServiceMainInfoFormProps } from "./types"
import ServiceFieldsSchema from "./schema"
import serviceMainInfoFields from "./fields"
import PrevServicesList from "../PrevServicesList"
import ApartmentAutocomplete from "../../../entityInputs/ApartmentAutocomplete"
import { ApartmentLiteParams } from "../../../../types/store/apartments"
import ApartmentServicesStore from "../../../../store/shared/apartmentService/ApartmentServicesStore"

function ServiceMainInfoForm(props: ServiceMainInfoFormProps) {
  const {
    formMaker,
    withPrevServicesList,
    apartmentId,
    periodId,
    withApartment,
    apartment,
    lastOpenPeriod,
  } = props
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [companyDisabled, setCompanyDisabled] = useState(false)
  const form = useForm<ServiceMainInfoFormFields>({
    defaultValues: {
      ...formMaker.fields,
      payerCode: apartment?.payerCode,
      communalServicesAccount: apartment?.communalServicesAccount,
    },
    resolver: yupResolver(ServiceFieldsSchema),
  })
  const [formApartment, company] = useWatch({
    control: form.control,
    name: ["apartment", "company"],
  })
  const actualApartmentId = apartmentId || formApartment?.id
  const actualPeriodId = periodId || formApartment?.periodId

  const handleSubmit = (data: ServiceMainInfoFormFields) => {
    formMaker.updateFormFields(data)
    formMaker.nextStep()
  }

  const handleIssueDateChange = (newDate: Date) => {
    const durationType = form.getValues("durationType")
    const apartmentFields = apartment || formApartment
    if (apartmentFields && durationType === "open-ended") {
      const purposeResult = ApartmentServicesStore.generatePurpose(
        DateTime.fromJSDate(newDate),
        apartmentFields
      )
      if (purposeResult.err) {
        form.setError("purpose", {
          message: purposeResult.message,
        })
      } else {
        form.clearErrors("purpose")
        form.setValue("purpose", purposeResult.message)
      }
    }
  }

  const handlePurposeChange = () => {
    const purposeError = form.formState.errors.purpose
    if (purposeError) {
      form.clearErrors("purpose")
    }
  }

  const handleApartmentSelect = async (item: ApartmentLiteParams | null) => {
    if (item) {
      form.setValue("apartment.payerCode", item.payerCode || "")
      form.setValue("apartment.apartmentNumber", item.apartmentNumber || "")
      form.setValue("payerCode", item?.payerCode)
      form.setValue("communalServicesAccount", item?.communalServicesAccount)

      if (item.id) {
        loader.startLoading("apartment period")

        const response = await ApartmentServicesStore.getLastOpenedPeriod(
          item.id
        )

        resHandler(response, loader, (res) => {
          form.setValue("apartment.periodId", res.id)
        })
      }
    }
  }

  const checkCompanyIsNew = async () => {
    if (company?.id && actualApartmentId && actualPeriodId) {
      loader.startLoading()
      const [err, isNew] =
        await ApartmentServicesStore.checkIsNewCompanyForApartment(
          company.id,
          actualPeriodId,
          actualApartmentId
        )

      if (err) {
        loader.setError("check company is new", err)
        form.clearErrors("company.name")
      } else if (isNew) {
        form.setError("company.name", {
          message: "Attention! This is a new company for the object!",
        })
      } else {
        form.clearErrors("company.name")
      }
      loader.endLoading()
    } else {
      form.clearErrors("company.name")
    }
  }

  useEffect(() => {
    checkCompanyIsNew()
  }, [company?.id, actualApartmentId])

  const infoFields = serviceMainInfoFields(
    handlePurposeChange,
    handleIssueDateChange,
    setSubmitDisabled,
    formMaker,
    companyDisabled,
    setCompanyDisabled,
    lastOpenPeriod,
    withApartment,
    actualApartmentId
  )

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={3} pr={5} pl={5}>
        <Grid container spacing={2}>
          {withApartment && (
            <Grid item xs={12}>
              <ApartmentAutocomplete
                label="Object"
                form={form}
                onSelect={handleApartmentSelect}
              />
            </Grid>
          )}
          {!withApartment && withPrevServicesList && (
            <Grid item xs={12}>
              <PrevServicesList
                form={form}
                formStore={formMaker}
                apartmentId={apartmentId}
                periodId={actualPeriodId}
              />
            </Grid>
          )}
          {infoFields.map(({ size, ...field }) => (
            <Grid item xs={size} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom label="Next" variant="next" disabled={submitDisabled} />
      <LoaderState loader={loader} />
    </form>
  )
}

export default observer(ServiceMainInfoForm)

import { MeterTypes, ResourceTypes } from "kui-crm"
import { addToArrayByCondition } from "kui-utils"

const getMeterReadingValuesFields = (
  resource?: ResourceTypes,
  type?: MeterTypes
) => {
  if (resource === "electricity" && type) {
    return [
      { name: "T1", label: "T1", type: "number" },
      ...addToArrayByCondition(type !== "T1", {
        name: "T2",
        label: "T2",
        type: "number",
      }),
      ...addToArrayByCondition(type === "T3", {
        name: "T3",
        label: "T3",
        type: "number",
      }),
    ]
  }

  return [{ name: "value", label: "Value", type: "number" }]
}

export default getMeterReadingValuesFields

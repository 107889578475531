import React, { useEffect, ReactNode, useState, ReactElement } from "react"
import { Loader } from "@googlemaps/js-api-loader"
import Loading from "../Loading"

export enum Status {
  LOADING = "LOADING",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <Loading height="100%" />
  if (status === Status.FAILURE) return <h3>{status}</h3>
  return <div />
}

type MapWrapperProps = {
  children?: ReactNode
}

function MapWrapper({ children }: MapWrapperProps): ReactElement {
  const [isUnMounted, setIsUnMounted] = useState(false)
  const [status, setStatus] = useState(Status.LOADING)

  useEffect(() => {
    if (!isUnMounted && process.env.REACT_APP_MAP_KEY) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_MAP_KEY,
        libraries: ["places"],
      })

      const setStatusAndExecuteCallback = (newStatus: Status) => {
        setStatus(newStatus)
      }

      setStatusAndExecuteCallback(Status.LOADING)

      loader.load().then(
        () => setStatusAndExecuteCallback(Status.SUCCESS),
        () => setStatusAndExecuteCallback(Status.FAILURE)
      )
    }
    return () => setIsUnMounted(true)
  }, [])

  if (!process.env.REACT_APP_MAP_KEY) {
    return <h2>Add google key</h2>
  }

  if (status === Status.SUCCESS && children) return <>{children}</>

  render(status)

  return <></>
}

export default MapWrapper

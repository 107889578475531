/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Button, Grid, Divider } from "kui-basic"
import { DotsIcon } from "kui-icon"
import { PageHeaderProps } from "./types"
import DropdownMenu from "../../ui/DropdownMenu"
import Container from "../../ui/Container"
import PageBreadcrumbs from "../PageBreadcrumbs"

function PageHeader({ handleClick, options, breadcrumbs }: PageHeaderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)

  const handleButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <StyledWrapper>
      <StyledHeader>
        <Container>
          <Grid container justify="space-between" alignItems="center">
            <PageBreadcrumbs onPrev={handleClick} breadcrumbs={breadcrumbs} />
            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                css={{ position: "relative" }}
              >
                <Button
                  isCircle
                  ref={menuButtonRef}
                  size="xs"
                  border={isOpen ? "brand" : undefined}
                  variant={isOpen ? "transparent" : "transparentWithBorder"}
                  onClick={handleButtonClick}
                >
                  <DotsIcon />
                </Button>
                <StyledDropdownMenu
                  buttonRef={menuButtonRef}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  options={options}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </StyledHeader>
      <Divider />
    </StyledWrapper>
  )
}

export default observer(PageHeader)

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-top: 12px;
  width: 254px;
  top: 45px;
`

const StyledWrapper = styled.div`
  position: sticky;
  top: 64px;
  z-index: 5;
`

const StyledHeader = styled.div`
  padding: 12px 0;
  background: ${({ theme }) => theme.palette.background.light1};
`

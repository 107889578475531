import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState, ModalProps } from "kui-crm"
import { Loading } from "kui-complex"
import DashboardHeader from "../../components/common/DashboardHeader"
import useDashboardStore from "../../store"
import HousingInspectorSettingsModal from "./HousingInspectorSettingsModal"
import HousingInspectorDashboardCards from "./HousingInspectorDashboardCards"
import useDashboardFilter from "../../hooks/useDashboardFilter"

const HousingInspectorDashboard = () => {
  const { housingInspectorDashboard, periodFilter } = useDashboardStore()

  useDashboardFilter(periodFilter, housingInspectorDashboard?.loadCards)

  useEffect(() => {
    housingInspectorDashboard?.initHousingInspectorDashboard()
  }, [])

  if (housingInspectorDashboard?.loader.isLoading) {
    return <Loading height="100vh" />
  }

  return (
    <>
      <DashboardHeader
        settings={housingInspectorDashboard?.settings}
        renderModal={(props: ModalProps) => (
          <HousingInspectorSettingsModal {...props} />
        )}
      />
      <HousingInspectorDashboardCards />
      <LoaderState loader={housingInspectorDashboard?.loader} onlyError />
    </>
  )
}

export default observer(HousingInspectorDashboard)

import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import {
  DocumentModel,
  FileModel,
  DocumentVisibilityVariants,
} from "kui-crm/types"
import {
  FileParams,
  FilesStoreInterface,
  FileStoreInterface,
} from "../../types/store/file"
import { matchesVisibilityVariants } from "../../utils/content/matches"

class FileStore implements FileStoreInterface {
  id: number | null

  name: string

  url: string

  date: DateTime | null

  size: number | null

  createdBy: string

  visibility?: DocumentVisibilityVariants

  filesStore?: FilesStoreInterface | null

  extension: string

  constructor(file: FileParams, filesStore?: FilesStoreInterface) {
    this.id = file.id ?? null
    this.name = file.name ?? ""
    this.url = file.url ?? ""
    this.date = file.date ? DateTime.fromISO(file.date) : null
    this.size = file.size ?? null
    this.filesStore = filesStore || null
    this.createdBy = file.createdBy ?? ""
    this.visibility =
      file.visibility &&
      (matchesVisibilityVariants[file.visibility] as DocumentVisibilityVariants)
    this.extension = this.name.split(".")?.pop()?.toUpperCase() || ""
    makeAutoObservable(this, { filesStore: false })
  }

  editFile = (data: any) => {
    if (this.id) {
      this.filesStore?.editFile(this.id, data, () => {
        this.updateStore(data)
      })
    }
  }

  deleteFile = () => {
    if (this.id) {
      this.filesStore?.deleteFile(this.id)
    }
  }

  updateName = (name: string) => {
    this.name = name
  }

  updateStore = (data: unknown) => {}

  // get extension() {
  //   return this.name.split(".")?.pop()?.toUpperCase() || ""
  // }

  // get title() {
  //   return this.name.split(".")[0]
  // }

  get title() {
    return this.name.replace(/\.[^/.]+$/, "")
  }

  static initFromFileModel = (file: FileModel) =>
    new FileStore({
      id: file.id,
      name: file.name,
      size: Math.round((file.size / (1024 * 1024)) * 100) / 100,
      url: file.url,
    })

  static initFromDocumentModel = (file: DocumentModel) =>
    new FileStore({
      id: file.pk,
      name: file.name,
      url: file.attachment,
      size: Math.round((file.size / (1024 * 1024)) * 100) / 100,
      visibility: file.visibility,
      createdBy: file.uploaded_by,
      date: file.created,
    })
}

export default FileStore

import React from "react"
import { AdaptiveImagesBlock } from "kui-crm"
import { useFieldArray } from "react-hook-form"
import styled from "@emotion/styled"
import UploadButton from "./UploadButton"
import { InspectionMeterPhotoProps } from "./types"

const InspectionMeterPhoto = (props: InspectionMeterPhotoProps) => {
  const { name, form, disabled } = props
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name,
  })

  return (
    <StyledAdaptiveImagesBlock
      images={fields as any}
      endComponent={<UploadButton disabled={disabled} append={append} />}
      onDelete={remove}
    />
  )
}

export default InspectionMeterPhoto

const StyledAdaptiveImagesBlock = styled(AdaptiveImagesBlock)`
  max-width: 100%;
`

import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import { Loader, Editor, callPromises } from "kui-utils"
import { RentalContractModel, ServiceContractModel } from "kui-crm/types"
import NotesStore from "../../../store/notes/Notes"
import RentalContractInfoStore from "./RentalContractInfoStore"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import HostStore from "../../../store/Root"
import RentalContractAgent from "../../../agent/RentalContract"
import ServiceContractAgent from "../../../agent/ServiceContract"
import { RCInfoFormFields } from "../components/RCInfoFields/types"
import {
  RCChangeGroupModel,
  RCChangeModel,
  RCChangesFields,
  RCChangesResponse,
} from "../types/api/rcInfo"
import FileStore from "../../../store/templates/File"
import { ChangeGroupParams } from "../../../components/common/ChangesTable/types"
import getRCChangeRowParams from "../utils/rcChangesLines"
import ApartmentLiteServicesStore from "../../../store/shared/apartmentService/ApartmentServicesStore"

class RentalContractPageStore {
  contractInfoStore: RentalContractInfoStore

  documentsStore: DocumentsStore

  notesStore: NotesStore | null

  editor: Editor

  loader: Loader

  actionLoader: Loader

  pendingChangesLines: ChangeGroupParams[]

  appliedChangesLines: ChangeGroupParams[]

  lastOpenPeriod: DateTime | null

  constructor(hostStore: HostStore) {
    this.contractInfoStore = new RentalContractInfoStore()
    this.notesStore = null
    this.documentsStore = new DocumentsStore(RentalContractAgent)
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.pendingChangesLines = []
    this.appliedChangesLines = []
    this.lastOpenPeriod = null
    makeAutoObservable(this)
  }

  initRCPage = async (id: number) => {
    this.updateRentalContractPage()

    this.loader.startLoading()

    const [scId] = await callPromises([
      this.fetchRentalContract(id),
      this.documentsStore.fetchDocuments(id),
      this.fetchPendingChanges(id),
      this.fetchAppliedChanges(id),
    ])
    if (scId) await this.fetchServiceContract(scId)
    const apartmentId = this.contractInfoStore.apartment?.id
    if (apartmentId && !this.contractInfoStore.isClosed)
      await this.fetchLastOpenPeriod(apartmentId)

    this.loader.endLoading()
  }

  fetchRentalContract = async (id: number) => {
    const [err, res] = await to<RentalContractModel>(
      RentalContractAgent.getById(id)
    )
    runInAction(() => {
      if (!err && res) {
        this.contractInfoStore.updateRentalContract(res)
        this.notesStore = new NotesStore("rental-contracts", res.id)
      } else {
        this.loader.setError("fetch rental contract", err)
      }
    })

    return res?.service_contract_id
  }

  fetchServiceContract = async (id: number) => {
    const [err, res] = await to<ServiceContractModel>(
      ServiceContractAgent.getById(id)
    )
    runInAction(() => {
      if (!err && res) {
        this.contractInfoStore.updateServiceContract(res)
      } else {
        this.loader.setError("fetch service contract", err)
      }
    })
  }

  fetchPendingChanges = async (id: number) => {
    const [err, res] = await to<RCChangesResponse>(
      RentalContractAgent.getChanges(id)
    )

    runInAction(() => {
      if (!err && res) {
        this.pendingChangesLines = res.map((changeGroup) =>
          RentalContractPageStore.getRCChangeGroup(changeGroup)
        )
      } else {
        this.loader.setError("fetch pending changes", err)
      }
    })
  }

  fetchAppliedChanges = async (id: number) => {
    const [err, res] = await to<RCChangesResponse>(
      RentalContractAgent.getChanges(id, true)
    )

    runInAction(() => {
      if (!err && res) {
        this.appliedChangesLines = res.map((changeGroup) =>
          RentalContractPageStore.getRCChangeGroup(changeGroup)
        )
      } else {
        this.loader.setError("fetch applied changes", err)
      }
    })
  }

  fetchLastOpenPeriod = async (apartmentId: number) => {
    this.loader.startLoading()

    const [err, res] = await ApartmentLiteServicesStore.getLastOpenedPeriod(
      apartmentId
    )

    runInAction(() => {
      if (res) {
        this.lastOpenPeriod = res.period
      } else {
        this.loader.endLoading()
        this.loader.setError("fetch last open period", err)
      }
    })
  }

  deletePendingChange = async (id: number) => {
    const rcId = this.contractInfoStore.id

    if (rcId) {
      this.actionLoader.startLoading("pending change removal")

      const [err] = await to(RentalContractAgent.deleteChange(rcId, id))

      runInAction(() => {
        if (!err) {
          this.pendingChangesLines = this.pendingChangesLines.filter(
            (change) => change.id !== id
          )
        } else {
          this.actionLoader.setError("pending change removal", err)
        }
        this.actionLoader.endLoading()
      })
    }
  }

  deleteRentalContract = async (id: number) => {
    this.actionLoader.startLoading()

    const [err] = await to(RentalContractAgent.delete(id))
    if (err) {
      this.actionLoader.setError("rental contract removal", err)
    }
    this.actionLoader.endLoading()
  }

  patchRentalContract = async (data: RCInfoFormFields) => {
    const contractId = this.contractInfoStore.id
    if (contractId) {
      this.actionLoader.startLoading("rental contract changes")

      await this.contractInfoStore.patchRentalContractInfo(data)
      await this.fetchPendingChanges(contractId)

      this.actionLoader.endLoading()
    }
  }

  updateRentalContractPage = () => {
    this.contractInfoStore = new RentalContractInfoStore()
    this.notesStore = null
    this.documentsStore = new DocumentsStore(RentalContractAgent)
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
  }

  get contractId() {
    return this.contractInfoStore.id
  }

  get usedPendingDates() {
    return this.pendingChangesLines
      .map((line) => line.dateOfChange?.toJSDate())
      .filter((date) => date) as Date[]
  }

  static getRCChangeGroup = (
    changeGroup: RCChangeGroupModel
  ): ChangeGroupParams => ({
    id: changeGroup.id,
    dateOfChange: changeGroup.apply_from
      ? DateTime.fromISO(changeGroup.apply_from)
      : null,
    file: changeGroup.reason_document
      ? FileStore.initFromDocumentModel(changeGroup.reason_document)
      : null,
    createdDate: changeGroup.created
      ? DateTime.fromISO(changeGroup.created)
      : null,
    createdBy: changeGroup.created_by,
    changes: Object.keys(changeGroup.changes).map((changeField) =>
      RentalContractPageStore.getRCChange(
        changeGroup.changes[changeField as keyof RCChangesFields],
        changeField
      )
    ),
  })

  static getRCChange = (change: RCChangeModel<any>, name: string) =>
    getRCChangeRowParams({
      fieldName: name,
      currentValue: change.old,
      newValue: change.new,
    })
}

export default RentalContractPageStore

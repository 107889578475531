import { useLocation } from "react-router"
import { getQueryParam } from "../utils/service/functions"

const useTabIndex = (paths: string[], searchKey: string = "tab") => {
  useLocation()
  const name = getQueryParam(searchKey)

  return Math.max(
    name
      ? paths.findIndex((path) => path.includes(`${searchKey}=${name}` || ""))
      : 0,
    0
  )
}

export default useTabIndex

import * as yup from "yup"

export const ImpairmentInfoFieldsSchema = {
  type: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  solution: yup.string().required("This field is required"),
  proofFile: yup.mixed().required("This field is required"),
  price: yup.string().required("This field is required"),
}

const ImpairmentInfoFormSchema = yup.object().shape(ImpairmentInfoFieldsSchema)

export default ImpairmentInfoFormSchema

import React, { useState } from "react"
import { observer } from "mobx-react"
import ExpensesTabsSettings from "../ExpensesTabsSettings"
import MenuPanel from "../../../../../../../components/ui/MenuPanel"
import useTabIndex from "../../../../../../../hooks/useTabIndex"
import ExpensesSummaryTab from "../../tabs/ExpensesSummaryTab"
import ExpensesMetersTab from "../../tabs/ExpensesMetersTab"
import ExpensesServicesTab from "../../tabs/ExpensesServicesTab"
import ExpensesAttachmentsTab from "../../tabs/ExpensesAttachmentsTab"
import useExpensesStore from "../../../store"
import ExpensesEmptyPlug from "../ExpensesEmptyPlug"

const tabs = ["Summary", "Metering devices", "Lines", "Attachments"]

const paths = [
  `?tab=expenses&subtab=summary`,
  `?tab=expenses&subtab=metering-devices`,
  `?tab=expenses&subtab=lines`,
  `?tab=expenses&subtab=attachments`,
]

const tabPanels = [
  <ExpensesSummaryTab />,
  <ExpensesMetersTab />,
  <ExpensesServicesTab />,
  <ExpensesAttachmentsTab />,
]

const emptyTabPanels = new Array(4).fill(<ExpensesEmptyPlug />)

function ExpensesTabsPanel() {
  const { availableContracts } = useExpensesStore()
  const activeTab = useTabIndex(paths, "subtab")
  const [currentTab, setCurrentTab] = useState(Number(activeTab))

  return (
    <MenuPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={availableContracts.length ? tabPanels : emptyTabPanels}
      label="Expenses"
      handleChange={setCurrentTab}
      endComponent={<ExpensesTabsSettings activeTab={Number(currentTab)} />}
    />
  )
}

export default observer(ExpensesTabsPanel)

import React from "react"
import styled from "@emotion/styled"
import { Caption, Button } from "kui-basic"
import { Link } from "kui-crm"
import { SquareFileBilletProps } from "./types"

function SquareFileBillet({ name, link, size }: SquareFileBilletProps) {
  return (
    <Link href={link} openInNewWindow>
      <StyledButton isCircle size={size || "m"} variant="whiteWithGray">
        <StyledLabel>{name}</StyledLabel>
      </StyledButton>
    </Link>
  )
}

export default SquareFileBillet

const StyledButton = styled(Button)`
  width: 48px;
  height: 48px;
  border-radius: 8px !important;
  overflow: hidden;
  padding: 10px 8px !important;
  text-align: left;
`

const StyledLabel = styled(Caption)`
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`

import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { useMetersValues, RegularInspectionFormFields } from "kui-crm_actions"
import MultistepForm from "../../../../../common/MultistepForm"
import renderImagesSteps from "../../InspectionImagesStep/imagesSteps/renderSteps"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import Loading from "../../../../../common/Loading"
import renderPartialMeterSteps from "../../InspectionMetersStep/PartialMeterInfoStep/renderPartialMeterSteps"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"

const RegularInspectionForm = (props: InspectionFormProps) => {
  const { handleClose, shift } = props
  const { inspectionsStore, metersStore, descriptionStore } =
    useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm

  const handleSubmit = async () => {
    const inspectionId = await inspectionsStore.createRegularInspection(
      formStore.fields as RegularInspectionFormFields
    )
    handleClose(inspectionId)
  }

  useMetersValues(formStore, metersStore)

  return (
    <>
      <MultistepForm stepShift={shift} form={formStore}>
        {metersStore.loader.isLoading && <Loading height={200} />}
        {renderPartialMeterSteps(metersStore.meters)}
        {renderImagesSteps("regular", handleSubmit, descriptionStore.fields)}
      </MultistepForm>
      <LoaderState loader={metersStore.loader} onlyError />
    </>
  )
}

export default observer(RegularInspectionForm)

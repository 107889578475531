import React, { ReactElement } from "react"
import { PartialMeterInfoFields } from "kui-crm_actions"
import PartialMeterInfoStep from "./index"
import MeterPhotoStep from "../MeterPhotoStep"

const renderPartialMeterSteps = (
  meters: PartialMeterInfoFields[]
): ReactElement[][] =>
  meters.map((meter, index) => [
    <PartialMeterInfoStep
      index={index}
      key={`meter_info_${index}`}
      resource={meter.resource}
      type={meter.type}
    />,
    <MeterPhotoStep
      key={`meter_photo_${index}`}
      index={index}
      resource={meter.resource}
    />,
  ])

export default renderPartialMeterSteps

import { DetailApartmentInfoStepFields } from "kui-crm_actions"
import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"

const statisticalValuationFields: InputParams<DetailApartmentInfoStepFields>[] =
  [
    { label: "Name of data source", name: "dataSourceName" },
    { label: "Link of statistical valuation", name: "link" },
    {
      label: "Screenshot statistical valuation",
      name: "screenshot",
      variant: "file",
    },
    {
      label: "Min price",
      name: "minPrice",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
      size: 6,
    },
    {
      label: "Max price",
      name: "maxPrice",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
      size: 6,
    },
  ]

export default statisticalValuationFields

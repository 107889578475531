import React from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { PlusIcon } from "kui-icon"
import { Button } from "kui-basic"
import UploadFilesWithLabel from "../../../../common/UploadFilesWithLabel"
import { InspectionImagesFields, InspectionImagesStepProps } from "./types"
import useApartmentLiteStore from "../../../../../store/shared/apartment"

const InspectionImagesStep = <T extends string>(
  props: InspectionImagesStepProps<T>
) => {
  const {
    name,
    handleSubmit,
    entityName,
    addEntityLabel,
    handleAddEntity,
    type,
    ...other
  } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const formattedName = entityName ? `${entityName}.${name}` : name
  const defaultValues = formStore.getNestedField(formattedName)
  const defaultValuesWithType = { type, ...defaultValues }
  const form = useForm<InspectionImagesFields<typeof name>>({
    defaultValues: {
      [name]: !type ? defaultValues : defaultValuesWithType,
    },
  })

  const handleFormSubmit = (data: InspectionImagesFields<typeof name>) => {
    formStore.addValueToField(name, data[name], entityName)
    if (handleSubmit) handleSubmit(data)
    else formStore.nextStep()
  }

  const handleAdd = (data: InspectionImagesFields<typeof name>) => {
    formStore.addValueToField(name, data[name], entityName)
    handleAddEntity()
  }

  return (
    <form onSubmit={form.handleSubmit(handleFormSubmit)}>
      <UploadFilesWithLabel
        withPrev
        required={false}
        name={name}
        onPrevClick={formStore.prevStep}
        form={form}
        withNext={!handleSubmit}
        variant="images"
        rightButton={
          addEntityLabel && (
            <Button
              size="s"
              variant="orange"
              endIcon={<PlusIcon />}
              onClick={form.handleSubmit(handleAdd)}
            >
              {addEntityLabel}
            </Button>
          )
        }
        {...other}
      />
    </form>
  )
}

InspectionImagesStep.defaultProps = {
  withComment: true,
}

export default observer(InspectionImagesStep)

import React from "react"
import { Grid } from "kui-basic"
import { InputWithController } from "kui-complex"
import styled from "@emotion/styled"
import { useFieldArray } from "react-hook-form"
import { ImagesGroupWithCommentProps } from "./types"
import PhotoContainer from "../PhotoContainer"
import { ImageStoreInterface } from "../../../types/store/image"
import PhotoCards from "../PhotoCards"
import useModalProps from "../../../hooks/useModalProps"
import { UploadFileFields } from "../../../types/common"

const ImagesGroupWithComment = (props: ImagesGroupWithCommentProps) => {
  const { label, name, form, disabled } = props
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: `${name}.images`,
  })
  const modalProps = useModalProps()

  const loadImages = (data: UploadFileFields) => {
    data.files.forEach((file) => {
      append({
        name: file.name || "",
        smallImageUrl: file.url || "",
        mediumImageUrl: file.url || "",
        largeImageUrl: file.url || "",
        file: file.file as File,
      })
    })
  }

  const handleImageDelete = (image: ImageStoreInterface, index: number) => {
    remove(index)
  }

  return (
    <>
      <PhotoContainer
        label={label}
        handleClick={modalProps.handleOpen}
        disabled={disabled}
      >
        <Grid container spacing={3}>
          <Grid item>
            <StyledInput
              isTextArea
              name={`${name}.comment`}
              label="Comment"
              form={form}
              disabled={disabled}
            />
          </Grid>
          <PhotoCards
            images={fields as any}
            loadImages={loadImages}
            displayImagesCount={4}
            onDelete={handleImageDelete}
            modalProps={modalProps}
            disabled={disabled}
          />
        </Grid>
      </PhotoContainer>
    </>
  )
}

export default ImagesGroupWithComment

const StyledInput = styled(InputWithController)`
  textarea {
    width: 465px;
    min-height: 136px;
  }
`

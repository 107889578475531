import React from "react"
import { observer } from "mobx-react"
import { InitialInspectionFormFields } from "kui-crm_actions"
import BasicApartmentInfoStep from "./BasicApartmentInfoStep"
import SecurityApartmentInfoStep from "./SecurityApartmentInfoStep"
import RoomsApartmentInfoStep from "./RoomsApartmentInfoStep"
import DetailApartmentInfoStep from "./DetailApartmentInfoStep"
import MultistepForm from "../../../../../common/MultistepForm"
import renderImagesSteps from "../../InspectionImagesStep/imagesSteps/renderSteps"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import { InspectionRoomsFields } from "../../InspectionImagesStep/types"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"

const InitialInspectionForm = ({ handleClose, shift }: InspectionFormProps) => {
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm

  const handleSubmit = async () => {
    const inspectionId = await inspectionsStore.createInitialInspection(
      formStore.fields as InitialInspectionFormFields
    )
    handleClose(inspectionId)
  }

  return (
    <MultistepForm stepShift={shift} form={formStore}>
      <BasicApartmentInfoStep />
      <SecurityApartmentInfoStep />
      <RoomsApartmentInfoStep />
      <DetailApartmentInfoStep />
      {renderImagesSteps(
        "initial",
        handleSubmit,
        formStore.fields as InspectionRoomsFields
      )}
    </MultistepForm>
  )
}

export default observer(InitialInspectionForm)

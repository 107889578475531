import { UserBankInfoFormFields } from "../../../../forms/ClientBankAccForm/types"
import { FieldGroupsParams } from "../../../../../../components/common/UpdatedFieldsGroup/types"

const userBankInfoFieldsGroup: FieldGroupsParams<UserBankInfoFormFields> = {
  title: "Bank data",
  modalProps: {
    updatedTitle: "Change a personal bank acc",
  },
  fields: [
    {
      label: "Bank name",
      name: "bankInfo.bankName",
      size: 4,
    },
    { label: "SWIFT", name: "bankInfo.SWIFT", size: 4 },
    {
      label: "Bank Identification Code",
      name: "bankInfo.BIC",
      size: 4,
      placeholder: "XXXXXXXXX",
    },
    {
      label: "Correspondent account",
      name: "bankInfo.correspondentAccount",
      size: 6,
      placeholder: "30101XXXXXXXXXXXXXXX",
    },
    {
      label: "Account",
      name: "bankInfo.account",
      size: 6,
      placeholder: "40XXXXXXXXXXXXXXXXXX",
    },
  ],
}

export default userBankInfoFieldsGroup

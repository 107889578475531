import * as React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import Page from "../../components/common/Page"
import AccountantDashboard from "./variants/AccountantDashboard"
import store from "../../store"
import { UserRole } from "../../types/api/cabinet"
import HousingManagerDashboard from "./variants/HousingManagerDashboard"
import HousingInspectorDashboard from "./variants/HousingInspectorDashboard"

const getDashboardByRole = (role: UserRole) => {
  switch (role) {
    case "accountant":
      return <AccountantDashboard />
    case "housing_inspector":
      return <HousingInspectorDashboard />
    case "housing_manager":
      return <HousingManagerDashboard />
    default:
      return null
  }
}

function DashboardPage() {
  const { cabinetStore } = store
  const dashboardCards = cabinetStore.user?.user_role
    ? getDashboardByRole(cabinetStore.user?.user_role)
    : null

  return <StyledPage>{dashboardCards}</StyledPage>
}

export default observer(DashboardPage)

const StyledPage = styled(Page)`
  background: ${({ theme }) => theme.palette.background.light1};
`

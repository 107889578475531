import { BooleanValues } from "kui-crm"
import { FieldGroupsParams } from "../../../../../components/common/UpdatedFieldsGroup/types"
import { GenderValues } from "../../../../../utils/content/listsOptions"
import { UserFullPersonalInfoFields } from "../types"
import PassportUpdatingModal from "../../../components/tabs/ClientPersonalInfoTab/PassportUpdatingModal"

const userPassportFieldsGroup: FieldGroupsParams<UserFullPersonalInfoFields> = {
  title: "Passport",
  UpdateModal: PassportUpdatingModal,
  fields: [
    { label: "Surname", name: "passportInfo.lastName", size: 4 },
    { label: "Name", name: "passportInfo.firstName", size: 4 },
    { label: "Middle name", name: "passportInfo.middleName", size: 4 },
    {
      label: "Sex",
      name: "passportInfo.gender",
      variant: "select",
      options: GenderValues,
      size: 3,
    },
    {
      label: "Birthday",
      name: "passportInfo.birthday",
      variant: "date",
      size: 3,
    },
    {
      label: "Series",
      name: "passportInfo.passportSeries",
      size: 3,
    },
    {
      label: "Number",
      name: "passportInfo.passportNumber",
      size: 3,
    },
    {
      label: "Unit code",
      name: "passportInfo.unitCode",
      size: 3,
    },
    {
      label: "Date of issue",
      name: "passportInfo.dateOfIssue",
      variant: "date",
      size: 3,
    },
    {
      name: "empty",
      variant: "empty",
      size: 6,
    },
    {
      label: "Name of authority",
      name: "passportInfo.nameOfAuthority",
      size: 6,
    },
    {
      label: "Place of birth",
      name: "passportInfo.placeOfBirth",
      size: 6,
    },
    {
      label: "With registration",
      name: "passportInfo.noRegistration",
      variant: "select",
      options: BooleanValues,
      size: 3,
    },
    {
      label: "Place of registration",
      name: "passportInfo.placeOfRegistration",
      size: 9,
    },
  ],
}

export default userPassportFieldsGroup

import React from "react"
import { Button, Box, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { PrevArrowIcon } from "kui-icon"
import Breadcrumbs from "../../ui/Breadcrumbs"
import { PageBreadcrumbsProps } from "./types"

const PageBreadcrumbs = (props: PageBreadcrumbsProps) => {
  const { breadcrumbs, onPrev } = props

  return (
    <Grid item>
      <Grid container alignItems="center">
        <Box mr={2}>
          <StyledButton
            isCircle
            onClick={onPrev}
            size="xs"
            variant="backgroundLight"
          >
            <PrevArrowIcon width={12} height={10} />
          </StyledButton>
        </Box>
        <Breadcrumbs routes={breadcrumbs} />
      </Grid>
    </Grid>
  )
}

export default PageBreadcrumbs

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.palette.background.light3};
`

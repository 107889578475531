import React from "react"
import { useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom, InputByType } from "kui-crm"
import {
  ServicePaymentInfoFormFields,
  ServicePaymentInfoFormProps,
} from "./types"
import ServicePaymentFieldsSchema from "./schema"
import servicePaymentInfoFields from "./fields"

const ServicePaymentInfoForm = (props: ServicePaymentInfoFormProps) => {
  const { handleSubmit, label, formStore, isPeriodClosed } = props
  const form = useForm<ServicePaymentInfoFormFields>({
    defaultValues: {
      ...formStore.fields,
    },
    resolver: yupResolver(ServicePaymentFieldsSchema),
  })

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={3} pr={5} pl={5}>
        <Grid container spacing={2}>
          {servicePaymentInfoFields.map((field) => (
            <Grid item xs={12} key={field.name}>
              <InputByType
                form={form}
                disabled={isPeriodClosed && field.name !== "comment"}
                {...field}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom
        label={label}
        withPrevStep
        handlePrevClick={formStore.prevStep}
      />
    </form>
  )
}

export default ServicePaymentInfoForm

import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import styled from "@emotion/styled"
import { Grid, Box } from "kui-basic"
import { RadioGroupWithLabel } from "kui-complex"
import { FormBottom, PartialPayer } from "kui-crm"
import { ResourceTypes } from "kui-crm/types"
import FieldsGroupTitle from "../../../../components/common/FieldsGroupTitle"
import { APIPayerValues } from "../../../../utils/content/listsOptions"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import SCPayersFormSchema from "./schema"
import useSCsStore from "../../store"
import resourcesPayersFields from "./payersFields"
import SCCommonFields from "../../components/SCCommonFields"
import { ServiceContractAdditionalTermsFields } from "../CreateServiceContractForm/types"

function SCPayersForm() {
  const [apartmentResources, setApartmentResources] = useState<ResourceTypes[]>(
    []
  )
  const { creationForm } = useSCsStore()
  const { fields, nextStep, prevStep, updateFormFields } = creationForm
  const form = useForm({
    resolver: yupResolver(SCPayersFormSchema),
    defaultValues: {
      ...fields,
    },
  })

  useEffect(() => {
    const as = async () => {
      if (fields?.apartment?.id) {
        const resources = await ApartmentsStore.getApartmentResources(
          fields?.apartment.id
        )
        if (resources) setApartmentResources(resources)
        else setApartmentResources([])
      }
    }
    as()
  }, [fields])

  const utilitiesPayersFields = [
    ...resourcesPayersFields.filter((field) =>
      apartmentResources.includes(
        field.name.replace("Payer", "") as ResourceTypes
      )
    ),
  ]

  const handleSubmit = (data: ServiceContractAdditionalTermsFields) => {
    nextStep()
    updateFormFields(data)
  }

  return (
    <form data-testid="form_step_5" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <Box pr={5} pl={5} mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledTitle title="Utilities payers" />
            <RadioGroupWithLabel
              title="Invoice"
              options={APIPayerValues}
              name="utilityPayers.0.payer"
              form={form}
            />
          </Grid>
          {utilitiesPayersFields.map((field, index) => (
            <Grid item xs={12}>
              <PartialPayer
                title={field.label}
                form={form}
                name={`utilityPayers.${index + 1}`}
                utilityType={field.name as ResourceTypes}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom
        withPrevStep
        variant="next"
        handlePrevClick={prevStep}
        label="Next"
      />
    </form>
  )
}

export default observer(SCPayersForm)

const StyledTitle = styled(FieldsGroupTitle)`
  padding-bottom: 8px;
`

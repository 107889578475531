import React, { useEffect } from "react"
import { Loading } from "kui-complex"
import { LoaderState, ModalProps } from "kui-crm"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import DashboardHeader from "../../components/common/DashboardHeader"
import HousingManagerSettingsModal from "./HousingManagerSettingsModal"
import HousingManagerDashboardCards from "./HousingManagerDashboardCards"
import useDashboardFilter from "../../hooks/useDashboardFilter"

const HousingManagerDashboard = () => {
  const { housingManagerDashboard, periodFilter } = useDashboardStore()

  useDashboardFilter(periodFilter, housingManagerDashboard?.loadCards)

  useEffect(() => {
    housingManagerDashboard?.initHousingManagerDashboard()
  }, [])

  if (housingManagerDashboard?.loader.isLoading) {
    return <Loading height="100vh" />
  }

  return (
    <>
      <DashboardHeader
        settings={housingManagerDashboard?.settings}
        renderModal={(props: ModalProps) => (
          <HousingManagerSettingsModal {...props} />
        )}
      />
      <HousingManagerDashboardCards />
      <LoaderState loader={housingManagerDashboard?.loader} onlyError />
    </>
  )
}

export default observer(HousingManagerDashboard)

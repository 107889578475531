import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import { FileBodyRequest } from "kui-crm/types"
import {
  ApartmentFillingModel,
  PostApartmentFillingRequest,
} from "../../../ApartmentsPage/types/api/apartmentsAPI"
import FileStore from "../../../../store/templates/File"
import ApartmentFillingListStore from "./ApartmentFillingList"
import ImageStore from "../../../../store/templates/ImageStore"
import {
  ApartmentFillingVariants,
  FillingTagParams,
} from "../../types/store/apartmentFilling"
import FillingAgent from "../../../../agent/Filling"
import { UploadFillingPhotoFields } from "../../forms/filling/UploadFillingPhoto/types"
import { FillingInfoFormFields } from "../../forms/filling/FillingInfoForm/types"
import {
  getPatchFileParams,
  uploadImages,
  uploadNewFile,
} from "../../../../utils/agent/uploadFiles"
import { clearNotValidFields } from "../../../../utils/service/mapper"

class ApartmentFillingStore {
  id: number

  name: string

  tag: FillingTagParams

  images: ImageStore[]

  serialNumber?: string

  numberOfSubjects?: number

  instruction?: FileStore | null

  fillingListStore: ApartmentFillingListStore

  type: ApartmentFillingVariants

  loader: Loader

  constructor(
    filling: ApartmentFillingModel,
    type: ApartmentFillingVariants,
    fillingListStore: ApartmentFillingListStore
  ) {
    this.id = filling.id
    this.name = filling.description
    this.tag = filling.feature
    this.images = filling.photos?.map((image) =>
      ImageStore.initFromImageModel(image)
    )
    this.serialNumber = filling.serial_code
    this.numberOfSubjects = filling.amount
    this.instruction = filling.instruction
      ? FileStore.initFromDocumentModel(filling.instruction)
      : null
    this.fillingListStore = fillingListStore
    this.type = type
    this.loader = new Loader()
    makeAutoObservable(this, { fillingListStore: false })
  }

  editFilling = async (apartmentId: number, data: FillingInfoFormFields) => {
    this.loader.startLoading("filling changes")

    const instruction = await this.updateInstruction(data)
    const body = ApartmentFillingStore.getPatchFillingBody(data, instruction)

    const response = await to<ApartmentFillingModel>(
      FillingAgent.edit(apartmentId, this.id, body)
    )

    resHandler(response, this.loader, this.updateFilling, "patch filling")
  }

  updateInstruction = async (data: FillingInfoFormFields) => {
    const instruction = getPatchFileParams(data.instruction)
    const newInstruction = await uploadNewFile(this.loader, data.instruction)

    return newInstruction || instruction
  }

  deleteFilling = async (apartmentId: number) => {
    this.loader.startLoading("filling removal")

    const [err] = await to(FillingAgent.delete(apartmentId, this.id))

    if (err) {
      this.loader.setError("filling removal", err)
    } else {
      this.fillingListStore.deleteFillingFromList(this.id, this.type)
    }
    this.loader.endLoading()
  }

  duplicateFilling = async (apartmentId: number) => {
    await this.fillingListStore.duplicateFilling(
      apartmentId,
      this.id,
      this.type
    )
  }

  deleteImage = async (apartmentId: number, imageId: number) => {
    this.loader.startLoading("photo removal")

    const [err] = await to(
      FillingAgent.deletePhoto(apartmentId, this.id, imageId)
    )
    runInAction(() => {
      if (!err) {
        this.images = this.images.filter((image) => image.id !== imageId)
      } else {
        this.loader.setError("filling photo removal", err)
      }
      this.loader.endLoading()
    })
  }

  addImages = async (apartmentId: number, data: UploadFillingPhotoFields) => {
    this.loader.startLoading("image creation")

    const images = await uploadImages(this.loader, data.images)

    await this.editFilling(apartmentId, { tempImages: images, tag: this.tag })

    this.loader.endLoading()
  }

  updateFilling = (filling: Partial<ApartmentFillingModel>) => {
    this.name = filling.description || this.name
    this.tag = filling.feature || this.tag
    this.images =
      filling.photos?.map((image) => ImageStore.initFromImageModel(image)) ||
      this.images
    this.serialNumber = filling.serial_code || this.serialNumber
    this.numberOfSubjects = filling.amount || this.numberOfSubjects
    this.instruction = filling.instruction
      ? FileStore.initFromDocumentModel(filling.instruction)
      : null
  }

  static getPatchFillingBody = (
    data: Partial<FillingInfoFormFields>,
    instruction?: FileBodyRequest | null
  ) =>
    clearNotValidFields({
      feature: data.tag?.id,
      description: data.name,
      serial_code: data.serialNumber,
      amount: Number(data.numberOfSubjects),
      photos: data.tempImages,
      instruction,
    }) as PostApartmentFillingRequest
}

export default ApartmentFillingStore

import * as yup from "yup"

const RCInfoSchemaParams = {
  number: yup.string().trim().required("This field is required"),
  type: yup.string().required("This field is required"),
  contractPeriodType: yup.string().required("This field is required"),
  signDate: yup.mixed().required("This field is required"),
  startDate: yup.mixed().required("This field is required"),
}

const RCPriceTermsSchemaParams = {
  rentPrice: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  initialCommission: yup
    .number()
    .positive()
    .required("This field is required")
    .max(100)
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  securityPayment: yup
    .number()
    .positive()
    .required("This field is required")
    .max(100)
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  installmentPeriod: yup
    .number()
    .positive()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  paymentDay: yup
    .number()
    .positive()
    .max(28)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  notificationPeriod: yup
    .number()
    .positive()
    .max(28)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
}

const RCTerminationsTermsSchemaParams = {
  showingDays: yup
    .number()
    .positive()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  penalty: yup
    .number()
    .positive()
    .required("This field is required")
    .max(100)
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
}

export const RCInfoSchema = yup.object().shape(RCInfoSchemaParams)

export const RCPriceTermsSchema = yup.object().shape(RCPriceTermsSchemaParams)

export const RCTerminationsTermsSchema = yup
  .object()
  .shape(RCTerminationsTermsSchemaParams)

export const RCInfoFullSchema = yup.object().shape({
  ...RCInfoSchemaParams,
  ...RCPriceTermsSchemaParams,
  ...RCTerminationsTermsSchemaParams,
})

import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { getPhoneNumberFromPhoneParams } from "kui-utils"
import { Link } from "kui-crm"
import { PreviewContent } from "kui-crm"
import TableRow from "../../../../components/ui/TableRow"
import TableCell from "../../../../components/ui/TableCell"
import { ClientRowProps } from "./types"
import { Routes } from "../../../../types/route"
import { defaultApartmentImageURL } from "../../../../utils/content/constants"
import UserBadge from "../../../../components/common/UserBadge"
import TextOverflow from "../../../../components/common/TextOverflow"
import ListWithLabel from "../../../../components/ui/ListWithLabel"
import ClientContractRow from "../ClientContractRow"

function ClientRow({ client, isDark }: ClientRowProps) {
  const formattedPhone = client.phone
    ? getPhoneNumberFromPhoneParams(client.phone, " ")
    : ""
  const clientContract = client.contracts[0]
  const contractsLink =
    clientContract?.role === "tenant"
      ? Routes.rental_contracts
      : Routes.service_contracts

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst>{client.id}</TableCell>
      <TableCell width="18%">
        <UserBadge user={client} weight={500} />
      </TableCell>
      {client.contracts.length > 1 ? (
        <StyledListCell colSpan={3}>
          <StyledListWithLabel
            label={`${client.contracts.length} objects`}
            renderList={() =>
              client.contracts.map((contract) => (
                <ClientContractRow
                  key={`${contract.id}_${contract.role}`}
                  contract={contract}
                />
              ))
            }
          />
        </StyledListCell>
      ) : (
        <>
          <TableCell>
            {clientContract?.role
              ? _.capitalize(clientContract.role)
              : "Unknown"}
          </TableCell>
          <TableCell>
            {clientContract?.apartment ? (
              <Link
                href={`${Routes.apartments}/${clientContract.apartment.id}`}
              >
                <PreviewContent
                  imageSrc={
                    clientContract.apartment?.mainImageURL ||
                    defaultApartmentImageURL
                  }
                  description={clientContract.apartment?.address}
                />
              </Link>
            ) : (
              "-"
            )}
          </TableCell>
          <TableCell>
            {clientContract ? (
              <Link href={`${contractsLink}/${clientContract.id}`}>
                {clientContract.number}
              </Link>
            ) : (
              "-"
            )}
          </TableCell>
        </>
      )}
      <TableCell width="14%">
        <TextOverflow weight={500}>{client.email}</TextOverflow>
      </TableCell>
      <TableCell isLast>{formattedPhone}</TableCell>
    </TableRow>
  )
}

export default ClientRow

const StyledListCell = styled(TableCell)`
  padding-left: 5px;
`

const StyledListWithLabel = styled(ListWithLabel)`
  .list_button {
    padding-left: 24%;
  }
`

import React from "react"
import _ from "lodash"
import { Link } from "kui-crm"
import { PreviewContent } from "kui-crm"
import TableRow from "../../../../components/ui/TableRow"
import TableCell from "../../../../components/ui/TableCell"
import { CompanyRowProps } from "./types"
import { Routes } from "../../../../types/route"

function CompanyRow({ company, isDark }: CompanyRowProps) {
  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst>{company.id}</TableCell>
      <TableCell>
        <Link href={`${Routes.companies}/${company.id}`}>
          <PreviewContent imageSrc={company.logo} description={company.name} />
        </Link>
      </TableCell>
      <TableCell>{_.capitalize(company.type)}</TableCell>
      <TableCell>{company.objectsCount}</TableCell>
      <TableCell>{company.address}</TableCell>
      <TableCell>{company.email}</TableCell>
      <TableCell isLast>{company.phone}</TableCell>
    </TableRow>
  )
}

export default CompanyRow

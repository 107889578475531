import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { CompanyAPITypes, CompanyInfoModel } from "kui-crm/types"
import { CompanyTypes } from "../types/store/companyOverview"
import {
  matchesAPICompanyTypes,
  matchesCompanyTypes,
} from "../../../utils/content/matches"
import CompanyAgent from "../../../agent/Company"
import { CompanyInfoFields } from "../forms/CompanyInfoForm/types"
import NotesStore from "../../../store/notes/Notes"

class CompanyOverviewStore {
  id: number

  name: string

  inn: string

  logo?: string | null

  type: CompanyTypes

  loader: Loader

  notesStore: NotesStore

  constructor(company: CompanyInfoModel) {
    this.id = company.id
    this.name = company.name
    this.inn = company.tin
    this.logo = company.logo
    this.type = matchesAPICompanyTypes[company.provider_type] as CompanyTypes
    this.loader = new Loader()
    this.notesStore = new NotesStore("companies", company.id)
    makeAutoObservable(this)
  }

  changeOverviewInfo = async (companyId: number, data: CompanyInfoFields) => {
    this.loader.startLoading("company info changes")

    await Promise.allSettled([
      this.patchCompanyInfo(companyId, data),
      this.changeLogo(data.logo),
    ])

    this.loader.endLoading()
  }

  patchCompanyInfo = async (companyId: number, data: CompanyInfoFields) => {
    const body = CompanyOverviewStore.getPatchInfoBody(data)
    const [err, res] = await to(CompanyAgent.patchCompany(companyId, body))
    if (typeof data.logo !== "string") {
      await this.changeLogo(data.logo)
    }

    runInAction(() => {
      if (!err && res) {
        this.updateOverviewInfo(res)
      } else {
        this.loader.setError(`patch company`, err)
      }
    })
  }

  changeLogo = async (newLogo?: File | string | null) => {
    if (typeof newLogo !== "string" && (this.logo || (!this.logo && newLogo))) {
      if (newLogo) {
        await this.updateLogo(newLogo)
      } else {
        await this.removeLogo()
      }
    }
  }

  updateLogo = async (newLogo: File) => {
    const body = new FormData()
    body.append("logo", newLogo, "logo")

    const [err] = await to(CompanyAgent.changeLogo(this.id, body))
    runInAction(() => {
      if (!err) {
        const url = URL.createObjectURL(newLogo)
        this.logo = url
      } else {
        this.loader.setError("patch logo", err)
      }
    })
  }

  removeLogo = async () => {
    const [err] = await to(CompanyAgent.deleteLogo(this.id))
    runInAction(() => {
      if (!err) {
        this.logo = null
      } else {
        this.loader.setError("logo removal", err)
      }
    })
  }

  updateOverviewInfo = (data: Partial<CompanyInfoModel>) => {
    this.name = data.name ?? this.name
    this.inn = data.tin ?? this.inn
    this.logo = data.logo ?? this.logo
    this.type = data.provider_type
      ? (matchesAPICompanyTypes[data.provider_type] as CompanyTypes)
      : this.type
  }

  static getPatchInfoBody = (data: CompanyInfoFields) => ({
    tin: data.inn,
    name: data.name,
    provider_type: matchesCompanyTypes[data.type] as CompanyAPITypes,
  })
}

export default CompanyOverviewStore

export const MeterTypeLabel = {
  T1: "One-part",
  T2: "Two-part",
  T3: "Three-part",
  cold: "Cold",
  hot: "Hot",
}

export const PaymentStatusLabel = {
  paid: "Yes",
  unpaid: "No",
  overdue: "Overdue",
}

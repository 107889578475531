import React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import ExpensesContracts from "../ExpensesContracts"
import ExpensesCalendar from "../ExpensesCalendar"
import useExpensesStore from "../../../store"
import { ExpensesTabsSettingsProps } from "./types"
import ServiceCreationButton from "../../tabs/ExpensesServicesTab/ServiceCreationButton"
import ExpensesPeriodWarning from "../ExpensesPeriodWarning"

function ExpensesTabsSettings({ activeTab }: ExpensesTabsSettingsProps) {
  const { availableContracts, periodStore } = useExpensesStore()

  return (
    <>
      {!periodStore.isPrevPeriodsClosed && <ExpensesPeriodWarning />}
      {activeTab === 2 && availableContracts.length && (
        <ServiceCreationButton />
      )}
      {availableContracts.length > 1 && (
        <Box mr={2}>
          <ExpensesContracts />
        </Box>
      )}
      <ExpensesCalendar />
    </>
  )
}

export default observer(ExpensesTabsSettings)

import React from "react"
import _ from "lodash"
import { useToggle } from "kui-utils"
import { LoaderState } from "kui-crm"
import { observer } from "mobx-react"
import { MeterReadingRegistryRowProps } from "./types"
import TableCell from "../../../../../../components/ui/TableCell"
import ApartmentLinkCell from "../../../../../../components/common/ApartmentLinkCell"
import TableRow from "../../../../../../components/ui/TableRow"
import SquareFileBillet from "../../../../../../components/common/SquareFileBillet"
import MeterReadingMenuCell from "../MeterReadingMenuCell"
import MeterReadingModal from "../../../../../../components/modals/MeterReadingModal"
import { MeterTypeLabel } from "../../../../../../utils/content/values"

const MeterReadingRegistryRow = (props: MeterReadingRegistryRowProps) => {
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] =
    useToggle()
  const { meter, isDark } = props
  const label = _.capitalize(
    `${meter.meter.type ? `${MeterTypeLabel[meter.meter.type]} ` : ""}${
      meter.resource
    }`
  )

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray" onClick={handleEditModalOpen}>
        {meter.id}
      </TableCell>
      <TableCell onClick={handleEditModalOpen}>
        {meter.apartment?.folderNumber}
      </TableCell>
      <TableCell onClick={handleEditModalOpen}>
        <ApartmentLinkCell apartment={meter.apartment} />
      </TableCell>
      <TableCell onClick={handleEditModalOpen}>{meter.meter.name}</TableCell>
      <TableCell onClick={handleEditModalOpen}>{label}</TableCell>
      <TableCell onClick={handleEditModalOpen}>
        {meter.checkDate?.toFormat("dd.MM.yyyy") || "-"}
      </TableCell>
      <TableCell onClick={handleEditModalOpen}>
        {meter.values?.map((value) => value.toLocaleString()).join(" / ")}
      </TableCell>
      <TableCell onClick={handleEditModalOpen}>
        {meter.file ? (
          <SquareFileBillet name={meter.file.name} link={meter.file.url} />
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell isLast>
        <MeterReadingMenuCell meter={meter} />
      </TableCell>

      <MeterReadingModal
        meter={meter}
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
      />
      <LoaderState loader={meter.loader} />
    </TableRow>
  )
}

export default observer(MeterReadingRegistryRow)

import * as React from "react"
import styled from "@emotion/styled"
import { Box } from "kui-basic"
import _ from "lodash"
import { observer } from "mobx-react"
import { HeadingWithButton } from "kui-crm"
import { CloudIcon } from "kui-icon"
import { FillingTypes } from "kui-crm/types"
import ApartmentFillingSection from "./ApartmentFillingSection"
import Container from "../../../ui/Container"
import { ApartmentFillingVariants } from "../../../../pages/ApartmentPage/types/store/apartmentFilling"
import { ApartmentFillingListProps } from "./types"
import { FillingParams } from "./ApartmentFillingCard/types"

const fillingSections: ApartmentFillingVariants[] = [
  "finish",
  "furniture",
  "electronics",
  "accessories",
]

function FillingList<T extends FillingParams>(
  props: ApartmentFillingListProps<T>
) {
  const { fillingList, disabled, ...other } = props
  const fillingListByType = (type: FillingTypes) =>
    fillingList?.filter((filling) => filling.type === type) || []

  const handleClick = () => {}

  if (!fillingList) return null

  return (
    <Box mt={3}>
      <Container>
        <HeadingWithButton
          onClick={handleClick}
          title="Filling"
          isEditing={!disabled}
          icon={<CloudIcon />}
        />
      </Container>
      <ContentWrapper>
        <div data-testid="filling_sections">
          {fillingSections.map((section) => (
            <ApartmentFillingSection
              key={section}
              title={_.capitalize(section)}
              fillingList={fillingListByType(section as FillingTypes)}
              disabled={disabled}
              {...other}
            />
          ))}
        </div>
      </ContentWrapper>
    </Box>
  )
}

export default observer(FillingList)

const ContentWrapper = styled(Container)`
  @media (min-width: 1200px) {
    max-width: 1344px;
  }
`

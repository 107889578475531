import React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import inspectionsTableHeadCells from "./headCells"
import GridTableHeader from "../../../../../../components/common/GridTableHeader"
import GridTableBody from "../../../../../../components/common/GridTableBody"
import useApartmentStore from "../../../../store"
import ApartmentContractInspections from "../ApartmentContractInspections"
import ApartmentEvaluationInspections from "../ApartmentEvaluationInspections"

function ApartmentInspectionsTable() {
  const { inspectionsStore } = useApartmentStore()
  const { inspectionsByContract } = inspectionsStore

  return (
    <Box mt={3} pb={3}>
      <GridTableHeader cells={inspectionsTableHeadCells} />
      <GridTableBody>
        <ApartmentEvaluationInspections />

        <div>
          {inspectionsByContract.map((store, index) => (
            <ApartmentContractInspections
              key={store.contract.number}
              inspectionsStore={store}
              index={index}
            />
          ))}
        </div>
      </GridTableBody>
    </Box>
  )
}

export default observer(ApartmentInspectionsTable)

import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import _ from "lodash"
import { ApartmentInspectionModel, InspectionTypes } from "kui-crm_actions"

class ApartmentInspectionStore {
  id: number

  name: string

  type: InspectionTypes

  date: DateTime | null

  comment: string

  damages: number | null

  wear: number | null

  impairmentsInspectionId: number | null

  appraisalDate: DateTime | null

  minPrice: number | null

  maxPrice: number | null

  appraisalInspectionId: number | null

  rentalContractId?: number

  rentalContractNumber?: string

  constructor(inspection: ApartmentInspectionModel) {
    this.id = inspection.id
    this.type = inspection.inspection_type
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.name = `${_.capitalize(this.type)} inspection ${
      this.date?.toFormat("dd.MM.yyyy") || ""
    }`
    this.comment = inspection.comment
    this.appraisalDate = inspection.appraisal?.date
      ? DateTime.fromISO(inspection.appraisal.date)
      : null
    this.damages = inspection.impairment?.damage
      ? Number(inspection.impairment.damage)
      : null
    this.wear = inspection.impairment?.wear_and_tear
      ? Number(inspection.impairment.wear_and_tear)
      : null
    this.impairmentsInspectionId = inspection.impairment?.id || null
    this.appraisalInspectionId = inspection.appraisal?.id || null
    this.minPrice = inspection.appraisal?.min_price
      ? Number(inspection.appraisal.min_price)
      : null
    this.maxPrice = inspection.appraisal?.max_price
      ? Number(inspection.appraisal.max_price)
      : null
    this.rentalContractId = inspection.rental_contract?.id
    this.rentalContractNumber = inspection.rental_contract?.number
    makeAutoObservable(this)
  }
}

export default ApartmentInspectionStore

import { RadioGroupWithLabel } from "kui-complex"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { ServicePaymentInfoFormFields } from "./types"
import {
  PayerValues,
  PaymentTypeValues,
} from "../../../../utils/content/listsOptions"

const servicePaymentInfoFields: InputParams<ServicePaymentInfoFormFields>[] = [
  {
    name: "paymentMadeBy",
    title: "Payment made by",
    options: PayerValues,
    direction: "vertical",
    variant: "custom",
    CustomInput: RadioGroupWithLabel,
  },
  {
    name: "refundFrom",
    title: "Refund from",
    options: PayerValues,
    direction: "vertical",
    variant: "custom",
    CustomInput: RadioGroupWithLabel,
  },
  {
    name: "paymentType",
    title: "Payment type",
    options: PaymentTypeValues,
    variant: "custom",
    CustomInput: RadioGroupWithLabel,
  },
  {
    name: "comment",
    label: "Comment",
    isTextArea: true,
  },
]

export default servicePaymentInfoFields

/** @jsxImportSource @emotion/react */
import React from "react"
import { ArrayPath, useFieldArray } from "react-hook-form"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import { InputPhoneWithForm } from "kui-complex"
import { CloseIcon, PlusIcon } from "kui-icon"
import { UserContactsProps } from "../types"
import useClientStore from "../../../store"

function ClientPhoneNumbersFields({ form }: UserContactsProps) {
  const { editor } = useClientStore()

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "phoneNumbers" as ArrayPath<{
      phoneNumber: string
      phoneCode: string
    }>,
  })
  const disabled = !editor.isEditing

  const addPhoneNumber = () => {
    append({ phoneNumber: "", phoneCode: "7" })
  }

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            css={
              fields.length <= 1 &&
              !disabled && { maxWidth: "calc(100% - 44px) !important" }
            }
          >
            <InputPhoneWithForm
              key={fields[0]?.id}
              label="Phone"
              name="phoneNumbers.0"
              data-testid="user_phone_first"
              form={form}
              disabled={disabled}
            />
          </Grid>
          {fields.length <= 1 && !disabled && (
            <Grid item>
              <StyledAddButton
                isCircle
                size="xs"
                variant="transparentWithBorder"
                onClick={addPhoneNumber}
              >
                <PlusIcon width={10} height={10} />
              </StyledAddButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {fields.length > 1 && (
        <Grid item xs={12}>
          <InputPhoneWithForm
            key={fields[1].id}
            label="Phone"
            name="phoneNumbers.1"
            data-testid="user_phone_second"
            form={form}
            disabled={disabled}
            endIcon={
              <StyledButton
                isCircle
                size="xs"
                variant="grayLight"
                data-testid="delete_second_phone_btn"
                onClick={() => remove(1)}
              >
                <CloseIcon width={8} height={8} />
              </StyledButton>
            }
          />
        </Grid>
      )}
    </Grid>
  )
}

export default observer(ClientPhoneNumbersFields)

const StyledButton = styled(Button)`
  border-radius: 8px !important;
`

const StyledAddButton = styled(Button)`
  margin-left: 12px;
`

import * as yup from "yup"

const ApartmentDocumentFormSchema = yup.object({
  number: yup.string().required("This field is required"),
  file: yup.mixed().nullable(),
  startDate: yup.mixed().required("This field is required"),
  endDate: yup.mixed().required("This field is required"),
})

export default ApartmentDocumentFormSchema

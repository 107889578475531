import React from "react"
import { TabsPanel as TabsPanelKUI, TabsPanelProps } from "kui-basic"
import { useNavigate } from "react-router-dom"
import styled from "@emotion/styled"

const TabsPanel = (props: TabsPanelProps) => {
  const navigate = useNavigate()

  return <StyledTabsPanel navigate={navigate} {...props} />
}

export default TabsPanel

const StyledTabsPanel = styled(TabsPanelKUI)`
  .KUI-TabsContainer {
    position: sticky;
    z-index: 4 !important;
    background: white;
  }
  .KUI-TabsWrapper {
    max-width: 1344px;
    margin: 0 auto;
    padding: 0 24px;
  }
  .KUI-Tabs_indicator {
    height: 3px;
  }
  .KUI-Tab {
    height: 55px;
    p {
      color: ${({ theme }) => theme.palette.grey.fiftyP};
    }
  }
  .KUI-Tab_selected {
    p {
      color: ${({ theme }) => theme.palette.grey.seventy};
    }
  }
`

import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const PaymentOrderSchema = {
  paymentOrder: yup.object({
    file: yup.mixed().required(defaultRequiredMessage),
  }),
}

const getClosePeriodFormSchema = (isLastMonthOfContract: boolean) =>
  yup.object().shape(isLastMonthOfContract ? {} : PaymentOrderSchema)

export default getClosePeriodFormSchema

import { makeAutoObservable, runInAction } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { HousingManagerDashboardSettings } from "../../types/store/housingManagerDashboard"
import InspectionsStore from "../entities/InspectionsStore"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import DashboardAgent from "../../../../agent/Dashboard"
import { callPromisesBySettings } from "../../../../utils/service/mapper"
import ContractsStore from "../entities/ContractsStore"
import DashboardPageStore from "../DashboardPageStore"
import MetersVerificationsStore from "../entities/MetersVerificationsStore"

class HousingManagerDashboardStore {
  overdueProxies: number | null

  metersVerifications: MetersVerificationsStore

  inspectionsImpairments: InspectionsStore

  closingServiceContracts: ContractsStore

  closingRentalContracts: ContractsStore

  settings: HousingManagerDashboardSettings | null

  loader: Loader

  actionLoader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.overdueProxies = null
    this.metersVerifications = new MetersVerificationsStore(dashboardStore)
    this.inspectionsImpairments = new InspectionsStore(dashboardStore)
    this.closingServiceContracts = new ContractsStore("service", dashboardStore)
    this.closingRentalContracts = new ContractsStore("rental", dashboardStore)
    this.settings = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    makeAutoObservable(this)
  }

  initHousingManagerDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.actionLoader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.actionLoader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      await callPromisesBySettings(this.settings, {
        overdueProxies: this.fetchOverdueProxies,
        metersVerifications: this.metersVerifications.fetchMetersVerifications,
        inspectionsImpairments: this.inspectionsImpairments.fetchInspections,
        closingRentalContracts: this.closingRentalContracts.fetchContracts,
        closingServiceContracts: this.closingServiceContracts.fetchContracts,
      })
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: HousingManagerDashboardSettings) => {
    this.loader.startLoading()

    const body = HousingManagerDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      overdueProxies: !!visible_blocks.overdue_proxies,
      metersVerifications: !!visible_blocks.meters_require_verification,
      inspectionsImpairments: !!visible_blocks.inspections_require_impairments,
      closingServiceContracts: !!visible_blocks.closing_service_contracts,
      closingRentalContracts: !!visible_blocks.closing_rental_contracts,
    }
  }

  fetchOverdueProxies = async () => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        "overdue_proxies",
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.overdueProxies = res.count
      })
    })
  }

  resetCards = () => {
    this.overdueProxies = null
    this.metersVerifications = new MetersVerificationsStore(this.dashboardStore)
    this.inspectionsImpairments = new InspectionsStore(this.dashboardStore)
    this.closingServiceContracts = new ContractsStore(
      "service",
      this.dashboardStore
    )
    this.closingRentalContracts = new ContractsStore(
      "rental",
      this.dashboardStore
    )
  }

  static getDashboardSettingsBody = (
    settings: HousingManagerDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      overdue_proxies: settings.overdueProxies,
      meters_require_verification: settings.metersVerifications,
      inspections_require_impairments: settings.inspectionsImpairments,
      closing_rental_contracts: settings.closingRentalContracts,
      closing_service_contracts: settings.closingServiceContracts,
    },
  })
}

export default HousingManagerDashboardStore

import { DateTime } from "luxon"
import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import {
  Loader,
  Paginator,
  MultistepForm,
  resHandler,
  callPromises,
} from "kui-utils"
import {
  ApartmentInspectionModel,
  InitialInspectionFormFields,
  inspectionsRequests,
  InventoryInspectionFormFields,
  RegularInspectionFormFields,
  TransferInspectionFormFields,
} from "kui-crm_actions"
import ApartmentInspectionStore from "./ApartmentInspectionStore"
import { GetApartmentInspectionsResponse } from "../../types/api/apartmentInspectionsAPI"
import ApartmentInspectionsAgent from "../../../../agent/ApartmentInspections"
import ContractInspectionsStore from "./ContractInspectionsStore"
import ApartmentPageStore from "../ApartmentPageStore"
import { AppraisalInspectionFormFields } from "../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/types"
import { ImpairmentsInspectionFormFields } from "../../../../components/forms/apartment/InspectionCreationForm/variations/ImpairmentsInspectionForm/types"
import { uploadFiles, uploadImages } from "../../../../utils/agent/uploadFiles"
import ApartmentInspectionsLiteStore from "../../../../store/lites/ApartmentInspectionsLiteStore"

class ApartmentInspectionsStore {
  nextInspectionDate: DateTime | null

  frequency: number

  inspectionsByContract: ContractInspectionsStore[]

  evaluationInspections: ApartmentInspectionStore[]

  loader: Loader

  actionLoader: Loader

  paginator: Paginator

  creationForm: MultistepForm<any>

  apartmentStore: ApartmentPageStore

  constructor(apartmentStore: ApartmentPageStore) {
    this.nextInspectionDate =
      apartmentStore.overviewStore.inspection?.nextInspectionDate || null
    this.frequency =
      apartmentStore.overviewStore.inspection?.inspectionsInterval || 0
    this.inspectionsByContract = []
    this.evaluationInspections = []
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.paginator = new Paginator(5)
    this.creationForm = new MultistepForm<any>()
    this.apartmentStore = apartmentStore
    makeAutoObservable(this, { apartmentStore: false })
  }

  fetchEvaluationInspections = async (apartmentId: number) => {
    this.loader.startLoading()

    const response = await to<GetApartmentInspectionsResponse>(
      ApartmentInspectionsAgent.getAll(
        apartmentId,
        this.paginator.limit,
        this.paginator.offset
      )
    )

    resHandler(
      response,
      this.loader,
      this.updateInspections,
      "fetch inspections"
    )
  }

  createInspection = async (apartmentId: number, body: any) =>
    inspectionsRequests.createInspection(apartmentId, body, this.actionLoader)

  createInventoryInspection = async (data: InventoryInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createInventoryInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createRegularInspection = async (data: RegularInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createRegularInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createInitialInspection = async (data: InitialInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createInitialInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createTransferInspection = async (data: TransferInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createTransferInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createAppraisalInspection = async (data: AppraisalInspectionFormFields) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("inspection creation")
      const uploadedObjectsFiles = data.comparableObjects.map(
        (object) => object.screenshot
      )
      const uploadedValuationsFiles = data.statisticalValuations.map(
        (object) => object.screenshot
      )

      const files = await callPromises([
        uploadFiles(this.actionLoader, uploadedObjectsFiles),
        uploadFiles(this.actionLoader, uploadedValuationsFiles),
      ])
      const body = ApartmentInspectionsLiteStore.getPostBodyAppraisalInspection(
        data,
        files[0],
        files[1]
      )
      return this.createInspection(apartmentId, body)
    }
    return null
  }

  createImpairmentsInspection = async (
    data: ImpairmentsInspectionFormFields
  ) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("inspection creation")
      const uploadedFiles = data.impairments.map(
        (impairment) => impairment.proofFile
      )
      const uploadedImages = data.impairments.map((impairment) =>
        uploadImages(this.actionLoader, impairment.images)
      )

      const files = await callPromises([
        uploadFiles(this.actionLoader, uploadedFiles),
        ...uploadedImages,
      ])
      const body =
        ApartmentInspectionsLiteStore.getPostBodyImpairmentsInspection(
          data,
          files[0],
          files.slice(1)
        )
      return this.createInspection(apartmentId, body)
    }
    return null
  }

  updateInspections = (res: GetApartmentInspectionsResponse) => {
    const mapper = (inspection: ApartmentInspectionModel) =>
      new ApartmentInspectionStore(inspection)

    const result = this.paginator.getPageResponse<
      ApartmentInspectionModel,
      ApartmentInspectionStore
    >(res, this.evaluationInspections, mapper)

    this.evaluationInspections = result
    this.inspectionsByContract = this.allApartmentContracts.map(
      (contract) => new ContractInspectionsStore(contract)
    )
  }

  addNewInspection = (res: ApartmentInspectionModel) => {
    const contractId = res.rental_contract?.id
    if (contractId) {
      const currentContractInspectionsStore = this.inspectionsByContract.find(
        (store) => store.contract.id === contractId
      )

      currentContractInspectionsStore?.addNewInspection(res)
    } else {
      this.evaluationInspections = [
        new ApartmentInspectionStore(res),
        ...this.evaluationInspections,
      ]
    }
  }

  deleteInspectionFromList = (id: number) => {
    this.evaluationInspections = this.evaluationInspections.filter(
      (inspection) => inspection.id !== id
    )
  }

  updateInspectionInfo = () => {
    this.nextInspectionDate =
      this.apartmentStore.overviewStore.inspection?.nextInspectionDate || null
    this.frequency =
      this.apartmentStore.overviewStore.inspection?.inspectionsInterval || 0
  }

  get inspectionsContracts() {
    return this.apartmentStore.overviewStore.rentalContracts
  }

  get allApartmentContracts() {
    return this.apartmentStore.overviewStore.rentalContracts
  }

  get lastEvaluationInspection() {
    const notInitialInspection = this.evaluationInspections.find(
      (inspection) => inspection.type !== "initial"
    )

    return this.evaluationInspections.length === 2
      ? notInitialInspection || this.evaluationInspections[1]
      : this.evaluationInspections[0]
  }

  get initialInspection() {
    return this.evaluationInspections.find(
      (inspection) => inspection.type === "initial"
    )
  }

  get apartmentId() {
    return this.apartmentStore.overviewStore.id
  }
}

export default ApartmentInspectionsStore

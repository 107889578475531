import React from "react"
import { InspectionRoomImagesParams, InspectionTypes } from "kui-crm_actions"
import getRoomsImagesSteps from "./steps"
import InspectionImagesStep from "../index"
import { InspectionRoomsFields } from "../types"

const renderImagesSteps = (
  type: InspectionTypes,
  handleSubmit: (data: InspectionRoomImagesParams) => void,
  roomsFields?: InspectionRoomsFields | null
) => {
  const fields = getRoomsImagesSteps(type, roomsFields)

  return fields.map((field, index) => (
    <InspectionImagesStep
      {...field}
      entityName="roomsImages"
      name={index}
      handleSubmit={index === fields.length - 1 && handleSubmit}
    />
  ))
}

export default renderImagesSteps

import React from "react"
import {
  DistrictAutocomplete,
  CityAutocomplete,
  RegionAutocomplete,
  CountryAutocomplete,
} from "kui-crm"
import { GeolocationParams } from "kui-crm/types"
import { ApartmentParamsInputType } from "../ApartmentDescriptionForm/types"
import { RoomTypesValues } from "../../../../../utils/content/listsOptions"
import CopyButton from "../../../../../components/common/CopyButton"
import InputWithAddressAutocomplete from "../../../../../components/ui/InputWithAddressAutocomplete"
import CountyAutocomplete from "../../../../../components/entityInputs/CountyAutocomplete"

const getApartmentParamsInputs = (
  address: string,
  handlePlaceSelect?: (
    coords: GeolocationParams | null,
    place: google.maps.places.PlaceResult
  ) => void
): ApartmentParamsInputType[] => [
  {
    name: "country",
    label: "Country",
    variant: "custom",
    CustomInput: CountryAutocomplete,
    size: 2,
  },
  {
    name: "region",
    label: "Region",
    variant: "custom",
    CustomInput: RegionAutocomplete,
    size: 2,
  },
  {
    name: "city",
    label: "City",
    variant: "custom",
    CustomInput: CityAutocomplete,
    size: 2,
  },
  {
    name: "county",
    label: "County",
    variant: "custom",
    CustomInput: CountyAutocomplete,
    size: 2,
  },
  {
    name: "district",
    label: "District",
    variant: "custom",
    CustomInput: DistrictAutocomplete,
    size: 2,
  },
  { name: "zipCode", label: "ZIP code", size: 2 },
  {
    name: "apartment",
    handlePlaceSelect,
    endIcon: <CopyButton text={address} />,
    variant: "custom",
    CustomInput: InputWithAddressAutocomplete,
    size: 4,
  },
  { name: "apartmentNumber", label: "Apartment", size: 2 },
  { name: "floor", label: "Floor", size: 1, type: "number" },
  { name: "floorCount", label: "Floors", size: 1, type: "number" },
  { name: "entrance", label: "Entrance", size: 2 },
  {
    name: "typeOfRoom",
    label: "Object type",
    size: 2,
    variant: "select",
    options: RoomTypesValues,
  },
  { name: "geolocation.lat", label: "Latitude", size: 2, type: "number" },
  { name: "geolocation.lng", label: "Longitude", size: 2, type: "number" },
  {
    name: "distanceToCenter",
    label: "Distance to the center",
    size: 2,
    type: "number",
    cornerLabel: "m",
  },
]

export default getApartmentParamsInputs

import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { DateTime } from "luxon"
import EditButton from "../../../../components/ui/EditButton"
import useSCStore from "../../store"
import { SCFieldsHeaderProps } from "./types"
import { SCInfoFormFields } from "../SCInfoFields/types"
import EditContractModal from "../../../../components/modals/EditContractModal"

const SCFieldsHeader = ({ form }: SCFieldsHeaderProps) => {
  const { editor, patchServiceContract, usedPendingDates } = useSCStore()
  const [isModalOpen, handleOpen, handleClose] = useToggle()

  const handleSubmit = (data: SCInfoFormFields) => {
    patchServiceContract(data)
    editor.endEditing()
    form.reset()
  }

  return (
    <>
      <Grid container justify="flex-end">
        <EditButton
          isEditing={editor.isEditing}
          onSaveHandler={form.handleSubmit(handleSubmit)}
          onEditHandler={handleOpen}
        />
      </Grid>

      <EditContractModal
        open={isModalOpen}
        handleClose={handleClose}
        editor={editor}
        form={form}
        usedDates={usedPendingDates}
        lastOpenPeriod={DateTime.now()}
      />
    </>
  )
}

export default observer(SCFieldsHeader)

import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { Loader, SortingFilter, Paginator } from "kui-utils"
import { ResourceTypes } from "kui-crm/types"
import { PaginationStoreInterface } from "../../../types/store/pagination"
import TariffAgent from "../../../agent/Tariff"
import TariffObjectStore from "./TariffObjectStore"
import { GetTariffObjectsResponse } from "../types/api/tariffAPI"

class TariffObjectsStore implements PaginationStoreInterface {
  loader: Loader

  filter: SortingFilter

  paginator: Paginator

  objects: TariffObjectStore[]

  constructor() {
    this.loader = new Loader()
    this.filter = new SortingFilter()
    this.paginator = new Paginator()
    this.objects = []
    makeAutoObservable(this)
  }

  fetchTariffObjects = async (tariffId: number, resource: ResourceTypes) => {
    this.loader.startLoading()

    const [err, res] = await to<GetTariffObjectsResponse>(
      TariffAgent.getTariffObjects(tariffId, resource)
    )
    runInAction(() => {
      if (!err && res) {
        this.objects = res.map((object) => new TariffObjectStore(object))
      } else {
        this.loader.setError("fetch objects")
      }
      this.loader.endLoading()
    })
  }
}

export default TariffObjectsStore

import React, { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { observer } from "mobx-react"
import MenuPanelOfList from "../../components/common/MenuPanelOfList"
import { Routes } from "../../types/route"
import RegistrySearchPanel from "./components/common/RegistrySearchPanel"
import useRegistriesStore from "./store"
import InsuranceRegistryTable from "./components/tables/InsuranceRegistryTable"
import ProxyRegistryTable from "./components/tables/ProxyRegistryTable"
import ExpensesRegistryTable from "./components/tables/ServicesRegistryTable"
import InspectionsRegistryTable from "./components/tables/InspectionsRegistryTable"
import MetersReadingsRegistryTable from "./components/tables/MetersReadingsRegistryTable"

const tabs = [
  "Expenses",
  "Inspections",
  // "Appraisals",
  "Insurances",
  "Proxies",
  "Meter verifications",
  // "Documents",
  // "Payments",
]
const tabPanels = [
  <ExpensesRegistryTable />,
  <InspectionsRegistryTable />,
  // <div />,
  <InsuranceRegistryTable />,
  <ProxyRegistryTable />,
  <MetersReadingsRegistryTable />,
  // <div />,
  // <div />,
]
const paths = [
  Routes.expensesRegistry,
  Routes.inspectionsRegistry,
  // Routes.appraisalsRegistry,
  Routes.insurancesRegistry,
  Routes.proxiesRegistry,
  Routes.metersVerificationsRegistry,
  // Routes.documentsRegistry,
  // Routes.paymentsRegistry,
]

const RegistriesPage = () => {
  const { setActiveRegistry, clear } = useRegistriesStore()
  const location = useLocation().pathname
  const activeTab = Math.max(
    paths.findIndex((name) => name === location),
    0
  )
  const [currentTab, setCurrentTab] = useState(Number(activeTab))

  const handleTabChange = (tabIndex: number) => {
    setActiveRegistry(tabIndex)
    setCurrentTab(tabIndex)
  }

  useEffect(() => {
    setActiveRegistry(activeTab)

    return clear
  }, [])

  return (
    <MenuPanelOfList
      label="Registry"
      activeTab={Number(activeTab)}
      tabs={tabs}
      tabPanels={tabPanels}
      paths={paths}
      endComponent={<RegistrySearchPanel activeTab={currentTab} />}
      handleChange={handleTabChange}
    />
  )
}

export default observer(RegistriesPage)

import {
  ApartmentMetroParams,
  RentalContractModel,
  ServiceContractModel,
  ApartmentModel,
} from "kui-crm/types"
import { linkApartmentMock } from "../user/data"
import { serviceContractMock } from "../service_contracts/data"

export const apartmentReject = {
  non_field_errors: ["Failed to create apartment."],
}

const noNestedApartmentParams = {
  area: "50",
  kitchen_area: "8",
  living_area: "40",
  ceiling_height: "2.5",
  number_of_windows: 2,
  address: "Moskow, Testovskaya",
  rooms_number: 2,
  bedrooms_number: 1,
  restrooms_number: 1,
  apartment_number: "2",
  floor: 2,
  floor_count: 5,
  post_index: "index",
  building_year: 2000,
  num_intercom: "1",
  num_entrance: "1",
  type_houses: "W",
  owner_id: 190,
  financial_personal_account: "51248785458",
}

const nestedApartmentParams = {
  geolocation: {
    lat: 55.845461,
    lon: 37.3688292,
  },
  owner: {
    id: 2,
    email: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    full_name: "Ivan Ivaniv Ivanovich",
    phone: "",
  },
  renter: {
    id: 1,
    email: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    full_name: "Petrov Petr Petrovich",
    phone: "",
  },
  main_image: {
    id: 1,
    image_name: "name",
    image_url: "",
    image_small_url: "main_small_url",
    image_medium_url: "main_small_url",
    image_large_url: "main_small_url",
    size: 1,
  },
}

const apartmentRentalContracts: RentalContractModel[] = [
  {
    id: 1,
    planned_leave_date: "",
    renter_payment_status: "paid",
    next_inspection: "",
    installment: 1,
    auto_prolongate: true,
    rental_type: "long",
    renter: {
      id: 1,
      email: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      full_name: "Alex Petrov Ivanovich",
      phone: "",
    },
    owner: {
      id: 2,
      email: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      full_name: "Ivan Ivaniv Ivanovich",
      phone: "",
    },
    renter_id: 191,
    number: "number",
    apartment: linkApartmentMock,
    sign_date: "2021-05-20",
    start_date: "2021-05-20",
    month_payment: "50000",
    initial_commission: "100",
    security_payment: "100",
    termination_penalty: "5000",
    notify_period_month: 1,
    closing_conditions: {},
  },
]

const apartmentServiceContracts: ServiceContractModel[] = [serviceContractMock]

const apartmentMetroStations: ApartmentMetroParams[] = [
  {
    name: "Новочеркасская",
    metro_station_id: 1,
    distance_walk_meters: 100,
    distance_car_kilometers: "70",
    time_car_minutes: 60,
  },
  {
    name: "Московская",
    metro_station_id: 2,
    distance_walk_meters: 480,
    distance_car_kilometers: "70",
    time_car_minutes: 60,
  },
]

export const partialApartmentMock: Partial<ApartmentModel> = {
  id: 1,
  ...noNestedApartmentParams,
  ...nestedApartmentParams,
  type_houses: "BL",
  rental_contracts: [],
  service_contracts: [],
  metro_stations: [],
  rental_status: "Rented out",
  is_closed_period: false,
  renter_payment_status: "paid",
  is_approved_service_expenses: true,
  locker_number: 98,
  max_electricity_counters: 1,
  max_gas_counters: 1,
  max_heating_counters: 1,
  max_water_counters: 1,
  administrative_company: {
    id: 1,
    name: "Company",
    logo: "",
  },
  room_type: "residential",
  gate_code: "",
  barrier_phone: "",
  concierge_phone: "",
  wifi_name: "",
  wifi_password: "",
  type_gas: "stove",
  hot_water: true,
}

export const fullApartmentMock: Partial<ApartmentModel> = {
  id: 2,
  ...noNestedApartmentParams,
  ...nestedApartmentParams,
  rental_contracts: apartmentRentalContracts,
  service_contracts: apartmentServiceContracts,
  metro_stations: apartmentMetroStations,
  rental_status: "Rented out",
  type_houses: "BL",
  is_closed_period: false,
  renter_payment_status: "paid",
  is_approved_service_expenses: true,
  locker_number: 45,
  max_electricity_counters: 1,
  max_gas_counters: 1,
  max_heating_counters: 1,
  max_water_counters: 1,
  administrative_company: {
    id: 1,
    name: "Company",
    logo: "",
  },
  room_type: "residential",
  gate_code: "",
  barrier_phone: "",
  concierge_phone: "",
  wifi_name: "",
  wifi_password: "",
  type_gas: "stove",
  hot_water: true,
}

export const fetchApartments = {
  count: 1,
  next: null,
  previous: null,
  results: [fullApartmentMock],
}

export const postApartment = {
  id: 1,
  area: 50,
  address: "Test address",
  rooms_number: 2,
  floor: 5,
  owner_id: 2,
  geolocation: {
    lat: 1,
    lon: 1,
  },
}

export const liteApartmentContractMock = {
  id: 1,
  number: "bla-bla",
  sign_date: "2022-10-15",
  start_date: "2022-10-15",
}

export const liteApartmentContractsMock = [liteApartmentContractMock]

import React from "react"
import { Grid } from "kui-basic"
import { InputWithController } from "kui-complex"
import { UserCarFieldsProps } from "./types"

function ClientCarFields({
  inputKey,
  index,
  form,
  disabled,
}: UserCarFieldsProps) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <InputWithController
          key={inputKey}
          label="Type"
          name={`cars.${index}.mark`}
          form={form}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputWithController
          key={inputKey}
          label="Number"
          name={`cars.${index}.number`}
          placeholder="А000АА 000"
          form={form}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default ClientCarFields

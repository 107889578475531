import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { LoaderState } from "kui-crm"
import { ApartmentContractInspectionProps } from "./types"
import AccordionRow from "../../../../../../components/common/AccordionRow"
import ApartmentInspectionRow from "../ApartmentInspectionRow"
import useApartmentStore from "../../../../store"
import Loading from "../../../../../../components/common/Loading"
import ScrollingContainer from "../../../../../../components/common/ScrollingContainer"

function ApartmentContractInspections(props: ApartmentContractInspectionProps) {
  const { inspectionsStore, index } = props
  const { overviewStore } = useApartmentStore()
  const { loader, inspections, fetchContractInspections, contract, paginator } =
    inspectionsStore

  useEffect(() => {
    if (paginator.isFetching && !paginator.isFinishPage && overviewStore.id) {
      fetchContractInspections(overviewStore.id)
    }
    if (paginator.isFetching && paginator.isFinishPage) {
      paginator.stopFetching()
    }
  }, [paginator, paginator.isFetching])

  const handleAccordionOpen = (expanded: boolean) => {
    if (expanded && index !== 0) {
      paginator.startFetching()
    }
  }

  useEffect(() => {
    if (index === 0) {
      paginator.startFetching()
    }
  }, [])

  return (
    <AccordionRow
      title={`Rental contract ${contract.number}`}
      onChange={handleAccordionOpen}
      expanded={index === 0}
    >
      <StyledAccordionContent onScrollEnd={paginator.startFetching}>
        {inspections?.map((inspection, rowIndex) => (
          <ApartmentInspectionRow
            key={inspection.id}
            inspection={inspection}
            index={rowIndex}
          />
        ))}
        {loader.isLoading && <Loading height="72px" />}
        <LoaderState loader={loader} onlyError />
      </StyledAccordionContent>
    </AccordionRow>
  )
}

export default observer(ApartmentContractInspections)

const StyledAccordionContent = styled(ScrollingContainer)`
  padding-top: 2px;
  max-height: 288px;
`

import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { ApartmentDashboardModel } from "../../types/api/apartments"
import ApartmentsStore from "./ApartmentsStore"
import DashboardAgent from "../../../../agent/Dashboard"

class ApartmentStore {
  notifyId: number

  id: number

  name: string

  logo: string | null

  notificationDate: DateTime | null

  apartmentsStore: ApartmentsStore

  loader: Loader

  constructor(
    apartmentNotify: ApartmentDashboardModel,
    apartmentsStore: ApartmentsStore
  ) {
    const { apartment, id, created } = apartmentNotify
    this.notifyId = id
    this.id = apartment.id
    this.name = apartment.object_name || apartment.address
    this.logo =
      apartment.main_image?.image_small_url ||
      apartment.main_image?.image_url ||
      null
    this.notificationDate = created ? DateTime.fromISO(created) : null
    this.apartmentsStore = apartmentsStore
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  archiveApartment = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.archiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.apartmentsStore.removeFromApartmentsList(this.id)
    })
  }

  restoreApartment = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.unArchiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.apartmentsStore.removeFromArchiveList(this.id)
    })
  }
}

export default ApartmentStore

/** @jsxImportSource @emotion/react */
import * as React from "react"
import { useTheme } from "@emotion/react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { addToArrayByCondition } from "kui-utils"
import { HalfArrowNextIcon } from "kui-icon"
import { Link } from "kui-crm"

export type BreadcrumbsRoutesType = {
  link: string | null
  title: string | undefined
}

type BreadcrumbsProps = {
  routes: BreadcrumbsRoutesType[]
}

function Breadcrumbs({ routes }: BreadcrumbsProps) {
  const theme = useTheme()

  const styles = {
    cursor: "pointer",
    transition: "all linear .2s",
    "&:hover": {
      color: theme.palette.grey.seventy,
    },
  }

  return (
    <StyledWrapper container alignItems="center">
      {routes.map((item, index) => [
        <StyledLink href={item.link}>
          <Caption
            size="xs"
            weight={500}
            color="fourty"
            css={item.link && styles}
            key={`${item.title}_${item.link}`}
          >
            {item.title}
          </Caption>
        </StyledLink>,
        ...addToArrayByCondition(
          index < routes.length - 1,
          <StyledIcon width={7} height={9} />
        ),
      ])}
    </StyledWrapper>
  )
}

export default Breadcrumbs

const StyledWrapper = styled(Grid)`
  width: auto;
`

const StyledLink = styled(Link)`
  width: auto;
  &:not(:last-of-type) {
    margin-right: 8px;
  }
  &:not(:first-of-type) {
    margin-left: 8px;
  }
`

const StyledIcon = styled(HalfArrowNextIcon)`
  path {
    fill: ${({ theme }) => theme.palette.grey.fourty};
  }
`

import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { useFieldArray } from "react-hook-form"
import { observer } from "mobx-react"
import { Heading, Box } from "kui-basic"
import {
  InspectionRoomFields,
  InspectionRoomsPhotoFields,
  InspectionRoomsPhotoProps,
} from "./types"
import Container from "../../../../../../components/ui/Container"
import ImagesGroupWithComment from "../../../../../../components/common/ImagesGroupWithComment"
import useInspectionStore from "../../store"

const InspectionRoomsPhoto = (props: InspectionRoomsPhotoProps) => {
  const { editor } = useInspectionStore()
  const { form } = props
  const { fields } = useFieldArray<InspectionRoomsPhotoFields>({
    control: form.control,
    name: "roomsImages",
  })
  const spacesFields = fields as InspectionRoomFields[]

  return (
    <>
      <Container>
        <Box pt={3} mb={2}>
          <Heading size="h4">Photos</Heading>
        </Box>
      </Container>
      <StyledContainer>
        {spacesFields.map((room, index) => (
          <ImagesGroupWithComment
            label={
              room.type === "combined_bathroom"
                ? "Combined bathroom"
                : `${_.capitalize(room.type)} ${room.roomNumber || ""}`
            }
            form={form}
            name={`roomsImages.${index}`}
            disabled={!editor.isEditing}
          />
        ))}
      </StyledContainer>
    </>
  )
}

export default observer(InspectionRoomsPhoto)

const StyledContainer = styled(Container)`
  @media (min-width: 1200px) {
    max-width: 1392px;
  }
`

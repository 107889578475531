import * as React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import MenuPanel from "../../../../../components/ui/MenuPanel"
import useClientStore from "../../../store"
import useTabIndex from "../../../../../hooks/useTabIndex"
import clientDataTabContent from "./content"

function ClientDataTab() {
  const { editor, overviewStore } = useClientStore()
  const clientType = overviewStore?.type
  const content = clientDataTabContent[clientType || "personal"]
  const activeTab = useTabIndex(content.paths, "subtab")

  return (
    <Box pt={3} pb={3}>
      <MenuPanel
        activeTab={activeTab}
        paths={content.paths}
        label="Data"
        tabs={content.tabs}
        tabPanels={content.tabPanels}
        handleChange={editor.endEditing}
      />
    </Box>
  )
}

export default observer(ClientDataTab)

import { FileBodyRequest } from "kui-crm/types"
import requests from "./index"
import { ApprovalServicesRequest } from "../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesServicesAPI"
import {
  AbortServiceRequest,
  CheckCompanyIsNewRequest,
  PatchServiceRequest,
  PostServiceRequest,
} from "../types/api/apartmentExpenses"

const ApartmentServicesAgent = {
  getByApartmentId: (
    apartmentId: number,
    contractId: number,
    periodId: number
  ) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/services?rental_contract=${contractId}`
    ),
  getPrevServices: (apartmentId: number, periodId: number) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/services/previous_list`
    ),
  create: (apartmentId: number, data: PostServiceRequest) =>
    requests.post(`/apartments/${apartmentId}/expenses/services`, data),
  patch: (
    apartmentId: number,
    id: number,
    data: Partial<PatchServiceRequest>
  ) =>
    requests.patch(`/apartments/${apartmentId}/expenses/services/${id}`, data),
  delete: (apartmentId: number, id: number) =>
    requests.delete(`/apartments/${apartmentId}/expenses/services/${id}`),
  postDocument: (
    data: FileBodyRequest,
    apartmentId: number,
    expenseId: number
  ) =>
    requests.post(
      `/apartments/${apartmentId}/expenses/services/${expenseId}/documents`,
      data
    ),
  patchDocument: (
    documentId: number,
    data: FileBodyRequest,
    apartmentId: number,
    expenseId: number
  ) =>
    requests.put(
      `/apartments/${apartmentId}/expenses/services/${expenseId}/documents/${documentId}`,
      data
    ),
  deleteDocument: (
    documentId: number,
    apartmentId: number,
    expenseId: number
  ) =>
    requests.delete(
      `/apartments/${apartmentId}/expenses/services/${expenseId}/documents/${documentId}`
    ),
  approvalServices: (
    apartmentId: number,
    periodId: number,
    data: ApprovalServicesRequest
  ) =>
    requests.post(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/generate-invoice`,
      data
    ),
  duplicateService: (apartmentId: number, serviceId: number) =>
    requests.post(
      `/apartments/${apartmentId}/expenses/services/${serviceId}/copy`
    ),
  getLastOpenPeriod: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/expenses/bill-periods/last-open`),
  getPendingServices: (apartmentId: number) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/bill-periods/pending-services`
    ),
  abortService: (
    apartmentId: number,
    serviceId: number,
    body: AbortServiceRequest
  ) =>
    requests.post(
      `/apartments/${apartmentId}/expenses/services/${serviceId}/abort`,
      body
    ),
  checkCompanyIsNew: (
    apartmentId: number,
    periodId: number,
    data: CheckCompanyIsNewRequest
  ) =>
    requests.post(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/check-new-company`,
      data
    ),
}

export default ApartmentServicesAgent

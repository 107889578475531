import React from "react"
import { observer } from "mobx-react"
import { NextArrowIcon } from "kui-icon"
import { MeterFeaturesForm, MeterFeaturesFormFields } from "kui-crm"
import { MeterInfoStepProps } from "kui-crm_actions"
import ModalLabel from "../../../../../common/ModalLabel"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import getMeterLabel from "../../../../../../utils/service/getMeterLabel"

const FullMeterInfoStep = (props: MeterInfoStepProps) => {
  const { resource, index } = props
  const { inspectionsStore, overviewStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const type = formStore.fields?.meters[index].type
  const label = `Add info about ${getMeterLabel(resource, type)}`

  const handleSubmit = (data: MeterFeaturesFormFields) => {
    formStore.replaceFieldInArray("meters", { ...data, resource }, index)
    formStore.nextStep()
  }

  return (
    <div>
      <ModalLabel label={label} />
      <MeterFeaturesForm
        withPassport
        withNextCheck={false}
        withLastValue={false}
        resource={resource}
        formStore={formStore}
        isNewResource={false}
        apartmentId={overviewStore.id}
        administrativeCompany={overviewStore.managementCompany}
        handleSubmit={handleSubmit}
        defaultValues={formStore.fields?.meters?.[index]}
        formBottomProps={{
          label: "Next",
          endIcon: <NextArrowIcon />,
          withPrevStep: true,
          handlePrevClick: formStore.prevStep,
        }}
      />
    </div>
  )
}

export default observer(FullMeterInfoStep)

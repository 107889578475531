import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import { PayoutsModel } from "../../types/api/expensesSummaryAPI"
import ApartmentExpensesAgent from "../../../../../../agent/ApartmentExpenses"
import PayoutStore from "./Payout"
import { ApartmentPayoutsParams } from "../../../../types/store/apartment"
import ApartmentOwnExpensesStore from "./ApartmentOperatingExpenses"
import ApartmentSummaryStore from "./ApartmentSummary"

class ApartmentPayoutsStore {
  payouts: ApartmentPayoutsParams[]

  ownExpansesStore: ApartmentOwnExpensesStore | null

  summaryStore: ApartmentSummaryStore

  loader: Loader

  constructor(summaryStore: ApartmentSummaryStore) {
    this.loader = new Loader()
    this.payouts = []
    this.ownExpansesStore = null
    this.summaryStore = summaryStore
    makeAutoObservable(this)
  }

  fetchPayouts = async (
    apartmentId: number,
    contractId: number,
    periodId: number,
    withoutLoader?: boolean
  ) => {
    if (!withoutLoader) this.loader.startLoading("payouts")

    const [err, res] = await to<PayoutsModel>(
      ApartmentExpensesAgent.getBillLinesById(apartmentId, contractId, periodId)
    )

    if (res && !err) {
      this.updatePayouts(res)
    } else {
      this.loader.setError("fetch payouts")
    }
    this.loader.endLoading()
  }

  updatePayouts = (res: PayoutsModel) => {
    this.payouts = res.bills.map((payouts) => {
      const tenantPayout = payouts.renter_lines
        ? new PayoutStore(payouts.renter_lines, payouts)
        : null
      const landlordPayout = payouts.owner_lines
        ? new PayoutStore(payouts.owner_lines, payouts)
        : null

      return {
        id: payouts.id,
        number: payouts.number,
        tenantPayout,
        landlordPayout,
      }
    })

    this.ownExpansesStore = new ApartmentOwnExpensesStore(
      res.operating_expenses,
      res.period_id
    )

    this.summaryStore.paymentsStore.updatePaymentsLines(res.payments)
  }
}

export default ApartmentPayoutsStore

import { HomeIcon } from "kui-icon"
import React from "react"
import styled from "@emotion/styled"
import DashboardListCard from "../../common/DashboardListCard"
import { ApartmentsInspectionsCardProps } from "./types"
import ApartmentInspectionRow from "./ApartmentInspectionRow"
import ApartmentInspectionsHeader from "./ApartmentInspectionsHeader"

const ApartmentsInspectionsCard = (props: ApartmentsInspectionsCardProps) => {
  const { value, done, inspections } = props

  return (
    <StyledCard
      value={value}
      label="Upcoming inspections"
      icon={<HomeIcon />}
      customHeader={<ApartmentInspectionsHeader value={value} done={done} />}
    >
      {inspections.map((inspection) => (
        <ApartmentInspectionRow key={inspection.id} inspection={inspection} />
      ))}
    </StyledCard>
  )
}

export default ApartmentsInspectionsCard

const StyledCard = styled(DashboardListCard)`
  .PaginationList {
    max-height: 346px;
  }
`

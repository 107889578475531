import React from "react"
import {
  CountryAutocomplete,
  RegionAutocomplete,
  CityAutocomplete,
} from "kui-crm"
import { InputsGroupWithTitleParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { InitialInspectionFormFields } from "../types"
import CopyButton from "../../../../../../../components/common/CopyButton"
import InputWithAddressAutocomplete from "../../../../../../../components/ui/InputWithAddressAutocomplete"
import {
  BooleanValues,
  RoomTypesValues,
} from "../../../../../../../utils/content/listsOptions"
import { getApartmentDetailInfoFields } from "../../../../../../../utils/formFields/apartment"

const getInitialInspectionInfoFieldGroup = (
  address?: string,
  roomCount?: number | null,
  bedroomsCount?: number | null
): InputsGroupWithTitleParams<InitialInspectionFormFields>[] => [
  {
    title: "Main object info",
    fields: [
      {
        name: "country",
        label: "Country",
        variant: "custom",
        CustomInput: CountryAutocomplete,
        size: 2,
      },
      {
        name: "region",
        label: "Region",
        variant: "custom",
        CustomInput: RegionAutocomplete,
        size: 2,
      },
      {
        name: "city",
        label: "City",
        variant: "custom",
        CustomInput: CityAutocomplete,
        size: 2,
      },
      {
        name: "apartment",
        endIcon: <CopyButton text={address || ""} />,
        variant: "custom",
        CustomInput: InputWithAddressAutocomplete,
        size: 4,
      },
      { name: "apartmentNumber", label: "Apartment", size: 2 },
      { name: "zipCode", label: "ZIP code", size: 2 },
      { name: "floor", label: "Floor", size: 1, type: "number" },
      { name: "floorCount", label: "Floors", size: 1, type: "number" },
      { name: "entrance", label: "Entrance", size: 2 },
      {
        name: "typeOfRoom",
        label: "Object type",
        size: 2,
        variant: "select",
        options: RoomTypesValues,
      },
    ],
  },
  {
    title: "Security and passwords",
    fields: [
      {
        name: "hasIntercom",
        label: "Intercom",
        size: 2,
        variant: "select",
        options: BooleanValues,
      },
      { name: "intercomCode", label: "Intercom code", size: 2 },
      {
        name: "hasGate",
        label: "Gate",
        size: 2,
        variant: "select",
        options: BooleanValues,
      },
      { name: "gateCode", label: "Gate code", size: 2 },
    ],
  },
  {
    title: "Detail info",
    fields: getApartmentDetailInfoFields(roomCount, bedroomsCount),
  },
]

export default getInitialInspectionInfoFieldGroup

import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const RCCheckOutDateFormSchema = yup.object().shape({
  dateOfLeaving: yup.mixed().required(defaultRequiredMessage),
  reasonOfLiving: yup.object({
    file: yup.mixed().required(defaultRequiredMessage),
  }),
})

export default RCCheckOutDateFormSchema

import React, { useEffect } from "react"
import { useLocation } from "react-router"
import { observer } from "mobx-react"
import { ModalProps, TariffCreationModal } from "kui-crm"
import { ResourceTypes } from "kui-crm/types"
import { Routes } from "../../../../types/route"
import TableSearchWithCreating from "../../../../components/common/TableSearchWithCreating"
import useTariffsStore, { tariffsStore } from "../../store"
import TariffsTable from "../TariffsTable"
import MenuPanelOfList from "../../../../components/common/MenuPanelOfList"

const tabs = ["Electricity", "Water", "Gas", "Heating"]
const tabPanels = [
  <TariffsTable resource="electricity" />,
  <TariffsTable resource="water" />,
  <TariffsTable resource="gas" />,
  <TariffsTable resource="heating" />,
]
const paths = [
  Routes.electricityTariffsGroups,
  Routes.waterTariffsGroups,
  Routes.gasTariffsGroups,
  Routes.heatingTariffsGroups,
]

function TariffsTabPanel() {
  const { resource, setActiveResource } = useTariffsStore()
  const { paginator, filter } = resource

  const location = useLocation().pathname
  const activeTab = Math.max(
    paths.findIndex((name) => name === location),
    0
  )

  useEffect(() => {
    setActiveResource(tabs[activeTab].toLowerCase() as ResourceTypes)
  }, [])

  const handleTabChange = (index: number) => {
    setActiveResource(tabs[index].toLowerCase() as ResourceTypes)
  }

  return (
    <MenuPanelOfList
      label="Tariffs Groups"
      activeTab={Number(activeTab)}
      tabs={tabs}
      tabPanels={tabPanels}
      paths={paths}
      handleChange={handleTabChange}
      endComponent={
        <TableSearchWithCreating filter={filter} paginator={paginator}>
          {(props: ModalProps) => (
            <TariffCreationModal
              creationForm={tariffsStore.creationForm}
              createTariff={tariffsStore.createTariff}
              defaultResource={tariffsStore.activeResource}
              {...props}
            />
          )}
        </TableSearchWithCreating>
      }
    />
  )
}

export default observer(TariffsTabPanel)

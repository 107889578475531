import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react"
import styled from "@emotion/styled"
import { ScrollingContainerProps, ScrollingContainerStylesProps } from "./types"
import useScrollWithBottomSpacing from "../../../hooks/useScrollWithBottomSpacing"

const ScrollingContainer = forwardRef(
  (props: ScrollingContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { onScrollEnd, ...otherProps } = props
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [isScrollable, setIsScrollable] = useState(false)
    // eslint-disable-next-line
    const withScrollListener = onScrollEnd
      ? useScrollWithBottomSpacing(10, 200, onScrollEnd, containerRef)
      : null

    useEffect(() => {
      if (containerRef.current) {
        const hasScroll =
          containerRef.current.scrollHeight > containerRef.current.clientHeight
        setIsScrollable(hasScroll)
      }
    })

    const forwardedRef = (elem: HTMLDivElement) => {
      if (ref) {
        if (typeof ref === "function") {
          ref(elem)
        } else {
          ref.current = elem
        }
      }

      containerRef.current = elem
    }

    return (
      <StyledWrapper
        isScrollable={isScrollable}
        ref={forwardedRef}
        {...otherProps}
      />
    )
  }
)

export default ScrollingContainer

const StyledWrapper = styled.div<ScrollingContainerStylesProps>`
  overflow-y: auto;
  position: relative;
  padding-right: ${({ isScrollable }) => (isScrollable ? 8 : 0)}px;
  ::-webkit-scrollbar {
    width: 8px;
    position: absolute;
    right: 8px;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: all ease-out 0.3s;
  }

  ::-webkit-resizer,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    display: none;
  }
  &:hover {
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.grey.fifteenB};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.grey.thirty};
    }
  }
`

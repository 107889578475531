import { DateTime } from "luxon"
import _ from "lodash"
import { OptionParams } from "kui-complex/InputSelectBase"
import { UtilityPayerModel } from "kui-crm/types"
import { ChangeParams } from "../../../components/common/ChangesTable/types"
import { getChangeRowParams } from "../../../components/common/ChangesTable/ChangeLine/ChangeLineTableRow/content"
import { matchesSubjectRoles } from "../../../utils/content/matches"
import {
  RentalTypesValues,
  UnitOfPaymentValues,
} from "../../../utils/content/listsOptions"
import { FixedCostModel } from "../../../types/api/service_contracts"

const getFixedCostValue = (expense: FixedCostModel) =>
  `${Number(expense.value).toLocaleString()} ₽ | ${_.capitalize(
    matchesSubjectRoles[expense.payer as keyof typeof matchesSubjectRoles]
  )}`

const mergeFixedCosts = (oldList: any[], newList: any[]) => {
  const result: ChangeParams[] = []

  oldList.forEach((value) => {
    result.push({
      fieldName: value.name,
      currentValue: getFixedCostValue(value),
      newValue: "",
    })
  })

  newList.forEach((value) => {
    const oldValueIndex = result.findIndex(
      (oldValue) => oldValue.fieldName === value.fieldName
    )

    if (oldValueIndex >= 0) {
      result[oldValueIndex].newValue = getFixedCostValue(value)
    } else {
      result.push({
        fieldName: value.name,
        newValue: getFixedCostValue(value),
        currentValue: "",
      })
    }
  })

  return result
}

const getResourcePayerValue = (value: UtilityPayerModel) =>
  `${
    value.payment_made_by
      ? `${Number(value.refund_value).toLocaleString()} ${
          UnitOfPaymentValues.find(
            (option) => option.value === value.value_type
          )?.label || ""
        } ${_.capitalize(
          matchesSubjectRoles[value.refund_from]
        )} + ${_.capitalize(matchesSubjectRoles[value.payment_made_by!])}`
      : _.capitalize(matchesSubjectRoles[value.refund_from!])
  }`

const getSCChangeRowParams = (change: ChangeParams): ChangeParams => {
  switch (change.fieldName) {
    case "number": {
      return getChangeRowParams(change)
    }
    case "auto_prolongate": {
      return getChangeRowParams(change, "Auto prolongation", (value: boolean) =>
        value ? "Yes" : "No"
      )
    }
    case "sign_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "start_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "end_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "initial_commission": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} %`
      )
    }
    case "maintenance_commission": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} %`
      )
    }
    case "min_rent_price": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} ₽`
      )
    }
    case "max_rent_price": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} ₽`
      )
    }
    case "taxes_type": {
      return getChangeRowParams(change)
    }
    case "advertising_period": {
      return getChangeRowParams(change)
    }
    case "inspection_interval_month": {
      return getChangeRowParams(change, "Inspection")
    }
    case "allowable_price_deviation": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} %`
      )
    }
    case "penalty_in_preparation": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} ₽`
      )
    }
    case "notify_period_month": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} month`
      )
    }
    case "allowed_pets": {
      return getChangeRowParams(change, "Pets allowed", (value: boolean) =>
        value ? "Yes" : "No"
      )
    }
    case "allowed_children": {
      return getChangeRowParams(change, "Children allowed", (value: boolean) =>
        value ? "Yes" : "No"
      )
    }
    case "additional_terms": {
      return getChangeRowParams(change)
    }
    case "long_term_type": {
      return getChangeRowParams(change, "", (value) =>
        RentalTypesValues.filter((option: OptionParams) =>
          value.includes(option.value)
        )
          .map((option: OptionParams) => option.label)
          .join(", ")
      )
    }
    case "fixed_costs": {
      return {
        fieldName: "Fixed costs:",
        currentValue: "",
        newValue: "",
        lines: mergeFixedCosts(change.currentValue, change.newValue),
      }
    }
    case "utility_payer": {
      return getChangeRowParams(change, "Invoice payer", getResourcePayerValue)
    }
    case "electricity_payer": {
      return getChangeRowParams(change, "Invoice payer", getResourcePayerValue)
    }
    case "gas_payer": {
      return getChangeRowParams(change, "Invoice payer", getResourcePayerValue)
    }
    case "heating_payer": {
      return getChangeRowParams(change, "Invoice payer", getResourcePayerValue)
    }
    case "water_payer": {
      return getChangeRowParams(change, "Invoice payer", getResourcePayerValue)
    }
    default: {
      return {
        fieldName: "",
        currentValue: "",
        newValue: "",
      }
    }
  }
}

export default getSCChangeRowParams

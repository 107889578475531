import { RentalContractModel } from "kui-crm/types"
import { linkApartmentMock } from "../user/data"

export const rentalContract: RentalContractModel = {
  id: 18,
  auto_prolongate: true,
  installment: 1,
  rental_type: "long",
  planned_leave_date: "",
  renter_payment_status: "paid",
  next_inspection: "",
  renter: {
    id: 692,
    email: "oxaha2008@rambler.ru",
    first_name: "Оксана",
    last_name: "Геращенко",
    middle_name: "Николаевна",
    full_name: "Геращенко Оксана Николаевна",
    phone: "+79104457483",
  },
  renter_id: 692,
  number: "invalid-378",
  apartment: linkApartmentMock,
  owner: {
    id: 675,
    email: "nadya.petrushina@gmail.com",
    first_name: "Надежда",
    last_name: "Королькова",
    middle_name: "Владимировна",
    full_name: "Королькова Надежда Владимировна",
    phone: "+79262221218",
  },
  sign_date: "1990-01-01",
  start_date: "1990-01-01",
  end_date: undefined,
  month_payment: "50000",
  day_of_payment: 15,
  initial_commission: "30",
  security_payment: "100",
  termination_showing_days: 14,
  termination_penalty: "90",
  roommates_ids: [],
  status: "Closed",
  inspections: [],
  notify_period_month: 1,
  closing_conditions: {},
}

export const rentalContracts = [
  {
    id: 14,
    renter: {
      id: 692,
      email: "oxaha2008@rambler.ru",
      first_name: "Оксана",
      last_name: "Геращенко",
      middle_name: "Николаевна",
      full_name: "Геращенко Оксана Николаевна",
      phone: "+79104457483",
    },
    number: "invalid-378",
    apartment_id: 378,
    apartment_address: "13-я Парковая улица, 30 к1",
    owner: {
      id: 675,
      email: "nadya.petrushina@gmail.com",
      first_name: "Надежда",
      last_name: "Королькова",
      middle_name: "Владимировна",
      full_name: "Королькова Надежда Владимировна",
      phone: "+79262221218",
    },
    sign_date: "1990-01-01",
    start_date: "1990-01-01",
    end_date: null,
    month_payment: "0.00",
    day_of_payment: 0,
    sign_commission: "0.00",
    first_payment_deposit_percent: "100.00",
    second_payment_deposit_percent: "0.00",
    third_payment_deposit_percent: "0.00",
    termination_showing_days: 14,
    termination_penalty: "0.00",
    roommates: [],
    status: "Closed",
    inspections: [],
  },
  {
    id: 94,
    renter: {
      id: 672,
      email: "indbleta@yahoo.com",
      first_name: "Indrit",
      last_name: "Bleta",
      middle_name: "Shqiptare",
      full_name: "Bleta Indrit Shqiptare",
      phone: "+79258763941",
    },
    number: "564546",
    apartment_id: 378,
    apartment_address: "13-я Парковая улица, 30 к1",
    owner: {
      id: 675,
      email: "nadya.petrushina@gmail.com",
      first_name: "Надежда",
      last_name: "Королькова",
      middle_name: "Владимировна",
      full_name: "Королькова Надежда Владимировна",
      phone: "+79262221218",
    },
    sign_date: "2021-10-19",
    start_date: "2021-10-19",
    end_date: null,
    month_payment: "45.00",
    day_of_payment: 4,
    sign_commission: "0.00",
    first_payment_deposit_percent: "100.00",
    second_payment_deposit_percent: "0.00",
    third_payment_deposit_percent: "0.00",
    termination_showing_days: 14,
    termination_penalty: "100.00",
    roommates: [],
    status: "Closed",
    inspections: [],
  },
]

const listRentalContracts = [
  {
    id: 14,
    number: "invalid-378",
    month_payment: "0.00",
    day_of_payment: 0,
    end_date: null,
    renter: {
      id: 692,
      email: "oxaha2008@rambler.ru",
      first_name: "Оксана",
      last_name: "Геращенко",
      middle_name: "Николаевна",
      full_name: "Геращенко Оксана Николаевна",
      phone: "+79104457483",
    },
    apartment: {
      id: 378,
      address: "13-я Парковая улица, 30 к1",
      rooms_number: 2,
      service_contracts: [
        {
          id: 14,
          number: "invalid-378",
          start_date: "1990-01-01",
          end_date: null,
        },
      ],
      owner: {
        id: 675,
        email: "nadya.petrushina@gmail.com",
        first_name: "Надежда",
        last_name: "Королькова",
        middle_name: "Владимировна",
        full_name: "Королькова Надежда Владимировна",
        phone: "+79262221218",
      },
    },
  },
  {
    id: 94,
    number: "564546",
    month_payment: "45.00",
    day_of_payment: 4,
    end_date: null,
    renter: {
      id: 672,
      email: "indbleta@yahoo.com",
      first_name: "Indrit",
      last_name: "Bleta",
      middle_name: "Shqiptare",
      full_name: "Bleta Indrit Shqiptare",
      phone: "+79258763941",
    },
    apartment: {
      id: 378,
      address: "13-я Парковая улица, 30 к1",
      rooms_number: 2,
      service_contracts: [
        {
          id: 14,
          number: "invalid-378",
          start_date: "1990-01-01",
          end_date: null,
        },
      ],
      owner: {
        id: 675,
        email: "nadya.petrushina@gmail.com",
        first_name: "Надежда",
        last_name: "Королькова",
        middle_name: "Владимировна",
        full_name: "Королькова Надежда Владимировна",
        phone: "+79262221218",
      },
    },
  },
]

export const fetchRentalContracts = {
  count: 2,
  next: null,
  previous: null,
  results: rentalContracts,
}

export const fetchListRentalContracts = {
  count: 2,
  next: null,
  previous: null,
  results: listRentalContracts,
}

export const rentalContractReject = {
  non_field_errors: ["Failed"],
}

import React from "react"
import { Caption, Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { Theme } from "@emotion/react"
import { DashboardCardInfoProps, LinkCardIconStylesProps } from "./types"

const DashboardCardInfo = (props: DashboardCardInfoProps) => {
  const { value, label, icon, variant, customHeader } = props
  const errorColor = variant === "error" ? "red" : "orange"
  const color = value && value > 0 ? errorColor : "green"

  return (
    <Grid container alignItems="center">
      <StyledIconWrapper cardColor={color}>{icon}</StyledIconWrapper>
      {customHeader || (
        <div>
          <StyledHeading size="h2" cardColor={color}>
            {value || 0}
          </StyledHeading>
          <Caption weight={500}>{label}</Caption>
        </div>
      )}
    </Grid>
  )
}

export default DashboardCardInfo

export const iconColors = (theme: Theme) => ({
  red: {
    color: theme.palette.red.fiftyP,
    background: theme.palette.red.five,
  },
  green: {
    color: theme.palette.green.fiftyP,
    background: theme.palette.green.five,
  },
  orange: {
    color: theme.palette.brand.main,
    background: theme.palette.brand.background,
  },
})

const StyledIconWrapper = styled.div<LinkCardIconStylesProps>`
  width: 56px;
  height: 56px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ cardColor, theme }) =>
    iconColors(theme)[cardColor].background};
  margin-right: 16px;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ cardColor, theme }) => iconColors(theme)[cardColor].color};
    }
  }
`

const StyledHeading = styled(Heading)<LinkCardIconStylesProps>`
  color: ${({ cardColor, theme }) => iconColors(theme)[cardColor].color};
`

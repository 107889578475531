import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Heading, Grid } from "kui-basic"
import { InputWithController } from "kui-complex"
import { CountryAutocomplete } from "kui-crm"
import ClientPhoneNumbersFields from "../ClientPhoneNumbersFields"
import ClientEmailsFields from "../ClientEmailsFields"
import { UserContactsProps } from "../types"
import useClientStore from "../../../store"

function ClientContactsFields({ form }: UserContactsProps) {
  const { editor } = useClientStore()
  const disabled = !editor.isEditing

  return (
    <>
      <StyledHeading size="h4">Contacts</StyledHeading>
      <StyledContainer container spacing={3}>
        <Grid item xs={6}>
          <ClientEmailsFields form={form} />
        </Grid>
        <Grid item xs={6}>
          <ClientPhoneNumbersFields form={form} />
        </Grid>
        <Grid item xs={6}>
          <CountryAutocomplete
            label="Country of Stay"
            name="countryOfStay"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithController
            label="The actual address"
            name="actualAddress"
            form={form}
            disabled={disabled}
          />
        </Grid>
      </StyledContainer>
    </>
  )
}

export default observer(ClientContactsFields)

const StyledContainer = styled(Grid)`
  padding-bottom: 24px;
`

const StyledHeading = styled(Heading)`
  font-weight: 700;
  margin-bottom: 16px;
`

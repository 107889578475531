import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import {
  Loader,
  Editor,
  getPhoneNumberFromPhoneParams,
  toNumber,
  resHandler,
} from "kui-utils"
import { ApartmentMetroParams, ApartmentModel } from "kui-crm/types"
import { EntityStoreInterface } from "../../../types/store/entity"
import HostStore from "../../../store/Root"
import ApartmentAgent from "../../../agent/Apartment"
import ApartmentOverviewStore from "./ApartmentOverviewStore"
import { PatchApartmentParams } from "../../ApartmentsPage/types/api/apartmentsAPI"
import ApartmentOwnershipStore from "./ownership/ApartmentOwnership"
import ApartmentInspectionsStore from "./inspections/ApartmentInspectionsStore"
import ApartmentDescriptionStore from "./description/ApartmentDescription"
import ApartmentDocumentsStore from "./documents/ApartmentDocuments"
import { ApartmentDescriptionFields } from "../forms/description/ApartmentDescriptionForm/types"
import { clearNotValidFields } from "../../../utils/service/mapper"
import {
  ApartmentDescriptionParams,
  ApartmentPaymentInfoParams,
} from "../../../types/store/apartments"
import {
  averageWalkMetersPerHour,
  minutesPerHour,
} from "../../../utils/content/constants"
import ApartmentMetersStore from "../../../store/shared/apartment/meters/ApartmentMetersStore"
import CheckServiceStore from "../../../store/templates/CheckServiceStore"
import { CheckServiceModel } from "../../../types/api/check_service"
import ApartmentFieldsStore from "../../../store/shared/apartment/ApartmentFieldsStore"

class ApartmentPageStore implements EntityStoreInterface {
  loader: Loader

  actionLoader: Loader

  editor: Editor

  overviewStore: ApartmentOverviewStore

  ownershipStore: ApartmentOwnershipStore

  inspectionsStore: ApartmentInspectionsStore

  descriptionStore: ApartmentDescriptionStore

  documentsStore: ApartmentDocumentsStore

  metersStore: ApartmentMetersStore

  checkServices: CheckServiceStore[]

  private hostStore: HostStore

  constructor(hostStore: HostStore) {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.hostStore = hostStore
    this.overviewStore = new ApartmentOverviewStore(this)
    this.descriptionStore = new ApartmentDescriptionStore(this)
    this.inspectionsStore = new ApartmentInspectionsStore(this)
    this.ownershipStore = new ApartmentOwnershipStore()
    this.documentsStore = new ApartmentDocumentsStore(this)
    this.metersStore = new ApartmentMetersStore(this)
    this.checkServices = []
    makeAutoObservable(this)
  }

  fetchApartmentById = async (id: number) => {
    this.loader.startLoading()

    const response = await to<ApartmentModel>(ApartmentAgent.getById(id))
    await this.fetchApartmentServices(id)

    resHandler(
      response,
      this.loader,
      (resp) => {
        this.overviewStore.updateOverviewInfo(resp)
        this.descriptionStore.updateDescriptionInfo(resp)
        this.inspectionsStore.updateInspectionInfo()
      },
      "fetch apartment"
    )
  }

  fetchApartmentServices = async (apartmentId: number) => {
    const response = await to<CheckServiceModel[]>(
      ApartmentAgent.getApartmentServices(apartmentId)
    )

    resHandler(
      response,
      this.loader,
      (resp) => {
        this.checkServices = resp.map(
          (service) =>
            new CheckServiceStore(
              service,
              `/apartments/${apartmentId}/checking`
            )
        )
      },
      "fetch apartment check services",
      { withEndLoading: false }
    )
  }

  patchApartment = async (
    id: number,
    data: Partial<ApartmentDescriptionFields>,
    withNull?: boolean
  ): Promise<[Error | null, ApartmentModel | undefined]> => {
    this.actionLoader.startLoading("apartment changes")

    const body: PatchApartmentParams = ApartmentPageStore.getPatchApartmentBody(
      data,
      this,
      withNull
    )

    const response = await to<ApartmentModel>(ApartmentAgent.patch(id, body))

    resHandler(
      response,
      this.actionLoader,
      (resp) => {
        this.overviewStore.updateOverviewInfo(resp)
        this.descriptionStore.updateDescriptionInfo(resp)
      },
      "patch apartment"
    )

    return response
  }

  archiveApartment = async (id: number) => {
    this.loader.startLoading("apartment archiving")

    const [err] = await to(ApartmentAgent.archive(id))
    if (err) {
      this.loader.setError("apartment archiving", err)
    }
    this.loader.endLoading()
  }

  updateApartmentPage = () => {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = new ApartmentOverviewStore(this)
    this.descriptionStore = new ApartmentDescriptionStore(this)
    this.inspectionsStore = new ApartmentInspectionsStore(this)
    this.ownershipStore = new ApartmentOwnershipStore()
    this.documentsStore = new ApartmentDocumentsStore(this)
  }

  get metroStore() {
    return this.hostStore.metroStore
  }

  getApartmentDescriptionInfo = (
    apartment: ApartmentModel
  ): ApartmentDescriptionParams => ({
    ...ApartmentFieldsStore.getApartmentDescriptionInfo(apartment),
    metroStations: this.getMetroStationsParams(apartment?.metro_stations),
  })

  getMetroStationsParams = (metroStations?: ApartmentMetroParams[]) =>
    metroStations?.map((metroStation) => ({
      id: metroStation.metro_station_id,
      name: metroStation.name,
      walkDistance: metroStation.distance_walk_meters,
      carDistance: Number(metroStation.distance_car_kilometers),
      carTime: metroStation.time_car_minutes,
      walkTime: Math.round(
        metroStation.distance_walk_meters! /
          (averageWalkMetersPerHour / minutesPerHour)
      ),
    })) ?? []

  static getPatchApartmentBody = (
    data: Partial<ApartmentDescriptionFields>,
    apartment?: ApartmentPageStore,
    withNull?: boolean
  ): PatchApartmentParams =>
    clearNotValidFields(
      {
        area: data.area,
        living_area: data.livingArea,
        kitchen_area: data.kitchenArea,
        rooms_number: data.roomsNumber,
        bedrooms_number: data.bedroomsNumber,
        bathrooms_number: data.bathroomsNumber,
        restrooms_number: data.restroomsNumber,
        combined_bathrooms_number: data.combinedBathroomsNumber,
        ceiling_height: data.ceilingHeight,
        number_of_windows: data.numberOfWindows,
        locker: data.locker?.id,
        folder_number: data.folderNumber,
        building_year: data.buildingYear,
        parking_type: data.parkingType,
        parking_number: data.parkingNumber,
        address: data?.apartment?.address || null,
        apartment_number: data.apartmentNumber,
        floor: data.floor,
        floor_count: data.floorCount,
        geolocation: data.geolocation?.lat
          ? {
              lat: data.geolocation.lat,
              lon: data.geolocation.lng,
            }
          : null,
        has_intercom: data.hasIntercom,
        has_gate: data.hasGate,
        has_barrier: data.hasBarrier,
        has_security: data.hasSecurity,
        has_concierge: data.hasConcierge,
        gate_code: data.gateCode,
        post_index: data.zipCode,
        num_entrance: data.entrance,
        owner_id: apartment?.overviewStore.landlord?.id,
        metro_stations: ApartmentPageStore.getApartmentMetroStationsBody(data),
        max_electricity_counters: data.maxMeters?.electricity,
        max_gas_counters: data.maxMeters?.gas,
        max_heating_counters: data.maxMeters?.heating,
        max_water_counters: data.maxMeters?.water,
        num_intercom: data.intercomCode,
        room_type: data.typeOfRoom,
        barrier_phone: data.barrierPhone
          ? getPhoneNumberFromPhoneParams(data.barrierPhone)
          : null,
        concierge_phone: data.conciergePhone
          ? getPhoneNumberFromPhoneParams(data.conciergePhone)
          : null,
        type_gas: data.gasLeads,
        hot_water:
          typeof data.withHotWater === "boolean" ? data.withHotWater : null,
        type_houses: data.typeOfHouse,
        payer_code: data.paymentInfo?.payerCode,
        financial_personal_account: data.paymentInfo?.communalServicesAccount,
        has_video_surveillance: data.hasVideoControl,
        video_surveillance_phone: data.videoControlPhone
          ? getPhoneNumberFromPhoneParams(data.videoControlPhone)
          : null,
        wifi_name: data.wifi?.name,
        wifi_password: data.wifi?.password,
        internet_provider_link: data.internetProvider?.link,
        internet_provider_login: data.internetProvider?.login,
        internet_provider_password: data.internetProvider?.password,
        tv_type: data.tvType,
        home_phone: data.homePhone
          ? getPhoneNumberFromPhoneParams(data.homePhone)
          : null,
        serial_number: data.houseNumber,
        wall_type: data.wallMaterial,
        overlap_type: data.overlapType,
        passenger_elevators_number: data.passengerElevatorsCount,
        service_elevators_number: data.serviceElevatorsCount,
        security_phone: data.securityPhone
          ? getPhoneNumberFromPhoneParams(data.securityPhone)
          : null,
        distance_to_center_kilometers: data.distanceToCenter,
        city: data.city?.id,
        region: data.region?.id,
        renovation_year: data.renovation?.year,
        renovation_type: data.renovation?.type,
        renovation_style: data.renovation?.style,
        rooms: data.rooms
          ?.filter((room) => room.area || room.type)
          .map((room, index) =>
            clearNotValidFields({
              id: room.id,
              area: room.area,
              room_type: room.type,
              order_number: index + 1,
            })
          ),
        county: data.county?.id,
        district: data.district?.id,
      },
      withNull
    )

  static getApartmentMetroStationsBody = (
    data: Partial<ApartmentDescriptionFields>
  ) =>
    data.metroStations?.map((metro) => ({
      metro_station_id: metro.id,
      distance_walk_meters: toNumber(metro.walkDistance),
      distance_car_kilometers: toNumber(metro.carDistance),
      time_car_minutes: toNumber(metro.carTime),
    }))

  static getApartmentPaymentInfo = (
    apartment: ApartmentModel
  ): ApartmentPaymentInfoParams => ({
    payerCode: apartment.payer_code,
    communalServicesAccount: apartment.financial_personal_account,
    managementCompany: apartment.administrative_company
      ? {
          ...apartment.administrative_company,
          operatingAccount: apartment.payer_code,
        }
      : null,
  })
}

export default ApartmentPageStore

import React from "react"
import { useWatch } from "react-hook-form"
import { Box, Grid, Heading } from "kui-basic"
import { observer } from "mobx-react"
import { InputByType } from "kui-crm"
import { getPartialMeterInfoFields } from "kui-crm_actions"
import _ from "lodash"
import { ApartmentMeterFieldsProps } from "./types"
import InspectionMeterPhoto from "../../InspectionMeterPhoto"
import getMeterLabel from "../../../../../../../utils/service/getMeterLabel"

const ApartmentPartialMeterFields = (props: ApartmentMeterFieldsProps) => {
  const { resource, index, form, disabled } = props
  const type = useWatch({
    control: form.control,
    name: `meters.${index}.type`,
  })
  const label = _.capitalize(getMeterLabel(resource, type))

  const meterFields = getPartialMeterInfoFields(resource, type)

  return (
    <>
      <Box pt={3} pb={2}>
        <Heading size="h4">{label}</Heading>
      </Box>
      <Grid container spacing={3}>
        {meterFields.map(({ size, name, ...field }) => (
          <Grid item key={name} xs={2}>
            <InputByType
              name={`meters.${index}.${name}`}
              form={form}
              {...field}
              disabled={disabled}
            />
          </Grid>
        ))}
        <Grid item xs={2}>
          <InspectionMeterPhoto
            disabled={disabled}
            name={`meters.${index}.images`}
            form={form}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default observer(ApartmentPartialMeterFields)

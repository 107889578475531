import React from "react"
import { useLocation } from "react-router"
import styled from "@emotion/styled"
import Page from "../../components/common/Page"
import { Routes } from "../../types/route"
import TariffsPage from "../TariffsPage"
import MetersPage from "../MetersPage"
import RegistriesPage from "../RegistriesPage"
import ListTabsPanel from "../../components/ui/ListTabsPanel"

const tabs = ["Tariff groups", "Metering devices", "Registry"]
const tabPanels = [<TariffsPage />, <MetersPage />, <RegistriesPage />]
const paths = [Routes.tariffsGroups, Routes.meteringDevices, Routes.registries]

function FunctionsPage() {
  const location = useLocation().pathname
  const activeTab = Math.max(
    paths.findIndex((name) => location.includes(name)),
    0
  )

  return (
    <Page withContainer={false}>
      <StyledTabsPanel
        activeTab={Number(activeTab)}
        tabs={tabs}
        tabPanels={tabPanels}
        paths={paths}
      />
    </Page>
  )
}

export default FunctionsPage

const StyledTabsPanel = styled(ListTabsPanel)`
  .KUI-TabsContainer {
    position: sticky;
    background: white;
    top: 64px;
    z-index: 1;
  }
  .KUI-TabsWrapper {
    z-index: 1;
  }
`

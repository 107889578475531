import React from "react"
import { useForm } from "react-hook-form"
import { Box } from "kui-basic"
import { FormWrapper, InputFileWithVisibility } from "kui-crm"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { ClosePeriodFormProps } from "./types"
import getClosePeriodFormSchema from "./schema"
import useExpensesStore from "../../../store"

const ClosePeriodForm = ({ handleSubmit }: ClosePeriodFormProps) => {
  const { periodStore } = useExpensesStore()
  const schema = getClosePeriodFormSchema(periodStore.isLastMonthOfContract)
  const form = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Confirm">
      <Box pt={2}>
        <InputFileWithVisibility
          label="Payment order"
          form={form}
          name="paymentOrder"
        />
      </Box>
    </FormWrapper>
  )
}

export default observer(ClosePeriodForm)

import React from "react"
import { PlusIcon } from "kui-icon"
import styled from "@emotion/styled"
import { Grid, Button } from "kui-basic"
import { useToggle } from "kui-utils"
import { TwoColumnsButtonProps, TwoColumnWrapperStylesProps } from "./types"
import LinkButton from "../LinkButton"
import TypographyMoney from "../TypographyMoney"

const TwoColumnsButton = (props: TwoColumnsButtonProps) => {
  const {
    label,
    link,
    startValue,
    endValue,
    isDisplayed,
    cellsWidth,
    renderModal,
  } = props
  const [open, handleOpen, handleClose] = useToggle()
  const fullCellWidth = cellsWidth?.reduce((sum, width) => sum + width, 0) || 1
  const formattedWidths =
    cellsWidth?.map((width) =>
      Math.round((Number(width) / fullCellWidth) * 100)
    ) || []

  return (
    <StyledWrapper widths={formattedWidths}>
      {isDisplayed ? (
        <>
          {link ? (
            <StyledLinkButton withoutChildrenWrapper href={link}>
              <TypographyMoney
                size="s"
                color="fiftyP"
                className="TwoColumnsButton_value"
                value={startValue}
              />
              <TypographyMoney
                size="s"
                color="fiftyP"
                className="TwoColumnsButton_value"
                value={endValue}
              />
            </StyledLinkButton>
          ) : (
            <Button
              fullWidth
              size="xs"
              onClick={handleOpen}
              endIcon={<PlusIcon />}
            >
              {label}
            </Button>
          )}
        </>
      ) : (
        <Grid container>
          <StyledTypographyValue className="TwoColumnsButton_value">
            -
          </StyledTypographyValue>
          <StyledTypographyValue className="TwoColumnsButton_value">
            -
          </StyledTypographyValue>
        </Grid>
      )}
      {renderModal && renderModal({ open, handleClose })}
    </StyledWrapper>
  )
}

export default TwoColumnsButton

const StyledWrapper = styled.div<TwoColumnWrapperStylesProps>`
  .TwoColumnsButton_value:first-of-type {
    width: ${({ widths }) => `calc(${widths[0] || 100}% + 18px)`} !important;
  }
  .TwoColumnsButton_value:last-of-type {
    width: ${({ widths }) => `calc(${widths[1] || 100}% - 18px)`} !important;
  }
`

const StyledLinkButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  min-width: unset !important;
  text-align: left;
  &,
  > div {
    width: 100%;
  }
  .TwoColumnsButton_value {
    justify-content: flex-start;
    p {
      color: ${({ theme }) => theme.palette.grey.fiftyP} !important;
    }
  }
`

const StyledTypographyValue = styled.span`
  display: inline-block;
  &:first-of-type {
    padding-left: 12px;
  }
`

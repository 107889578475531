import { UserNamesModel, LiteUserModel } from "kui-crm/types"
import { UserLiteParams } from "../../types/store/userLite"
import { SubjectRoles } from "../../types/common"
import { ClientModel } from "../../types/api/clients"
import { maroomAvatar } from "../../utils/content/constants"

class UserLiteStore {
  id: number

  avatar: string

  fullName: string

  partialName: string

  role: SubjectRoles | null

  email: string

  phoneNumber: string

  name: string

  constructor(userParams: UserLiteParams) {
    this.id = userParams.id ?? 0
    this.avatar = userParams.avatar ?? ""
    this.fullName = userParams.fullName ?? ""
    this.role = userParams.role ?? null
    this.email = userParams.email ?? ""
    this.phoneNumber = userParams.phoneNumber ?? ""
    this.partialName = userParams.partialName ?? ""
    this.name = userParams.name ?? ""
  }

  static initFromLiteUserModel = (
    user?: LiteUserModel,
    role?: SubjectRoles | null
  ) =>
    new UserLiteStore({
      id: user?.id,
      fullName: user?.full_name,
      email: user?.email,
      phoneNumber: user?.phone,
      partialName: `${user?.last_name} ${user?.first_name}`,
      name: user?.full_name,
      role,
    })

  static initFromUserModel = (user: ClientModel) =>
    new UserLiteStore({
      id: user.id,
      fullName: user.full_name,
      email: user.email,
      phoneNumber: user.phone,
      partialName: `${user.last_name} ${user.first_name}`,
      role: user.role === "landlord_tenant" ? "landlord" : user.role,
    })

  static initFromUserNamesModel = (
    user?: UserNamesModel,
    role?: SubjectRoles
  ) =>
    new UserLiteStore({
      id: user?.id,
      fullName: user?.full_name,
      partialName: `${user?.first_name} ${user?.last_name}`,
      role,
    })

  static initForMaroom = () =>
    new UserLiteStore({
      fullName: "Maroom",
      partialName: "Maroom",
      role: "maroom",
      avatar: maroomAvatar,
    })
}

export default UserLiteStore

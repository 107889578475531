import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import InspectionStore from "./InspectionStore"
import DashboardAgent from "../../../../agent/Dashboard"
import DashboardPageStore from "../DashboardPageStore"
import { InspectionDashboardModal } from "../../types/api/inspections"

class InspectionsStore {
  value: number | null

  inspections: InspectionStore[]

  paginator: Paginator

  loader: Loader

  archiveInspections: InspectionStore[]

  archivePaginator: Paginator

  archiveLoader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.inspections = []
    this.archiveInspections = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.archivePaginator = new Paginator(5)
    this.archiveLoader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchInspections = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getImpairmentsInspections(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.archiveInspections = this.paginator.getPageResponse(
          res,
          this.archiveInspections,
          this.getInspectionParams
        )
      })
    })
  }

  fetchArchive = async () => {
    this.archiveLoader.startLoading()

    const response = await to(
      DashboardAgent.getImpairmentsInspections(
        this.archivePaginator.limit,
        this.archivePaginator.offset,
        this.dashboardStore.periodFilter,
        true
      )
    )

    runInAction(() => {
      resHandler(response, this.archiveLoader, (res) => {
        this.value = res.count
        this.archiveInspections = this.archivePaginator.getPageResponse(
          res,
          this.archiveInspections,
          this.getInspectionParams
        )
      })
    })
  }

  removeFromInspectionsList = (id: number) => {
    this.inspections = this.inspections.filter(
      (inspection) => inspection.id !== id
    )
  }

  removeFromArchiveList = (id: number) => {
    this.archiveInspections = this.archiveInspections.filter(
      (inspection) => inspection.id !== id
    )
  }

  getInspectionParams = (inspection: InspectionDashboardModal) =>
    new InspectionStore(inspection, this)
}

export default InspectionsStore

import { ApartmentPaymentSummaryModel } from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"

export const summaryDebtMock = {
  accrued: 75000,
  paid: 1000,
}

export const summaryOverpayMock = {
  accrued: 75000,
  paid: 80000,
}

export const summaryPaidMock = {
  accrued: 75000,
  paid: 75000,
}

export const paymentSummaryDebtMock: ApartmentPaymentSummaryModel = {
  renter: summaryDebtMock,
  owner: summaryDebtMock,
  period_id: 1,
}

export const paymentSummaryOverpayMock: ApartmentPaymentSummaryModel = {
  renter: summaryOverpayMock,
  owner: summaryOverpayMock,
  period_id: 1,
}

export const paymentSummaryPaidMock: ApartmentPaymentSummaryModel = {
  renter: summaryPaidMock,
  owner: summaryPaidMock,
  period_id: 1,
}

export const paymentSummaryIds = {
  paid: 1,
  debt: 2,
  overpay: 3,
}

import React, { useState } from "react"
import { useLocation } from "react-router"
import { observer } from "mobx-react"
import { Routes } from "../../types/route"
import Page from "../../components/common/Page"
import { ContractVariants } from "../../types/store/contract"
import ContractsSearchPanel from "./ContractsSearchPanel"
import ServiceContractsPage from "../ServiceContractsPage"
import RentalContractsPage from "../RentalContractsPage"
import ListTabsPanel from "../../components/ui/ListTabsPanel"

const tabs = ["Service contracts", "Rental contracts"]
const tabPanels = [<ServiceContractsPage />, <RentalContractsPage />]
const paths = [Routes.service_contracts, Routes.rental_contracts]

function ContractsPage() {
  const location = useLocation().pathname
  const activeTab = Math.max(
    paths.findIndex((name) => name === location),
    0
  )
  const [currentContract, setCurrentContract] = useState<ContractVariants>(
    activeTab === 0 ? "service" : "rental"
  )

  const handleContractChange = (e: any, index: number) => {
    if (index === 0) setCurrentContract("service")
    else setCurrentContract("rental")
  }

  return (
    <Page withContainer={false}>
      <ListTabsPanel
        activeTab={Number(activeTab)}
        tabs={tabs}
        tabPanels={tabPanels}
        paths={paths}
        onChange={handleContractChange}
        endComponent={
          <ContractsSearchPanel currentContract={currentContract} />
        }
      />
    </Page>
  )
}

export default observer(ContractsPage)

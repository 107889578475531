import { makeAutoObservable } from "mobx"
import { getPhoneParamsFromString, Loader, resHandler } from "kui-utils"
import { DateTime } from "luxon"
import to from "await-to-js"
import { HousingInspectorDashboardSettings } from "../../types/store/housingInspectorDashboard"
import {
  ApartmentInspectionParams,
  ApartmentsInspectionsParams,
} from "../../types/cards/inspections"
import DashboardPageStore from "../DashboardPageStore"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import DashboardAgent from "../../../../agent/Dashboard"
import {
  UpcomingInspectionModel,
  UpcomingInspectionResponse,
} from "../../types/api/inspections"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class HousingInspectorDashboardStore {
  apartmentsInspections: ApartmentsInspectionsParams | null

  settings: HousingInspectorDashboardSettings | null

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.apartmentsInspections = null
    this.settings = null
    this.loader = new Loader(true)
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  initHousingInspectorDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.loader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      if (
        this.settings.apartmentsInspectionsList ||
        this.settings.apartmentsInspectionsMap
      ) {
        await this.fetchApartmentsInspections()
      }
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: HousingInspectorDashboardSettings) => {
    this.loader.startLoading()

    const body =
      HousingInspectorDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      apartmentsInspectionsList: !!visible_blocks.upcoming_inspections,
      apartmentsInspectionsMap: !!visible_blocks.upcoming_inspections_map,
    }
  }

  fetchApartmentsInspections = async () => {
    const response = await to(
      DashboardAgent.getUpcomingInspections(
        this.dashboardStore.periodFilter.slice(1)
      )
    )

    resHandler(response, this.loader, this.updateApartmentsInspections)
  }

  updateApartmentsInspections = (res: UpcomingInspectionResponse) => {
    this.apartmentsInspections = {
      value: res.count,
      done: res.completed_count,
      inspections: res.results.map((inspection) =>
        HousingInspectorDashboardStore.getApartmentParams(inspection)
      ),
    }
  }

  resetCards = () => {
    this.apartmentsInspections = null
  }

  static getDashboardSettingsBody = (
    settings: HousingInspectorDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      upcoming_inspections: settings.apartmentsInspectionsList,
      upcoming_inspections_map: settings.apartmentsInspectionsMap,
    },
  })

  static getApartmentParams = ({
    apartment,
    action_date,
  }: UpcomingInspectionModel): ApartmentInspectionParams => ({
    id: apartment.id,
    apartment: {
      ...ApartmentsStore.getApartmentLinkParams(apartment),
      roomsCount: null,
    },
    date: action_date ? DateTime.fromISO(action_date) : null,
    tenant: {
      id: apartment.renter!.id,
      phoneNumber: getPhoneParamsFromString(apartment.renter!.phone),
      fullName: apartment.renter!.full_name,
    },
    landlord: null,
    location: {
      lat: apartment.geolocation.lat,
      lng: apartment.geolocation.lon,
    },
    metroStations: apartment.metro_stations.map((station) => ({
      color: `#${station.line.hex_color}`,
      name: station.name,
    })),
  })
}

export default HousingInspectorDashboardStore

import React from "react"
import { observer } from "mobx-react"
import { useToggle, addToArrayByCondition } from "kui-utils"
import EditServiceModal from "../../../../../../../../components/modals/ServiceEditingModal"
import { ServiceMenuCellProps } from "./types"
import MenuButton from "../../../../../../../../components/common/MenuButton"
import useExpensesStore from "../../../../store"
import PaymentOrderModal from "../PaymentOrderModal"
import AbortServiceModal from "../AbortServiceModal"

function ServiceMenuCell({ service }: ServiceMenuCellProps) {
  const {
    date,
    overviewStore,
    periodStore,
    servicesStore,
    apartmentStore,
    lastOpenPeriod,
  } = useExpensesStore()
  const [isEditing, startEditing, endEditing] = useToggle()
  const [isOrderOpen, handleOrderOpen, handleOrderClose] = useToggle()
  const [isAbortModalOpen, handleAbortModalOpen, handleAbortModalClose] =
    useToggle()
  const { isClosed, canBeEdited } = periodStore
  const { id, address, payerCode } = overviewStore
  const apartmentNumber =
    apartmentStore.descriptionStore.fields?.apartmentNumber
  const isActive =
    date && Number(service.chargeFrom?.diff(date, "months").months) <= 0

  const apartmentFields = id
    ? { id, address, payerCode, apartmentNumber }
    : null

  const duplicate = async () => {
    if (overviewStore.id) {
      await servicesStore.duplicateService(overviewStore.id, service.id)
    }
  }

  const activeMenuOptions = [
    ...addToArrayByCondition(!service.paymentOrder, {
      label: "Add payment order",
      handleClick: handleOrderOpen,
    }),
    {
      label: isClosed ? "Copy to current period" : "Duplicate",
      handleClick: duplicate,
    },
    { label: "Edit", handleClick: startEditing },
    ...addToArrayByCondition(canBeEdited, {
      label: "Delete",
      handleClick: service.deleteService,
    }),
  ]

  const periodMenuOptions = [
    { label: "Abort", handleClick: handleAbortModalOpen },
  ]

  const options =
    service.status === "pending" &&
    service.durationType === "period" &&
    date &&
    isActive
      ? periodMenuOptions
      : activeMenuOptions

  if (
    service.status === "pending" &&
    service.durationType === "open-ended" &&
    isActive
  )
    return null

  return (
    <>
      <MenuButton options={options} />
      <EditServiceModal
        open={isEditing}
        handleClose={endEditing}
        service={service}
        apartment={apartmentFields}
        isPeriodClosed={!canBeEdited}
        lastOpenPeriod={lastOpenPeriod}
      />
      <AbortServiceModal
        service={service}
        open={isAbortModalOpen}
        handleClose={handleAbortModalClose}
      />
      <PaymentOrderModal
        service={service}
        open={isOrderOpen}
        handleClose={handleOrderClose}
      />
    </>
  )
}

export default observer(ServiceMenuCell)

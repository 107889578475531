import React from "react"
import styled from "@emotion/styled"
import { Box, Caption } from "kui-basic"
import { observer } from "mobx-react"
import _ from "lodash"
import { ModalProps } from "kui-crm"
import { ApartmentInspectionsRowProps, InspectionStylesProps } from "./types"
import TextOverflow from "../../../../../../components/common/TextOverflow"
import GridTableRow from "../../../../../../components/common/GridTableRow"
import inspectionsTableHeadCells from "../ApartmentInspectionsTable/headCells"
import LinkButton from "../../../../../../components/common/LinkButton"
import { DynamicRoutes } from "../../../../../../types/route"
import TwoColumnsButton from "../../../../../../components/common/TwoColumnsButton"
import InspectionCreationModal from "../InspectionCreationModal"
import useApartmentStore from "../../../../store"

function ApartmentInspectionRow(props: ApartmentInspectionsRowProps) {
  const { inspection, index, ...other } = props
  const { overviewStore } = useApartmentStore()
  const cellsWidth = inspectionsTableHeadCells.map((cell) => cell.width || "")
  const isRowDark = Number(index || 0) % 2 !== 0
  const withImpairment =
    inspection.type === "transfer" ||
    inspection.type === "regular" ||
    !!inspection.impairmentsInspectionId
  const withAppraisal =
    (inspection.type !== "appraisal" && inspection.type !== "impairments") ||
    !!inspection.appraisalInspectionId
  const inspectionLink = DynamicRoutes.inspection(
    overviewStore.id!,
    inspection.id
  )
  const impairmentsLink =
    !!inspection.impairmentsInspectionId &&
    DynamicRoutes.inspection(
      overviewStore.id!,
      inspection.impairmentsInspectionId
    )
  const appraisalLink =
    !!inspection.appraisalInspectionId &&
    DynamicRoutes.inspection(
      overviewStore.id!,
      inspection.appraisalInspectionId
    )

  return (
    <>
      <StyledTableRow isDark={isRowDark} {...other} cellsWidth={cellsWidth}>
        <Box pl={1}>
          <Caption weight={500} size="s" color="fiftyP">
            {inspection.id}
          </Caption>
        </Box>
        <Caption>{inspection.date?.toFormat("dd.MM.yyyy") || "-"}</Caption>
        <Caption>{_.capitalize(inspection.type)}</Caption>
        <TextOverflow multiline={2} strategy="fixed">
          {inspection.comment}
        </TextOverflow>
        <StyledLinkButton href={inspectionLink} labelColor="fiftyP">
          {inspection.name}
        </StyledLinkButton>
        <TwoColumnsButton
          data-size={2}
          isDisplayed={withImpairment}
          label="Impairments"
          link={impairmentsLink}
          startValue={inspection.damages}
          endValue={inspection.wear}
          renderModal={(modalProps: ModalProps) => (
            <InspectionCreationModal
              variant="impairments"
              basedOn={inspection.id}
              {...modalProps}
            />
          )}
        />
        {inspection.appraisalDate?.toFormat("dd.MM.yyyy") || "-"}
        <TwoColumnsButton
          data-size={2}
          isDisplayed={withAppraisal}
          label="Appraisal"
          link={appraisalLink}
          startValue={inspection.minPrice}
          endValue={inspection.maxPrice}
          renderModal={(modalProps: ModalProps) => (
            <InspectionCreationModal
              variant="appraisal"
              basedOn={inspection.id}
              {...modalProps}
            />
          )}
        />
      </StyledTableRow>
    </>
  )
}

export default observer(ApartmentInspectionRow)

const StyledTableRow = styled(GridTableRow)<InspectionStylesProps>`
  padding-top: 8px;
  padding-bottom: 8px;
  background: ${({ isDark, theme }) =>
    isDark
      ? theme.palette.background.light2
      : theme.palette.grey.zero} !important;
  .GridCell:not(:last-of-type) {
    padding-right: 40px !important;
  }
`

const StyledLinkButton = styled(LinkButton)`
  min-width: unset !important;
  width: 100%;
`

import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import ApartmentStep from "./ApartmentStep"
import InspectionCommonStep from "../../../../../components/forms/apartment/InspectionCreationForm/InspectionCommonStep"
import generateInspectionSteps from "../../../../../components/forms/apartment/InspectionCreationForm/generateInspectionSteps"
import { DynamicRoutes } from "../../../../../types/route"
import { InspectionCreationFormProps } from "./types"

const InspectionCreationForm = (props: InspectionCreationFormProps) => {
  const { handleClose } = props
  const { inspectionsStore, overviewStore } = useApartmentLiteStore()
  const navigate = useNavigate()
  const { creationForm } = inspectionsStore

  const handleCreate = (inspectionId?: number | null) => {
    if (inspectionId) {
      const inspectionLink = DynamicRoutes.inspection(
        overviewStore.id!,
        inspectionId
      )
      navigate(inspectionLink)
    }
    creationForm.resetForm()
    handleClose()
  }

  if (creationForm.step === 1) {
    return <ApartmentStep />
  }

  if (creationForm.step === 2) {
    return <InspectionCommonStep onlyInspections />
  }

  return generateInspectionSteps(handleCreate, 2)[
    (creationForm.fields?.type ||
      "initial") as keyof typeof generateInspectionSteps
  ]
}

export default observer(InspectionCreationForm)

import React from "react"
import InspectionImagesStep from "../../../InspectionImagesStep"
import { ImpairmentImagesStepProps } from "./types"

const ImpairmentImagesStep = (props: ImpairmentImagesStepProps) => {
  const { index, addStep, handleSubmit } = props

  return (
    <InspectionImagesStep
      withComment={false}
      description="Add photos"
      label="Comment about impairment"
      entityName={`impairments.${index}`}
      name="images"
      handleSubmit={handleSubmit}
      addEntityLabel="Add impairment"
      handleAddEntity={addStep}
    />
  )
}

export default ImpairmentImagesStep

import React from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { FormBottom, InputFileWithVisibility } from "kui-crm"
import { yupResolver } from "@hookform/resolvers/yup"
import { RCCheckOutDateFormFields, RCCheckOutDateFormProps } from "./types"
import useRCStore from "../../store"
import InputWithDatePicker from "../../../../components/ui/InputWithDatepicker"
import RCCheckOutDateFormSchema from "./schema"

const RCCheckOutDateForm = ({ handleSubmit }: RCCheckOutDateFormProps) => {
  const { contractInfoStore } = useRCStore()
  const form = useForm<RCCheckOutDateFormFields>({
    defaultValues: {
      dateOfLeaving: contractInfoStore.dateOfLeaving,
    },
    resolver: yupResolver(RCCheckOutDateFormSchema),
  })

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box pr={5} pl={5} mb={3}>
        <Box mb={2}>
          <InputWithDatePicker
            label="Date"
            name="dateOfLeaving"
            description="Select the expected check-out date"
            form={form}
          />
        </Box>
        <InputFileWithVisibility
          label="Document"
          name="reasonOfLiving"
          form={form}
        />
      </Box>

      <FormBottom label="Save" />
    </form>
  )
}

export default observer(RCCheckOutDateForm)

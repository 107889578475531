import { makeAutoObservable } from "mobx"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import ApartmentDocumentsRegistryStore from "./variants/ApartmentDocumentsRegistryStore"
import ServicesRegistryStore from "./variants/ServicesRegistryStore"
import InspectionsRegistryStore from "./variants/InspectionsRegistryStore"
import MetersReadingRegistryStore from "./variants/MetersReadingRegistryStore"

class RegistriesPageStore {
  registriesStores: any[]

  activeRegistryStore: MultipleEntitiesStoreInterface | null

  constructor() {
    this.registriesStores = [
      new ServicesRegistryStore(),
      new InspectionsRegistryStore(),
      new ApartmentDocumentsRegistryStore("insurances"),
      new ApartmentDocumentsRegistryStore("proxies"),
      new MetersReadingRegistryStore(),
    ]
    this.activeRegistryStore = null
    makeAutoObservable(this)
  }

  setActiveRegistry = (index: number) => {
    this.activeRegistryStore = this.registriesStores[index]
  }

  clear = () => {
    this.registriesStores = [
      new ServicesRegistryStore(),
      new InspectionsRegistryStore(),
      new ApartmentDocumentsRegistryStore("insurances"),
      new ApartmentDocumentsRegistryStore("proxies"),
      new MetersReadingRegistryStore(),
    ]
    this.activeRegistryStore = null
  }
}

export default RegistriesPageStore

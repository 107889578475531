import { RubleHistoryIcon } from "kui-icon"
import React from "react"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { ApartmentsServicesCardProps } from "./types"
import ApartmentServicesRow from "./ApartmentServicesRow"
import CompaniesRequisitesArchive from "./ApartmentsServicesArchive"

const ApartmentsServicesCard = (props: ApartmentsServicesCardProps) => {
  const { apartmentsStore } = props
  const { value, apartments, loader, paginator, fetchApartments } =
    apartmentsStore

  return (
    <DashboardListCard
      value={value}
      label="Objects without services"
      icon={<RubleHistoryIcon />}
      renderArchive={(isOpen) => (
        <CompaniesRequisitesArchive
          isOpen={isOpen}
          apartmentsStore={apartmentsStore}
        />
      )}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchApartments}
    >
      {apartments.map((apartment) => (
        <ApartmentServicesRow key={apartment.id} apartment={apartment} />
      ))}
    </DashboardListCard>
  )
}

export default observer(ApartmentsServicesCard)

export const APIPayerValues = [
  { value: "owner", label: "Landlord" },
  { value: "renter", label: "Tenant" },
  { value: "maroom", label: "Maroom" },
]

export const PartialPayerValues = [
  { value: "owner", label: "Landlord" },
  { value: "renter", label: "Tenant" },
  { value: "maroom", label: "Maroom" },
  { value: "partial", label: "Partial" },
]

export const PayerValues = [
  { value: "landlord", label: "Landlord" },
  { value: "tenant", label: "Tenant" },
  { value: "maroom", label: "Maroom" },
]

export const TaxTypeValues = [
  { value: "resident", label: "Individual Resident" },
  { value: "nonresident", label: "Individual Non-resident" },
  { value: "self-employed", label: "Self-employed" },
  { value: "individual-entrepreneur", label: "Individual entrepreneur" },
  { value: "company", label: "Company" },
]

export const RentalTypesValues = [
  { value: "long", label: "Long" },
  { value: "medium", label: "Medium" },
  { value: "short", label: "Short" },
]

export const PeriodValues = [
  { value: "openEnded", label: "Open-Ended" },
  { value: "fixed", label: "Fixed" },
]

export const CompanyTypesValues = [
  { value: "administrative", label: "Administrative provider" },
  { value: "service", label: "Service provider" },
  { value: "resource", label: "Resource provider" },
]

export const GenderValues = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
]

export const OwnershipTransferValues = [
  { value: "donation", label: "Donation" },
  { value: "sale", label: "Sale" },
  { value: "heritage", label: "Heritage" },
  { value: "judgment", label: "Judgment" },
  { value: "equity_agreement", label: "Equity agreement" },
]

export const BooleanValues = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
]

export const OwnershipMembersValues = [
  { value: "owner", label: "Owner" },
  { value: "proxy", label: "Proxy" },
]

export const ResourceValues = [
  { value: "electricity", label: "Electricity" },
  { value: "water", label: "Water" },
  { value: "gas", label: "Gas" },
  { value: "heating", label: "Heating" },
]

export const ElectricityValues = [
  { value: "T1", label: "One-part tariff" },
  { value: "T2", label: "Two-part tariff" },
  { value: "T3", label: "Three-part tariff" },
]

export const WaterValues = [
  { value: "cold", label: "Cold" },
  { value: "hot", label: "Hot" },
]

export const OwnershipShareTypesValues = [
  { value: "full", label: "Owner" },
  { value: "percent", label: "Percentage" },
  { value: "proportion", label: "Partial" },
  { value: "non_dedicated_share", label: "Shared" },
  { value: "dedicated_share", label: "Dedicated" },
]

export const RoomTypesValues = [
  { value: "residential", label: "Residential" },
  { value: "non-residential", label: "Nonresidential" },
  { value: "commercial", label: "Commercial" },
  { value: "garage", label: "Garage" },
  { value: "car_space", label: "Parking" },
]

export const GasTypesValues = [
  { value: "stove", label: "Stove" },
  { value: "stove_and_heater", label: "Stove + boiler" },
  { value: "not_have", label: "No" },
]

export const HouseTypesValues = [
  { value: "ST", label: "Stalinka" },
  { value: "MB", label: "Monolith-brick" },
  { value: "M", label: "Monolith" },
  { value: "BR", label: "Brick" },
  { value: "P", label: "Panel" },
  { value: "W", label: "Wooden" },
  { value: "BL", label: "Blocky" },
  { value: "WP", label: "Wooden floors" },
  { value: "apart_hotel", label: "Apart hotel" },
  { value: "apartment", label: "Apartment" },
  { value: "townhouse", label: "Townhouse" },
  { value: "private_residence ", label: "Private residence" },
]

export const getNumbersValues = (count: number) =>
  new Array(count).fill(0).map((value, key) => ({
    value: `${key}`,
    label: key,
  }))

export const SlabsTypesValues = [
  { value: "wood", label: "Wood" },
  { value: "stone", label: "Stone" },
  { value: "brick", label: "Brick" },
  { value: "reinforced_concrete", label: "Reinforced concrete" },
  { value: "metal", label: "Metal" },
  { value: "combined", label: "Combined" },
]

export const WallMaterialsValues = [
  { value: "brick", label: "Brick" },
  { value: "block", label: "Block" },
  { value: "panel", label: "Panel" },
  { value: "wood", label: "Wood" },
  { value: "stone", label: "Stone" },
]

export const ParkingTypesValues = [
  { value: "public", label: "Public" },
  { value: "house", label: "House" },
  { value: "underground", label: "Underground" },
  { value: "multi_level", label: "Multi-level" },
]

export const TVTypesValues = [
  { value: "cable", label: "Cable" },
  { value: "satellite", label: "Satellite" },
  { value: "smart_tv", label: "Smart TV" },
  { value: "no", label: "No" },
]

export const PaymentTypeValues = [
  { value: "bank", label: "Bank" },
  { value: "card", label: "Card" },
  { value: "cash", label: "Cash" },
]

export const RentPeriodTypeValues = [
  { value: "short", label: "Short" },
  { value: "medium", label: "Medium" },
  { value: "long", label: "Long" },
  { value: "any", label: "All" },
] as const

export const TaxResidenceValues = [
  { value: "resident", label: "Resident" },
  { value: "not_resident", label: "Non-resident" },
]

export const RenovationTypeValues = [
  { value: "capital", label: "Capital" },
  { value: "euro", label: "Euro" },
  { value: "designer", label: "Designer" },
  { value: "cosmetic", label: "Cosmetic" },
  { value: "required", label: "Required" },
] as const

export const RenovationStyleValues = [
  { value: "classic", label: "Classic" },
  { value: "scandinavian", label: "Scandinavian" },
  { value: "loft", label: "Loft" },
  { value: "contemporary", label: "Contemporary" },
  { value: "minimalism", label: "Minimalism" },
  { value: "neoclassic", label: "Neoclassic" },
  { value: "eco", label: "Eco" },
  { value: "provence", label: "Provence" },
  { value: "hi_tech", label: "Hi-tech" },
  { value: "mediterranean", label: "Mediterranean" },
  { value: "eclectic", label: "Eclectic" },
  { value: "ethno", label: "Ethno" },
  { value: "gothic", label: "Gothic" },
]

export const RoomTypeValues = [
  { value: "connected", label: "Connected" },
  { value: "separate", label: "Separate" },
]

export const UnitOfPaymentValues = [
  { value: "percent", label: "%" },
  { value: "value", label: "₽" },
]

export const InspectionTypeValues = [
  { value: "regular", label: "Regular" },
  { value: "transfer", label: "Transfer" },
  { value: "inventory", label: "Inventory" },
  { value: "initial", label: "Initial" },
]

export const InspectionAndValuationTypeValues = [
  ...InspectionTypeValues,
  { value: "appraisal", label: "Appraisal" },
  { value: "impairments", label: "Impairments" },
]

export const TransferInspectionTypeValues = [
  { value: "maroom_tenant", label: "Maroom to Tenant" },
  { value: "tenant_maroom", label: "Tenant to Maroom" },
  { value: "maroom_landlord", label: "Maroom to Landlord" },
]

export const ImpairmentsTypeValues = [
  { value: "damages", label: "Damages" },
  { value: "wear_tear", label: "Wear&tear" },
]

export const FillingTypeValues = [
  { value: "finish", label: "Finish" },
  { value: "furniture", label: "Furniture" },
  { value: "electronics", label: "Electronics" },
  { value: "accessories", label: "Accessories" },
]

export const ClientTypeValues = [
  { value: "personal", label: "Personal" },
  { value: "company", label: "Legal entity" },
]

export const TransactionTypeValues = [
  { value: "pay-in", label: "Pay-in" },
  { value: "pay-out", label: "Pay-out" },
]

import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { ApartmentDocumentsBlockProps } from "./types"
import useApartmentStore from "../../../../store"
import usePaginationPage from "../../../../../../hooks/usePaginationPage"
import FileWithSettingsRow from "../../../../../../components/common/FileWithSettingsRow"
import Loading from "../../../../../../components/common/Loading"
import DocumentsWithPaginatorStore from "../../../../../../store/templates/DocumentsWithPaginatorStore"
import Container from "../../../../../../components/ui/Container"
import TableBottomLoading from "../../../../../../components/common/TableBottomLoading"

function ApartmentDocumentsBlock({ tab }: ApartmentDocumentsBlockProps) {
  const { documentsStore, overviewStore } = useApartmentStore()
  const tabStore = documentsStore[
    `${tab}Documents` as keyof typeof documentsStore
  ] as DocumentsWithPaginatorStore
  const requestParams = overviewStore.id ? [overviewStore.id] : []

  const initDocumentsBlock = async () => {
    if (overviewStore.id) {
      const withDate = tab !== "overview" && tab !== "ownership"
      tabStore?.fetchDocuments(
        overviewStore.id,
        withDate ? documentsStore.date : null
      )
    } else {
      tabStore?.cleanDocuments()
    }
  }

  usePaginationPage(tabStore?.paginator, initDocumentsBlock, null, [
    documentsStore.date,
  ])

  if (tabStore?.loader.isLoading && tabStore?.paginator.offset === 0) {
    return <Loading height="50vh" />
  }

  return (
    <Container>
      <div>
        {tabStore?.documents?.map((file) => (
          <FileWithSettingsRow
            key={file.id}
            file={file}
            requestParams={requestParams}
          />
        ))}
      </div>
      <TableBottomLoading isLoading={tabStore?.loader.isLoading} />

      <LoaderState loader={tabStore.creationLoader} />
      <LoaderState loader={tabStore.loader} onlyError />
    </Container>
  )
}

export default observer(ApartmentDocumentsBlock)

import React from "react"
import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { ComparableObjectFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/types"
import CopyButton from "../../../../../../../components/common/CopyButton"

const getStatisticalValuationFields = (
  index: number,
  link?: string
): InputParams<ComparableObjectFormFields>[] => [
  {
    label: "Name of data source",
    name: `statisticalValuations.${index}.dataSourceName`,
  },
  {
    label: "Link of statistical valuation",
    name: `statisticalValuations.${index}.link`,
    endIcon: <CopyButton text={link || ""} />,
  },
  {
    label: "Screenshot statistical valuation",
    name: `statisticalValuations.${index}.screenshot`,
    variant: "file",
  },
  {
    label: "Min price",
    name: `statisticalValuations.${index}.minPrice`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
    cornerLabel: "₽",
  },
  {
    label: "Max price",
    name: `statisticalValuations.${index}.maxPrice`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
    cornerLabel: "₽",
  },
]

export default getStatisticalValuationFields

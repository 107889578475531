import React, { useState } from "react"
import { observer } from "mobx-react"
import { Caption } from "kui-basic"
import useApartmentStore from "../../../../store"
import AccordionRow from "../../../../../../components/common/AccordionRow"
import ApartmentInspectionRow from "../ApartmentInspectionRow"

function ApartmentEvaluationInspections() {
  const { inspectionsStore } = useApartmentStore()
  const { evaluationInspections, initialInspection, lastEvaluationInspection } =
    inspectionsStore
  const [expanded, setExpanded] = useState(false)
  const isEvenLength = evaluationInspections.length % 2 === 0
  const slicedLength =
    evaluationInspections.length === 2 && initialInspection ? 2 : 1

  if (!lastEvaluationInspection) {
    return null
  }

  return (
    <>
      <AccordionRow
        title="Overvalues"
        expandedLabel={
          <Caption color="fiftyP">
            show other {evaluationInspections.length - slicedLength}
          </Caption>
        }
        onChange={setExpanded}
        disabled={evaluationInspections.length < 3}
      >
        {evaluationInspections.slice(slicedLength)?.map((inspection, index) => (
          <ApartmentInspectionRow
            key={inspection.id}
            inspection={inspection}
            index={index}
          />
        ))}
      </AccordionRow>
      {slicedLength === 2 && initialInspection && (
        <ApartmentInspectionRow index={0} inspection={initialInspection} />
      )}
      <ApartmentInspectionRow
        index={isEvenLength && expanded ? 1 : 0}
        inspection={lastEvaluationInspection}
      />
    </>
  )
}

export default observer(ApartmentEvaluationInspections)

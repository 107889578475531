import * as React from "react"
import { useRef } from "react"
import { observer } from "mobx-react"
import { ModalProps } from "kui-crm"
import InputWithAutocomplete from "../../ui/InputWithAutocomplete"
import ApartmentAutocompleteOption from "./ApartmentAutocompleteOption"
import ApartmentCreationModal from "../../modals/ApartmentCreationModal"
import { ApartmentAutocompleteProps } from "./types"
import { ApartmentLiteParams } from "../../../types/store/apartments"
import ApartmentsStore from "../../../store/lites/ApartmentsStore"
import useAutocomplete from "../../../hooks/useAutocomplete"
import { NewApartmentFormParams } from "../../forms/apartment/ApartmentCreationForm/types"

function ApartmentAutocomplete<TFormValues extends object>(
  props: ApartmentAutocompleteProps<TFormValues>
) {
  const { form, name, label, defaultFilter, onSelect, ...otherProps } = props
  const apartmentStoreRef = useRef(new ApartmentsStore())
  const { apartments, fetchAll, paginator, filter, loader, createApartment } =
    apartmentStoreRef.current
  const { isLoading } = loader

  const { onSearchItem } = useAutocomplete(
    filter,
    paginator,
    fetchAll,
    defaultFilter
  )

  const onSelectItem = (item: ApartmentLiteParams | null) => {
    form.setValue(`${name}.id`, item?.id)
    form.setValue(`${name}.address`, item?.address, { shouldValidate: true })
    if (onSelect) onSelect(item)
  }

  const onCreate = async (data: NewApartmentFormParams) => {
    const apartment = await createApartment(data)
    if (apartment) onSelectItem(apartment)

    return apartment?.id
  }

  return (
    <InputWithAutocomplete
      renderOption={(option: ApartmentLiteParams) => (
        <ApartmentAutocompleteOption option={option} />
      )}
      isLoading={isLoading}
      options={apartments}
      label={label}
      form={form}
      name={`${name}.address`}
      hideName={`${name}.id`}
      onSelectItem={onSelectItem}
      onSearchItem={onSearchItem}
      handleScrollEnd={paginator.startFetching}
      creatingLabel="Add new object"
      renderCreatingModal={(modalProps: ModalProps) => (
        <ApartmentCreationModal
          withRoute={false}
          handleSubmit={onCreate}
          {...modalProps}
        />
      )}
      {...otherProps}
    />
  )
}

ApartmentAutocomplete.defaultProps = {
  label: "Apartment",
  name: "apartment",
}

export default observer(ApartmentAutocomplete)

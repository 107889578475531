import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import ApartmentInspectionsHeader from "../ApartmentInspectionsHeader"
import ApartmentInspectionsTable from "../ApartmentInspectionsTable"
import usePaginationPage from "../../../../../../hooks/usePaginationPage"
import Loading from "../../../../../../components/common/Loading"
import useApartmentStore from "../../../../store"

function ApartmentInspectionsInfo() {
  const { inspectionsStore, overviewStore } = useApartmentStore()

  const initInspections = async () => {
    if (overviewStore.id) {
      inspectionsStore.fetchEvaluationInspections(overviewStore.id)
    }
  }

  usePaginationPage(inspectionsStore.paginator, initInspections)

  if (inspectionsStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <ApartmentInspectionsHeader />
      <ApartmentInspectionsTable />

      <LoaderState loader={inspectionsStore.loader} onlyError />
      <LoaderState loader={inspectionsStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentInspectionsInfo)

import React from "react"
import { Button, Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { PlusIcon } from "kui-icon"
import PhotoCards from "../PhotoCards"
import useModalProps from "../../../hooks/useModalProps"
import { ImagesGroupWithAddButtonProps } from "./types"
import { PhotoCardParams } from "../PhotoCard/types"
import { UploadFileFields } from "../../../types/common"

const ImagesGroupWithAddButton = (props: ImagesGroupWithAddButtonProps) => {
  const { images, remove, append, disabled, label } = props
  const modalProps = useModalProps()

  const handleImageDelete = (image: PhotoCardParams, index: number) => {
    remove(index)
  }

  const loadImages = (data: UploadFileFields) => {
    data.files.forEach((file) =>
      append({
        name: file.name || "",
        smallImageUrl: file.url || "",
        mediumImageUrl: file.url || "",
        largeImageUrl: file.url || "",
        file: file.file as File,
      })
    )
  }

  return (
    <>
      {label && (
        <StyledLabel size="s" color="fiftyP">
          {label}
        </StyledLabel>
      )}
      <Grid container spacing={3}>
        <PhotoCards
          images={images}
          loadImages={loadImages}
          displayImagesCount={6}
          onDelete={handleImageDelete}
          modalProps={modalProps}
          reserveMessage=""
          disabled={disabled}
        />
        <Grid item>
          <StyledButton
            isCircle
            variant="grayLight"
            onClick={modalProps.handleOpen}
            disabled={disabled}
          >
            <PlusIcon width={40} height={40} />
          </StyledButton>
        </Grid>
      </Grid>
    </>
  )
}

export default ImagesGroupWithAddButton

const StyledButton = styled(Button)`
  width: 136px;
  height: 136px;
  border-radius: 12px;
  background: ${({ theme }) => theme.palette.grey.fifteenB};
`

const StyledLabel = styled(Caption)`
  margin-bottom: 8px;
`

import * as yup from "yup"
import SCFixedCostsFormSchema from "../../../ServiceContractsPage/forms/SCFixedCostsForm/schema"

const SCInfoSchemaParams = {
  number: yup.string().trim().required("This field is required"),
  contractPeriodType: yup.string().required("This field is required"),
  signDate: yup.mixed().required("This field is required"),
  startDate: yup.mixed().required("This field is required"),
  rentPeriodType: yup.array().of(yup.string()).min(1),
}

const SCPriceTermsSchemaParams = {
  minRentPrice: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  maxRentPrice: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  initialCommission: yup
    .number()
    .positive()
    .required("This field is required")
    .max(100)
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  maintenanceCommission: yup
    .number()
    .positive()
    .required("This field is required")
    .max(100)
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
}

const SCMaintenanceTermsSchemaParams = {
  inspectionIntervalMonth: yup
    .number()
    .positive()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
}

const SCTerminationsTermsSchemaParams = {
  penaltyWithRental: yup
    .number()
    .positive()
    .max(100)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  penaltyInPreparation: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  notifyPeriodMonth: yup
    .number()
    .positive()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
}

export const SCInfoSchema = yup.object().shape(SCInfoSchemaParams)

export const SCPriceTermsSchema = yup.object().shape(SCPriceTermsSchemaParams)

export const SCMaintenanceTermsSchema = yup
  .object()
  .shape(SCMaintenanceTermsSchemaParams)

export const SCTerminationsTermsSchema = yup
  .object()
  .shape(SCTerminationsTermsSchemaParams)

export const SCFixedCostsSchema = SCFixedCostsFormSchema

export const SCTaxesSchema = yup.object().shape({
  agentType: yup.string().required("This field is required"),
})

export const SCInfoFullSchema = yup.object().shape({
  ...SCInfoSchemaParams,
  ...SCPriceTermsSchemaParams,
  ...SCMaintenanceTermsSchemaParams,
  ...SCTerminationsTermsSchemaParams,
})

import * as React from "react"
import styled from "@emotion/styled"
import { css, Theme } from "@emotion/react"
import { TableHeadProps, TableHeadStylesProps } from "./types"

function TableHead({
  children,
  offset,
  variant,
  ...otherProps
}: TableHeadProps) {
  return (
    <StyledTableHead
      offset={offset}
      variant={variant || "white"}
      {...otherProps}
    >
      {children}
    </StyledTableHead>
  )
}

export default TableHead

const tableHeadVariantsStyles = (theme: Theme) => ({
  grey: css`
    border-radius: 8px;
    tr {
      background: ${theme.palette.background.light1};
    }
    td {
      border: none;
    }
    td:first-child {
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  `,
  white: css`
    background: ${theme.palette.grey.zero};
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      display: block;
      background: ${theme.palette.background.light2};
    },
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      display: block;
      background: ${theme.palette.background.light2};
    },
  `,
})

const StyledTableHead = styled.thead<TableHeadStylesProps>`
  position: sticky;
  top: ${({ offset }) => offset || 120}px;
  z-index: 1000;
  td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  ${({ variant, theme }) => tableHeadVariantsStyles(theme)[variant]}
`

import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { inspectionsRequestBody } from "kui-crm_actions"
import {
  ApartmentDetailInfoParams,
  ApartmentMainInfoParams,
  ApartmentSecurityInfoParams,
} from "kui-crm/types"
import {
  InitialInspectionModel,
  PatchInitialInspectionBody,
} from "../../types/api/initialInspection"
import { RoomImagesParams } from "../../types/store/inspection"
import InspectionPageStore from "../InspectionPageStore"
import { InitialInspectionFormFields } from "../../variants/InitialInspectionInfo/types"
import { PatchRoomImagesParams } from "../../types/api/common"
import ApartmentFieldsStore from "../../../../../../store/shared/apartment/ApartmentFieldsStore"

class InitialInspectionStore {
  id: number

  date: DateTime | null

  dropboxLink: string

  mainObjectInfo: ApartmentMainInfoParams

  securityObjectInfo: ApartmentSecurityInfoParams

  detailObjectInfo: ApartmentDetailInfoParams

  roomsImages: RoomImagesParams[]

  inspectionStore: InspectionPageStore

  constructor(
    inspection: InitialInspectionModel,
    inspectionStore: InspectionPageStore
  ) {
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.mainObjectInfo = ApartmentFieldsStore.getApartmentMainInfo(
      inspection.initial
    )
    this.securityObjectInfo = ApartmentFieldsStore.getApartmentSecurityInfo(
      inspection.initial
    )
    this.detailObjectInfo = ApartmentFieldsStore.getApartmentDetailInfo(
      inspection.initial
    )
    this.roomsImages = InspectionPageStore.getObjectRoomsImages(
      inspection.photo_groups
    )
    this.inspectionStore = inspectionStore
    this.dropboxLink = inspection.dropbox_link || ""
    makeAutoObservable(this)
  }

  patchInspection = async (
    data: InitialInspectionFormFields
  ): Promise<PatchInitialInspectionBody> => {
    const roomsImages = await this.inspectionStore.actualizeRoomsImages(
      data.roomsImages
    )
    return InitialInspectionStore.getPatchInspectionBody(data, roomsImages)
  }

  static getPatchInspectionBody = (
    inspection: InitialInspectionFormFields,
    roomsImages: PatchRoomImagesParams[]
  ): PatchInitialInspectionBody => ({
    dropbox_link: inspection.dropboxLink,
    photo_groups: roomsImages,
    initial: inspectionsRequestBody.getInitialInspectionFields(inspection),
  })
}

export default InitialInspectionStore

import React from "react"
import { Box, Button, Caption } from "kui-basic"
import { NextArrowIcon, PrevArrowIcon } from "kui-icon"
import { InputWithController } from "kui-complex"
import styled from "@emotion/styled"
import { CheckIcon } from "kui-icon"
import UploadFiles from "../UploadFiles"
import { UploadFilesWithLabelProps } from "./types"

const UploadFilesWithLabel = <T extends object>(
  props: UploadFilesWithLabelProps<T>
) => {
  const {
    form,
    label,
    description,
    onPrevClick,
    withPrev,
    withNext,
    withComment,
    name,
    ...other
  } = props
  return (
    <>
      <Box mb={2} pr={5} pl={5}>
        <Caption size="s" color="fiftyP">
          {description}
        </Caption>
      </Box>
      <StyledUploadFiles
        form={form as any}
        name={withComment ? `${name}.files` : name}
        settings={
          withComment && (
            <InputWithController
              form={form}
              isTextArea
              label={label}
              name={`${name}.comment`}
            />
          )
        }
        leftButton={
          !!withPrev && (
            <Button
              isCircle
              size="s"
              variant="whiteWithGray"
              onClick={onPrevClick}
            >
              <StyledPrevArrowIcon />
            </Button>
          )
        }
        submitTitle={withNext ? "Next" : "Create"}
        submitButtonProps={{
          endIcon: withNext ? <NextArrowIcon /> : <CheckIcon />,
        }}
        {...other}
      />
    </>
  )
}

export default UploadFilesWithLabel

const StyledUploadFiles = styled(UploadFiles)`
  padding-top: 0;
  .FileWrapper {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`

const StyledPrevArrowIcon = styled(PrevArrowIcon)`
  width: 12px;
  path {
    fill: ${({ theme }) => theme.palette.grey.fourty} !important;
  }
`

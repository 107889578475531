import React, { useState } from "react"
import { observer } from "mobx-react"
import useTabIndex from "../../../../../hooks/useTabIndex"
import useClientStore from "../../../store"
import ClientDataTab from "../../tabs/ClientDataTab"
import ClientOverviewTab from "../../tabs/ClientOverviewTab"
import PageTabsPanel from "../../../../../components/ui/PageTabsPanel"
import ClientTabsSettings from "../ClientTabsSettings"

const paths = ["?tab=overview", "?tab=data"]
const tabs = ["Overview", "Data"]
const tabPanels = [<ClientOverviewTab />, <ClientDataTab />]

function ClientTabsPanel() {
  const { editor } = useClientStore()
  const activeTab = useTabIndex(paths)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTab(tabIndex)
    editor.endEditing()
  }

  return (
    <PageTabsPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
      onChange={handleTabChange}
      endComponent={<ClientTabsSettings activeTab={currentTab} />}
    />
  )
}

export default observer(ClientTabsPanel)

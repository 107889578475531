const clientDocumentPersonalFields = [
  { label: "Privacy policy agreement", name: "privacyPolicy" },
]

const clientDocumentCompanyFields = [
  { label: "Legal entity info", name: "companyInfo" },
  { label: "Confirmation of authority", name: "confirmationOfAuthority" },
]

export { clientDocumentPersonalFields, clientDocumentCompanyFields }

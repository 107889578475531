import { makeAutoObservable } from "mobx"
import {
  InitialInspectionFormFields,
  inspectionsRequests,
  InventoryInspectionFormFields,
  RegularInspectionFormFields,
  TransferInspectionFormFields,
} from "kui-crm_actions"
import { callPromises, Loader, MultistepForm, toNumber } from "kui-utils"
import { FileBodyRequest, ImageBodyRequest } from "kui-crm/types"
import {
  AppraisalInspectionFormFields,
  AppraisalInspectionInfoFields,
} from "../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/types"
import { uploadFiles, uploadImages } from "../../utils/agent/uploadFiles"
import {
  ImpairmentsInspectionFormFields,
  ImpairmentsInspectionInfoFields,
} from "../../components/forms/apartment/InspectionCreationForm/variations/ImpairmentsInspectionForm/types"
import {
  AppraisalInspectionPostBody,
  ImpairmentsInspectionPostBody,
} from "../../pages/ApartmentPage/types/api/apartmentInspectionsAPI"
import { clearNotValidFields } from "../../utils/service/mapper"
import { ApartmentPageLiteStoreInterface } from "../../types/store/apartments"

class ApartmentInspectionsLiteStore {
  creationForm: MultistepForm<any>

  apartmentStore: ApartmentPageLiteStoreInterface

  loader: Loader

  actionLoader: Loader

  constructor(apartmentStore: ApartmentPageLiteStoreInterface) {
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.creationForm = new MultistepForm<any>()
    this.apartmentStore = apartmentStore
    makeAutoObservable(this)
  }

  createInspection = async (apartmentId: number, body: any) =>
    inspectionsRequests.createInspection(apartmentId, body, this.actionLoader)

  createInventoryInspection = async (data: InventoryInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createInventoryInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createRegularInspection = async (data: RegularInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createRegularInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createInitialInspection = async (data: InitialInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createInitialInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createTransferInspection = async (data: TransferInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createTransferInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createAppraisalInspection = async (data: AppraisalInspectionFormFields) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("inspection creation")
      const uploadedObjectsFiles = data.comparableObjects.map(
        (object) => object.screenshot
      )
      const uploadedValuationsFiles = data.statisticalValuations.map(
        (object) => object.screenshot
      )

      const files = await callPromises([
        uploadFiles(this.actionLoader, uploadedObjectsFiles),
        uploadFiles(this.actionLoader, uploadedValuationsFiles),
      ])
      const body = ApartmentInspectionsLiteStore.getPostBodyAppraisalInspection(
        data,
        files[0],
        files[1]
      )
      return this.createInspection(apartmentId, body)
    }
    return null
  }

  createImpairmentsInspection = async (
    data: ImpairmentsInspectionFormFields
  ) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("inspection creation")
      const uploadedFiles = data.impairments.map(
        (impairment) => impairment.proofFile
      )
      const uploadedImages = data.impairments.map((impairment) =>
        uploadImages(this.actionLoader, impairment.images)
      )

      const files = await callPromises([
        uploadFiles(this.actionLoader, uploadedFiles),
        ...uploadedImages,
      ])
      const body =
        ApartmentInspectionsLiteStore.getPostBodyImpairmentsInspection(
          data,
          files[0],
          files.slice(1)
        )
      return this.createInspection(apartmentId, body)
    }
    return null
  }

  get apartmentId() {
    return this.apartmentStore.overviewStore.id
  }

  static getPostBodyAppraisalInspection = (
    inspection: AppraisalInspectionFormFields,
    objectsFiles: (FileBodyRequest | null)[],
    valuationsFiles: (FileBodyRequest | null)[]
  ): AppraisalInspectionPostBody => ({
    date: inspection.date.toISODate()!,
    inspection_type: inspection.type,
    based_on: inspection.basedOn || null,
    appraisal: ApartmentInspectionsLiteStore.getAppraisalInspectionFields(
      inspection,
      objectsFiles,
      valuationsFiles
    ),
  })

  static getAppraisalInspectionFields = (
    inspection: AppraisalInspectionInfoFields,
    objectsFiles: (FileBodyRequest | null)[],
    valuationsFiles: (FileBodyRequest | null)[]
  ) => ({
    max_price: toNumber(inspection.maxPrice),
    min_price: toNumber(inspection.minPrice),
    advertising_period: inspection.advertisingPeriod,
    recommendations: inspection.recommendations,
    owner_wishes: inspection.ownerWishes,
    comparable_objects: inspection.comparableObjects.map((object, key) => ({
      id: object.id,
      advertisement_link: object.link,
      object_price: toNumber(object.price),
      key_object_differences: object.differences,
      advertisement_screenshot: objectsFiles[key],
    })),
    statistical_valuations: inspection.statisticalValuations.map(
      (valuation, key) => ({
        id: valuation.id,
        source_name: valuation.dataSourceName,
        link: valuation.link,
        min_price: toNumber(valuation.minPrice),
        max_price: toNumber(valuation.maxPrice),
        screenshot: valuationsFiles[key],
      })
    ),
  })

  static getPostBodyImpairmentsInspection = (
    inspection: ImpairmentsInspectionFormFields,
    files: FileBodyRequest[],
    images: ImageBodyRequest[][]
  ): ImpairmentsInspectionPostBody => ({
    date: inspection.date.toISODate()!,
    inspection_type: inspection.type,
    based_on: inspection.basedOn || null,
    impairments: ApartmentInspectionsLiteStore.getImpairmentsInspectionFields(
      inspection,
      files,
      images
    ),
  })

  static getImpairmentsInspectionFields = (
    inspection: ImpairmentsInspectionInfoFields,
    files: FileBodyRequest[],
    images: ImageBodyRequest[][]
  ) =>
    inspection.impairments.map((impairment, index) => ({
      id: impairment.id,
      impairment_type: impairment.type,
      price: toNumber(impairment.price),
      description: impairment.description,
      solution: impairment.solution,
      proof_file: files[index],
      photos: images[index],
      item_link: impairment.link,
    }))

  static getMeterValues = (
    initialValue?: string | number | null,
    lastValue?: string | number | null
  ) =>
    clearNotValidFields({
      initial_value: initialValue,
      last_value: lastValue,
    })
}

export default ApartmentInspectionsLiteStore

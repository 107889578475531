import { addToArrayByCondition } from "kui-utils"
import { InputFileWithVisibility } from "kui-crm"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { ApartmentDocumentFormFields } from "../../../../pages/RegistriesPage/components/forms/ApartmentDocumentForm/types"
import { ApartmentDocumentVariants } from "../../../../pages/ApartmentPage/types/store/apartment"

const getApartmentDocumentFormFields = (
  variant: ApartmentDocumentVariants
): InputParams<ApartmentDocumentFormFields>[] => [
  {
    name: "file",
    label: "Document",
    variant: "custom",
    CustomInput: InputFileWithVisibility,
    size: 12,
  },
  {
    name: "number",
    label: "Number",
    size: variant === "insurance" ? 6 : 12,
  },
  ...addToArrayByCondition(variant === "insurance", {
    name: "price",
    label: "Price",
    cornerLabel: "₽",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
  }),
  {
    name: "startDate",
    label: "Start date",
    variant: "date",
    size: 6,
  },
  {
    name: "endDate",
    label: "Expiration date",
    variant: "date",
    size: 6,
  },
]

export default getApartmentDocumentFormFields

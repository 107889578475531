import React from "react"
import { Button, Grid } from "kui-basic"
import { observer } from "mobx-react"
import useExpensesStore from "../../../../store"

const CalcDepositFileButton = () => {
  const { summaryStore, overviewStore, periodStore, selectedContract } =
    useExpensesStore()
  const disabled = !(
    periodStore.isLastMonthOfContract && !periodStore.isDepositCalculated
  )

  const handleClick = () => {
    if (overviewStore.id && selectedContract?.id && periodStore.id) {
      summaryStore.calcDepositAndRefresh(
        overviewStore.id,
        selectedContract.id,
        periodStore.id
      )
    }
  }

  return (
    <Grid item>
      <Button
        disabled={disabled}
        variant="white"
        size="s"
        onClick={handleClick}
      >
        Deposit calculation
      </Button>
    </Grid>
  )
}

export default observer(CalcDepositFileButton)

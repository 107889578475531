import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import { RowWithDateProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../utils/content/constants"
import DateWithDot from "./DateWithDot"

const RowWithDate = (props: RowWithDateProps) => {
  const { date, link, label, apartment } = props

  return (
    <StyledLink href={link}>
      <StyledRow container alignItems="center" justify="space-between">
        <StyledPreviewContent
          imageSrc={apartment.previewImage || defaultApartmentImageURL}
          description={label}
          label={
            <Caption size="xs" color="fiftyP">
              {apartment.address}
            </Caption>
          }
        />
        {!!date && <DateWithDot date={date} />}
      </StyledRow>
    </StyledLink>
  )
}

export default RowWithDate

const StyledLink = styled(Link)`
  &:not(&:last-of-type) > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
`

const StyledPreviewContent = styled(PreviewContent)`
  max-width: calc(100% - 100px);
  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

import React from "react"
import { Divider, Grid } from "kui-basic"
import { FormWrapper, Modal } from "kui-crm"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { dashboardCardsFields, dashboardLinksFields } from "./content"
import { DashboardSettingsModalProps } from "./types"
import useDashboardStore from "../../../store"
import { AccountantDashboardSettings } from "../../../types/store/accountantDashboard"
import DashboardSettingRow from "../../../components/common/DashboardSettingRow"

const AccountantSettingsModal = (props: DashboardSettingsModalProps) => {
  const { accountantDashboard } = useDashboardStore()
  const form = useForm<AccountantDashboardSettings>({
    defaultValues: { ...accountantDashboard?.settings },
  })

  const handleSubmit = (data: AccountantDashboardSettings) => {
    accountantDashboard?.editSettings(data)
  }

  return (
    <Modal title="Dashboard settings" {...props}>
      <FormWrapper
        form={form}
        onSubmit={form.handleSubmit(handleSubmit)}
        label="Save"
      >
        <Grid container spacing={2}>
          {dashboardLinksFields.map((field) => (
            <DashboardSettingRow key={field.name} form={form} {...field} />
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {dashboardCardsFields.map((field) => (
            <DashboardSettingRow key={field.name} form={form} {...field} />
          ))}
        </Grid>
      </FormWrapper>
    </Modal>
  )
}

export default observer(AccountantSettingsModal)

import requests from "./index"
import { PatchServiceContractRequest } from "../types/api/service_contracts"

const ServiceContractAgent = {
  getById: (id: number) => requests.get(`/service-contracts/${id}`),
  patch: (id: number, data: PatchServiceContractRequest) =>
    requests.post(`/service-contracts/${id}/changes`, data),
  delete: (id: number) => requests.delete(`/service-contracts/${id}`),
  getDocuments: (id: number) =>
    requests.get(`/service-contracts/${id}/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/service-contracts/${id}/documents`, data),
  patchDocument: (documentId: number, data: FormData, id: number) =>
    requests.patch(`/service-contracts/${id}/documents/${documentId}`, data),
  deleteDocument: (documentId: number, id: number) =>
    requests.delete(`/service-contracts/${id}/documents/${documentId}`),
  getChanges: (id: number, isApplied?: boolean) =>
    requests.get(`/service-contracts/${id}/changes?applied=${!!isApplied}`),
  deleteChange: (scId: number, id: number) =>
    requests.delete(`/service-contracts/${scId}/changes/${id}`),
}

export default ServiceContractAgent

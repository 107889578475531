import React, { useState } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { Link } from "kui-crm"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import EditMetersData from "../../../../forms/meters/EditMetersData"
import TableCellMenu from "../../../../../../../../components/common/TableCellMenu"
import { ComplexMeterRowProps } from "./types"
import LinkButton from "../../../../../../../../components/common/LinkButton"
import TableRowDivider from "../../../../../../../../components/common/TableRowDivider"
import TwoCaptionsBlock from "../../../../../../../../components/common/TwoCaptionsBlock"
import { Routes } from "../../../../../../../../types/route"
import useExpensesStore from "../../../../store"
import PartialPayerCell from "../PartialPayerCell"
import MeterLabel from "../MeterLabel"
import MeterCostCell from "../MeterCostCell"

function ComplexMeterRow(props: ComplexMeterRowProps) {
  const { counter, tariff, onModalOpen, disabled, ModalComponent } = props
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { periodStore } = useExpensesStore()

  const handleSubmit = (value: string) => {
    if (periodStore.id) {
      tariff.postMeterValue(value, periodStore.id)
    }
  }

  const handleDeactivate = () => {
    counter.deactivate()
  }

  const handleModalOpen = async () => {
    await onModalOpen(counter.id)
    setIsEditModalOpen(true)
  }

  const handleModalClose = () => {
    setIsEditModalOpen(false)
  }

  return (
    <>
      <StyledRow data-testid="complex_row">
        <TableCell isFirst>
          <Link
            href={`${Routes.meteringDevices}/${counter.resource}/${counter.id}`}
          >
            <TwoCaptionsBlock
              top={<MeterLabel meter={counter} />}
              bottom={`#${counter.number}`}
              topSize="s"
              bottomSize="xs"
              topWeight={500}
              spacing={2}
              data-testid="counter_title"
            />
          </Link>
        </TableCell>
        <TableCell data-testid="check_date">
          {counter.nextCheck?.toFormat("dd.MM.yy")}
        </TableCell>
        <PartialPayerCell
          mainPayer={counter.payer}
          refundPayer={counter.paymentsInfo}
        />
        <TableCell data-testid="tariff_group">
          <LinkButton
            href={`${Routes.tariffsGroups}/${counter.resource}/${counter.tariff?.id}`}
          >
            {counter.tariff?.name}
          </LinkButton>
        </TableCell>
        <TableCell data-testid="prev_month_value">
          {tariff.prevMonthValue}
        </TableCell>
        <TableCell colSpan={2}>
          <EditMetersData
            data-testid="current_value"
            type={counter.title}
            isLoading={tariff.loader.isLoading}
            handleSubmit={handleSubmit}
            value={Number(tariff.value)}
            date={tariff.editingDate?.toFormat("dd.MM.yy")}
            disabled={disabled}
          />
        </TableCell>
        <TableCell data-testid="consumption">
          {tariff.consumption.toLocaleString()}
        </TableCell>
        <TableCell data-testid="tariff_value">
          {tariff.tariffValue
            ? `${tariff.tariffValue.toLocaleString()} ₽`
            : "-"}
        </TableCell>
        <TableCell data-testid="total_price">
          <MeterCostCell meter={counter} disabled={disabled} />
        </TableCell>
        <TableCellMenu
          align="right"
          disabled={disabled}
          data-testid="menu_cell"
          options={[
            {
              label: "Edit metering devices",
              handleClick: handleModalOpen,
            },
            {
              label: "Deactivate",
              handleClick: handleDeactivate,
            },
          ]}
        />
      </StyledRow>
      <TableRowDivider />
      <ModalComponent open={isEditModalOpen} handleClose={handleModalClose} />

      <LoaderState loader={counter.loader} />
    </>
  )
}

export default observer(ComplexMeterRow)

const StyledRow = styled(TableRow)`
  td:first-child {
    padding-left: 27px;
  }
  td:last-child {
    padding-right: 15px;
  }
`

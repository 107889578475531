/** @jsxImportSource @emotion/react */
import React, { SyntheticEvent, useRef, useState } from "react"
import styled from "@emotion/styled"
import _ from "lodash"
import { ArrayPath, useFieldArray } from "react-hook-form"
import { Box, Caption } from "kui-basic"
import FileItem from "./FileItem"
import { UploadFilesProps } from "./types"
import UploadFilesFooter from "./UploadFilesFooter"
import { maxWidth } from "../../../utils/service/theme"
import UploadFilesLabel from "./UploadFilesLabel"
import { UploadFileParams } from "./FileItem/types"
import { imageExtensions } from "../../../utils/content/constants"

const limitTitle = 100

function UploadFiles<TFormValues extends object>({
  form,
  name,
  submitTitle,
  leftButton,
  rightButton,
  className,
  required,
  selectedFile,
  setSelectedFile,
  settings,
  onAdd,
  onRemove,
  disabled,
  submitButtonProps,
  label,
  variant,
}: UploadFilesProps<TFormValues>) {
  const ref = useRef<HTMLInputElement>(null)
  const { fields, append, remove, update } = useFieldArray<TFormValues>({
    control: form.control,
    name: name! as ArrayPath<TFormValues>,
  })
  const [hasToBigTitle, setHasToBigTitle] = useState(false)
  const [error, setError] = useState("")

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const fileUploaded =
      (e.target as HTMLInputElement).files &&
      Number((e.target as HTMLInputElement).files?.length) > 0
        ? (e.target as HTMLInputElement).files
        : null

    if (fileUploaded) {
      let toBigTitle = false
      const newFiles: UploadFileParams[] = []
      setError("")

      _.forEach(fileUploaded, (file: File) => {
        const fileExtensions = file.name.split(".").pop()?.toLocaleLowerCase()
        if (
          variant === "images" &&
          !imageExtensions.includes(fileExtensions!)
        ) {
          setError("Only images have been uploaded")
        } else {
          const newFile = {
            index: `${file.name}_${file.size}`,
            name: file.name,
            size: Math.round((file.size / (1024 * 1024)) * 100) / 100,
            url: URL.createObjectURL(file),
            file,
          }
          append(newFile as any)
          newFiles.push(newFile)
          if (Number(file.name?.length) > limitTitle) {
            toBigTitle = true
          }
        }
      })

      setHasToBigTitle(toBigTitle)
      if (onAdd) {
        onAdd(newFiles)
      }
    }
  }

  const handleDelete = (fileKey: number | string, index: number) => {
    if (fields.length === 1 && ref.current) {
      ref.current.value = ""
    }
    remove(index)
    if (onRemove) {
      onRemove(index)
    }
  }

  const handleClick = () => {
    if (ref.current) {
      ref.current.click()
    }
  }

  const changeName = (newName: string, index?: number) => {
    if (index) {
      const currentFile = fields[index]
      update(index, { ...currentFile, name: newName })
    }
  }

  return (
    <>
      <ContentWrapper className={className}>
        <>
          <StyledInput
            id="file_input"
            ref={ref}
            multiple
            type="file"
            data-testid="file_input"
            onChange={handleChange}
          />
          {label && (
            <Box mb={2}>
              <Caption size="s" color="fiftyP">
                {label}
              </Caption>
            </Box>
          )}
          {fields.length > 0 ? (
            <>
              <div data-testid="files_wrapper">
                {fields.map((file: any, index) => (
                  <FileItem
                    key={file.index}
                    index={index}
                    file={file}
                    onDelete={handleDelete}
                    onChange={changeName}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    limitTitle={limitTitle}
                  />
                ))}
              </div>
              {settings}
            </>
          ) : (
            <UploadFilesLabel variant={variant} handleChange={handleChange} />
          )}
          {error && (
            <Caption colorGroup="red" weight={500} size="s">
              {error}
            </Caption>
          )}
        </>
      </ContentWrapper>
      <UploadFilesFooter
        showAddButton={fields.length > 0}
        disabled={disabled}
        disabledSubmit={
          !!((required && fields.length === 0) || hasToBigTitle || disabled)
        }
        submitTitle={submitTitle}
        leftButton={leftButton}
        rightButton={rightButton}
        onClick={handleClick}
        submitButtonProps={submitButtonProps}
      />
    </>
  )
}

UploadFiles.defaultProps = {
  name: "files",
  required: true,
  limitSize: 5,
}

export default UploadFiles

const StyledInput = styled.input`
  display: none;
`

const ContentWrapper = styled.div`
  padding: 16px 40px;
  ${maxWidth("xs")} {
    padding: 0 18px 24px;
    min-height: 212px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`

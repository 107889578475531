import React from "react"
import { Button, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { UpdateIcon } from "kui-icon"
import RCCheckOutDateModal from "../RCCheckOutDateModal"
import RCClosingModal from "../RCClosingModal"
import useRCStore from "../../store"
import PopupWithCheckList from "../../../../components/common/PopupWithCheckList"

const RCClosingButtons = () => {
  const { contractInfoStore } = useRCStore()
  const [isCheckOutDateOpen, openCheckOutDate, closeCheckOutDate] = useToggle()
  const [isClosingModalOpen, openClosingModal, closeClosingModal] = useToggle()
  const { dateOfLeaving, canBeClosed, closingProgress } = contractInfoStore

  return (
    <>
      <Grid item>
        <Button
          onClick={openCheckOutDate}
          variant="orange"
          size="xs"
          endIcon={dateOfLeaving ? <UpdateIcon /> : undefined}
        >
          {dateOfLeaving
            ? dateOfLeaving.toFormat("dd.MM.yyyy")
            : "Select check-out date"}
        </Button>
      </Grid>
      <Grid item>
        <PopupWithCheckList
          label="To close a contract you need to:"
          items={closingProgress}
          popupActive={!canBeClosed}
          button={
            <Button
              onClick={openClosingModal}
              size="xs"
              disabled={!canBeClosed}
            >
              Close contract
            </Button>
          }
        />
      </Grid>

      <RCCheckOutDateModal
        open={isCheckOutDateOpen}
        handleClose={closeCheckOutDate}
      />
      <RCClosingModal
        open={isClosingModalOpen}
        handleClose={closeClosingModal}
      />
    </>
  )
}

export default observer(RCClosingButtons)

import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import {
  DocumentModel,
  FileBodyRequest,
  DocumentVisibilityVariants,
} from "kui-crm/types"
import {
  DocumentAgentInterface,
  DocumentsStoreInterface,
  FileStoreInterface,
} from "../../types/store/file"
import { UploadDocumentParams } from "../../components/common/DocumentsBlockWithForm/types"
import {
  matchesAPIVisibilityVariants,
  matchesVisibilityVariants,
} from "../../utils/content/matches"

class DocumentStore implements FileStoreInterface {
  id: number

  name: string

  url: string

  date: DateTime | null

  size: number | null

  createdBy: string

  visibility?: DocumentVisibilityVariants

  documentsStore: DocumentsStoreInterface

  agent: DocumentAgentInterface

  loader: Loader

  constructor(
    document: DocumentModel,
    documentsStore: DocumentsStoreInterface,
    agent: DocumentAgentInterface
  ) {
    this.id = document.pk
    this.name = document.name ?? ""
    this.url = document.attachment ?? ""
    this.date = document.created ? DateTime.fromISO(document.created) : null
    this.size = Math.round((document.size / (1024 * 1024)) * 100) / 100 ?? null
    this.createdBy = document.uploaded_by ?? ""
    this.documentsStore = documentsStore
    this.agent = agent
    this.loader = new Loader()
    this.visibility = document.visibility
      ? (matchesVisibilityVariants[
          document.visibility
        ] as DocumentVisibilityVariants)
      : undefined
    makeAutoObservable(this, { documentsStore: false })
  }

  patchDocument = async (data: UploadDocumentParams, ...params: any) => {
    if (
      (data.name && this.name !== data.name) ||
      data.visibility !== this.visibility
    ) {
      this.loader.startLoading("document changes")

      const body = DocumentStore.getDocumentBody(data)
      const result = await to<DocumentModel>(
        this.agent.patchDocument(this.id, body, ...params)
      )

      resHandler(result, this.loader, this.updateDocument, "patch document")
    }
  }

  updateDocument = (res: DocumentModel) => {
    this.name = res.name
    this.visibility = res.visibility
      ? (matchesVisibilityVariants[
          res.visibility
        ] as DocumentVisibilityVariants)
      : this.visibility
  }

  deleteDocument = async (...params: any) => {
    this.loader.startLoading("document removal")

    const [err] = await to(this.agent.deleteDocument(this.id, ...params))
    if (!err) {
      this.documentsStore.deleteDocument(this.id)
    } else {
      this.loader.setError("document removal", err)
    }
    this.loader.endLoading()
  }

  get extension() {
    return this.name.split(".")?.pop()?.toUpperCase() || ""
  }

  get title() {
    return this.name.replace(/\.[^/.]+$/, "")
  }

  static getDocumentBody = (data: UploadDocumentParams) =>
    ({
      name: data.name?.replace(/\.[^/.]+$/, ""),
      visibility: matchesAPIVisibilityVariants[data.visibility!],
    } as FileBodyRequest)
}

export default DocumentStore

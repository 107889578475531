import React from "react"
import { Modal } from "kui-crm"
import { CreatingFillingModalProps } from "./types"
import FillingCreatingForm from "../../../../forms/filling/FillingCreatingForm"
import { FillingFormFields } from "../../../../forms/filling/types"

function FillingCreatingModal(props: CreatingFillingModalProps) {
  const { type, handleClose, open, handleSubmit } = props

  const handleFormSubmit = (data: FillingFormFields) => {
    handleSubmit(data)
    handleClose()
  }

  return (
    <Modal title={`New ${type}`} open={open} handleClose={handleClose}>
      <FillingCreatingForm type={type} handleSubmit={handleFormSubmit} />
    </Modal>
  )
}

export default FillingCreatingModal

import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import ComparableObjectsFields from "./ComparableObjectsFields"
import { AppraisalInspectionFormFields } from "./types"
import StatisticalValuationsFields from "./StatisticalValuationsFields"
import Container from "../../../../../../components/ui/Container"
import ManagerValuationFields from "./ManagerValuationFields"
import InspectionTitle from "../../components/InspectionTitle"
import useInspectionStore from "../../store"
import AppraisalInspectionStore from "../../store/variations/AppraisalInspectionStore"
import AppraisalInspectionInfoSchema from "./schema"

const AppraisalInspectionInfo = () => {
  const { inspectionInfo, editor, patchInspection } = useInspectionStore()
  const inspection = inspectionInfo as AppraisalInspectionStore

  const form = useForm<AppraisalInspectionFormFields>({
    defaultValues: {
      dropboxLink: inspection.dropboxLink,
      comparableObjects: inspection.comparableObjects,
      statisticalValuations: inspection.statisticalEvaluations,
      managerValuation: inspection.appraisalOffer,
    },
    resolver: yupResolver(AppraisalInspectionInfoSchema),
  })

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(patchInspection, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <>
      <InspectionTitle form={form} />
      <Container>
        <ComparableObjectsFields form={form} />
        <StatisticalValuationsFields form={form} />
        <ManagerValuationFields form={form} />
      </Container>
    </>
  )
}

export default observer(AppraisalInspectionInfo)

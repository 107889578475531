import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import useApartmentStore from "../../../store"
import { CreationInspectionFormProps } from "./types"
import InspectionCommonStep from "../../../../../components/forms/apartment/InspectionCreationForm/InspectionCommonStep"
import { DynamicRoutes } from "../../../../../types/route"
import generateInspectionSteps from "../../../../../components/forms/apartment/InspectionCreationForm/generateInspectionSteps"

const InspectionCreationForm = (props: CreationInspectionFormProps) => {
  const { type, basedOn, handleClose, onlyInspections } = props
  const { inspectionsStore, overviewStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const navigate = useNavigate()

  const handleCreate = (inspectionId?: number | null) => {
    if (inspectionId) {
      const inspectionLink = DynamicRoutes.inspection(
        overviewStore.id!,
        inspectionId
      )
      navigate(inspectionLink)
    }
    formStore.resetForm()
    handleClose()
  }

  useEffect(() => {
    if (basedOn) formStore.updateFormFields({ basedOn })
  }, [basedOn])

  return formStore.step === 1 ? (
    <InspectionCommonStep type={type} onlyInspections={onlyInspections} />
  ) : (
    generateInspectionSteps(handleCreate, 1)[
      (formStore.fields?.type ||
        "initial") as keyof typeof generateInspectionSteps
    ]
  )
}

export default observer(InspectionCreationForm)

import { addToArrayByCondition } from "kui-utils"
import React from "react"
import HousingInspectorDashboardStore from "../../../store/variants/HousingInspectorDashboardStore"
import ApartmentsInspectionsCard from "../../../components/cards/ApartmentsInspectionsCard"
import ApartmentInspectionsMap from "../../../components/cards/ApartmentInspectionsMap"

export const getListCards = (
  housingInspectorStore: HousingInspectorDashboardStore
) => [
  ...addToArrayByCondition(
    housingInspectorStore.settings?.apartmentsInspectionsList &&
      housingInspectorStore.apartmentsInspections !== null,
    {
      key: "apartmentsInspectionsList",
      card: (
        <ApartmentsInspectionsCard
          {...housingInspectorStore.apartmentsInspections!}
        />
      ),
      size: 4,
    }
  ),
  ...addToArrayByCondition(
    housingInspectorStore.settings?.apartmentsInspectionsMap &&
      housingInspectorStore.apartmentsInspections !== null,
    {
      key: "apartmentsInspectionsMap",
      card: (
        <ApartmentInspectionsMap
          inspections={
            housingInspectorStore.apartmentsInspections?.inspections || []
          }
        />
      ),
      size: 8,
    }
  ),
]

import React from "react"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { ApartmentInspectionsHeaderProps, DoneValueStylesProps } from "./types"

const ApartmentInspectionsHeader = (props: ApartmentInspectionsHeaderProps) => {
  const { value, done } = props

  return (
    <div>
      <Caption weight={500}>
        <StyledValueAccent isEmpty={value === 0}>{value}</StyledValueAccent>{" "}
        Upcoming inspections
      </Caption>
      <Caption weight={500}>
        <StyledDoneAccent isEmpty={done === 0}>{done}</StyledDoneAccent>{" "}
        Completed inspections
      </Caption>
    </div>
  )
}

export default ApartmentInspectionsHeader

const StyledValueAccent = styled.span<DoneValueStylesProps>`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
  color: ${({ theme, isEmpty }) =>
    isEmpty ? theme.palette.green.fiftyP : theme.palette.brand.main};
`

const StyledDoneAccent = styled.span<DoneValueStylesProps>`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme, isEmpty }) =>
    isEmpty ? theme.palette.red.fiftyP : theme.palette.green.fiftyP};
`

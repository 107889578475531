import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import {
  getPhoneNumberFromPhoneParams,
  getPhoneParamsFromString,
  Loader,
} from "kui-utils"
import { CompanyOfficeModel } from "kui-crm/types"
import { CompanyOfficeAddressParams } from "../types/store/companyOffice"
import { PhoneParams } from "../../../types/common"
import CompanyAgent from "../../../agent/Company"
import { CompanyOfficeFields } from "../forms/CompanyOfficesForm/types"
import { clearNotValidFields } from "../../../utils/service/mapper"
import CompanyOfficesStore from "./CompanyOfficesStore"

class CompanyOfficeStore {
  id: number | null

  office: CompanyOfficeAddressParams | null

  phone: PhoneParams | null

  email: string

  zipCode: string

  loader: Loader

  constructor(office: CompanyOfficeModel) {
    this.id = office.id
    this.office = {
      address: office.address,
      location: office.geolocation || null,
    }
    this.phone = office.phone ? getPhoneParamsFromString(office.phone) : null
    this.email = office.email
    this.zipCode = office.zip_code || ""
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  patchCompanyOffice = async (companyId: number, data: CompanyOfficeFields) => {
    this.loader.startLoading()

    const body = CompanyOfficesStore.getOfficeBody(data)

    const [err, res] = await to(
      CompanyAgent.patchOffice(companyId, this.id!, body)
    )

    runInAction(() => {
      if (!err && res) {
        this.updateCompanyOffice(res)
      } else {
        this.loader.setError("patch office")
      }
      this.loader.endLoading()
    })
  }

  deleteCompanyOffice = async (companyId: number) => {
    this.loader.startLoading()

    const [err] = await to(CompanyAgent.deleteOffice(companyId, this.id!))
    runInAction(() => {
      if (err) {
        this.loader.setError("office removal")
      }
      this.loader.endLoading()
    })

    return !!err
  }

  updateCompanyOffice = (office: CompanyOfficeModel) => {
    this.office = {
      address: office.address,
      location: office.geolocation || null,
    }
    this.phone = office.phone ? getPhoneParamsFromString(office.phone) : null
    this.email = office.email
    this.zipCode = office.zip_code || ""
  }

  static getPatchCompanyOfficeBody = (data: CompanyOfficeFields) =>
    clearNotValidFields({
      email: data.email,
      address: data.office?.address,
      zip_code: data.zipCode,
      phone: data.phone ? getPhoneNumberFromPhoneParams(data.phone) : null,
      geolocation: data.office?.location,
    })
}

export default CompanyOfficeStore

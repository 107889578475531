import { DateTime } from "luxon"
import { callPromises } from "kui-utils"
import { inspectionsRequestBody } from "kui-crm_actions"
import { makeAutoObservable } from "mobx"
import { ResourceTypes, FileBodyRequest, ImageBodyRequest } from "kui-crm/types"
import {
  FullInspectionCommonFields,
  InspectionMeterCommonInfo,
} from "../../types/api/transferInspection"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import { ApartmentDescriptionParams } from "../../../../../../types/store/apartments"
import {
  InspectionMeterInfoParams,
  InspectionMetersInfoParams,
  InspectionPaymentInfoParams,
} from "../../types/store/transferInspection"
import { RoomImagesParams } from "../../types/store/inspection"
import InspectionPageStore from "../InspectionPageStore"
import FileStore from "../../../../../../store/templates/File"
import { InventoryInspectionInfoFields } from "../../variants/InventoryInspectionInfo/types"
import {
  InspectionFillingModel,
  PatchRoomImagesParams,
} from "../../types/api/common"
import ImageStore from "../../../../../../store/templates/ImageStore"
import { FillingParams } from "../../../../../../components/forms/apartmentFilling/FillingList/ApartmentFillingCard/types"
import { matchesAPIFillingTypes } from "../../../../../../utils/content/matches"
import { ApartmentFillingVariants } from "../../../../types/store/apartmentFilling"
import {
  InventoryInspectionFields,
  InventoryInspectionModel,
} from "../../types/api/inventoryInspection"
import TransferInspectionStore from "./TransferInspectionStore"

class InventoryInspectionStore {
  id: number

  date: DateTime | null

  dropboxLink: string

  descriptionFields: ApartmentDescriptionParams | null

  paymentFields: InspectionPaymentInfoParams

  metersInfo: InspectionMetersInfoParams

  apartmentStore: ApartmentPageStore

  roomsImages: RoomImagesParams[]

  fillingList: FillingParams[]

  inspectionStore: InspectionPageStore

  impairmentsInspectionId: number | null

  appraisalInspectionId: number | null

  constructor(
    inspection: InventoryInspectionModel,
    inspectionStore: InspectionPageStore,
    apartmentStore: ApartmentPageStore
  ) {
    const inspectionFields = inspection.inventory
    this.apartmentStore = apartmentStore
    this.descriptionFields =
      this.apartmentStore.getApartmentDescriptionInfo(inspectionFields)
    this.paymentFields =
      InventoryInspectionStore.getInspectionPaymentInfo(inspectionFields)
    this.metersInfo =
      InventoryInspectionStore.getInventoryMetersInfo(inspectionFields)
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.roomsImages = InspectionPageStore.getObjectRoomsImages(
      inspection.photo_groups
    )
    this.fillingList =
      inspectionFields?.filling.map((filling) =>
        InventoryInspectionStore.getInspectionFillingParams(filling)
      ) || []
    this.inspectionStore = inspectionStore
    this.dropboxLink = inspection.dropbox_link || ""
    this.impairmentsInspectionId = inspection.impairment_id || null
    this.appraisalInspectionId = inspection.appraisal_id || null
    makeAutoObservable(this)
  }

  patchInspection = async (data: InventoryInspectionInfoFields) => {
    const metersFiles = data.meters?.map((meter) => meter.passportFile) || []
    const fillingFiles =
      data.fillingList?.map((filling) => filling.instruction) || []
    const fillingImages =
      data.fillingList?.map((filling) => filling.images || []) || []
    const metersImages = data.meters?.map((meter) => meter.images || []) || []
    const files = await callPromises([
      this.inspectionStore.actualizeFiles(metersFiles),
      this.inspectionStore.actualizeFiles(fillingFiles),
      this.inspectionStore.actualizeImages(fillingImages),
      this.inspectionStore.actualizeImages(metersImages),
      this.inspectionStore.actualizeRoomsImages(data.roomsImages),
    ])

    return InventoryInspectionStore.getPatchInspectionBody(
      data,
      files[0],
      files[1],
      files[2],
      files[3],
      files[4]
    )
  }

  static getPatchInspectionBody = (
    inspection: InventoryInspectionInfoFields,
    metersFiles: FileBodyRequest[],
    fillingFiles: FileBodyRequest[],
    fillingImages: ImageBodyRequest[][],
    metersImages: ImageBodyRequest[][],
    roomsImages: PatchRoomImagesParams[]
  ) => ({
    dropbox_link: inspection.dropboxLink,
    photo_groups: roomsImages,
    inventory: {
      ...ApartmentPageStore.getPatchApartmentBody(inspection),
      ...inspectionsRequestBody.getInventoryInspectionMeters(
        inspection.meters,
        metersFiles,
        metersImages
      ),
      filling: InventoryInspectionStore.getInspectionFillingBody(
        inspection.fillingList,
        fillingFiles,
        fillingImages
      ),
      administrative_company: inspection.company?.id,
      taxes_type: inspection.taxesType,
      country_of_residence: inspection.countryOfResidence?.id,
    },
  })

  static getInspectionPaymentInfo = (
    inspection: FullInspectionCommonFields
  ): InspectionPaymentInfoParams => ({
    ...ApartmentPageStore.getApartmentPaymentInfo(inspection),
    countryOfResidence: inspection.country_of_stay,
    taxesType: inspection.taxes_type,
  })

  static getInventoryMetersInfo = (
    inspection: InventoryInspectionFields
  ): InspectionMetersInfoParams => ({
    meters: inspection.meters.map((meter) => ({
      ...InventoryInspectionStore.getMeterInfo(meter, meter.resource_type),
      ...TransferInspectionStore.getMeterValues(
        meter,
        meter.resource_type,
        "initialValue"
      ),
    })),
  })

  static getMeterInfo = (
    meter: InspectionMeterCommonInfo,
    resource: ResourceTypes
  ): InspectionMeterInfoParams => ({
    id: meter.id,
    type: meter.meter_type,
    number: meter.passport_number,
    startDate: meter.activation_date
      ? DateTime.fromISO(meter.activation_date)
      : null,
    company: meter.company,
    operationalAccountNumber: meter.operating_account,
    resource,
    tariff: meter.tariff
      ? {
          id: meter.tariff.id,
          name: meter.tariff.code_name,
        }
      : null,
    passportFile: meter.passport
      ? FileStore.initFromFileModel(meter.passport)
      : null,
    images:
      meter.photos?.map((photo) => ImageStore.initFromImageModel(photo)) || [],
  })

  static getInspectionFillingBody = (
    fillingList: FillingParams[],
    fillingFiles: FileBodyRequest[],
    fillingImages: ImageBodyRequest[][]
  ) =>
    fillingList.map((filling, index) => ({
      id: filling.id,
      instruction: fillingFiles[index],
      photos: fillingImages[index],
      feature: filling.tag.id,
      description: filling.name,
      serial_code: filling.serialNumber,
      amount: filling.numberOfSubjects,
    }))

  static getInspectionFillingParams = (filling: InspectionFillingModel) => ({
    id: filling.id,
    name: filling.description || "",
    tag: {
      id: filling.feature.id,
      name: filling.feature.name,
    },
    images: filling.photos?.map((image) =>
      ImageStore.initFromImageModel(image)
    ),
    serialNumber: filling.serial_code,
    numberOfSubjects: Number(filling.amount),
    instruction: filling.instruction
      ? FileStore.initFromDocumentModel(filling.instruction)
      : null,
    type: matchesAPIFillingTypes[
      filling.feature.feature_type as keyof typeof matchesAPIFillingTypes
    ] as ApartmentFillingVariants,
  })
}

export default InventoryInspectionStore

import React from "react"
import styled from "@emotion/styled"
import { Modal, ModalProps } from "kui-crm"
import CloseRentalContractForm from "../../forms/RCClosingForm"
import { RCClosingFormFields } from "../../forms/RCClosingForm/types"
import useRCStore from "../../store"

function RCClosingModal({ open, handleClose }: ModalProps) {
  const { contractInfoStore } = useRCStore()

  const handleSubmit = (data: RCClosingFormFields) => {
    contractInfoStore.closeRentalContract(data)
    handleClose()
  }

  return (
    <StyledModal
      title="Close rental contract"
      open={open}
      handleClose={handleClose}
    >
      <CloseRentalContractForm handleSubmit={handleSubmit} />
    </StyledModal>
  )
}

export default RCClosingModal

const StyledModal = styled(Modal)`
  h2 {
    padding-bottom: 12px;
  }
`

import React from "react"
import { Box, Grid, Heading } from "kui-basic"
import { InputWithController } from "kui-complex"
import _ from "lodash"
import { observer } from "mobx-react"
import { ResourceTypes } from "kui-crm/types"
import useTariffStore from "../../store"
import { TariffOverviewTariffsFieldsProps } from "./types"

const getResourceFields = (resource: ResourceTypes | null) => {
  switch (resource) {
    case "electricity":
      return [
        { label: "One-part", name: "tariffs.T1" },
        { label: "Two-part", name: "tariffs.T2" },
        { label: "Three-part", name: "tariffs.T3" },
      ]
    case "water":
      return [
        { label: "Cold", name: "tariffs.cold" },
        { label: "Hot", name: "tariffs.hot" },
        { label: "Sewer", name: "tariffs.sewerage" },
      ]
    default:
      return [{ label: "", name: "" }]
  }
}

function TariffOverviewTariffsFields({
  form,
}: TariffOverviewTariffsFieldsProps) {
  const { overviewStore, resource, editor } = useTariffStore()
  const tariffsCount = overviewStore.tariffInfoStore
    ? Object.keys(overviewStore.tariffInfoStore?.tariffs).length
    : 0
  const tariffsFields = getResourceFields(resource)
  const disabled = !editor.isEditing

  return (
    <Box mt={3}>
      <Box pb={2}>
        <Heading size="h4" weight={700}>
          Tariff{tariffsCount > 1 && "s"}
        </Heading>
      </Box>
      <Grid container spacing={3}>
        {tariffsFields.map((field) => (
          <Grid item xs={3} key={field.name}>
            <InputWithController
              form={form}
              type="number"
              label={
                !field.label && resource ? _.capitalize(resource) : field.label
              }
              name={field.name || `tariffs.${resource}`}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default observer(TariffOverviewTariffsFields)

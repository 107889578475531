import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState, ModalProps } from "kui-crm"
import { Loading } from "kui-complex"
import useDashboardStore from "../../store"
import AccountantDashboardCards from "./AccountantDashboardCards"
import DashboardHeader from "../../components/common/DashboardHeader"
import AccountantSettingsModal from "./AccountantSettingsModal"
import useDashboardFilter from "../../hooks/useDashboardFilter"

const AccountantDashboard = () => {
  const { accountantDashboard, periodFilter } = useDashboardStore()

  useDashboardFilter(periodFilter, accountantDashboard?.loadCards)

  useEffect(() => {
    accountantDashboard?.initAccountantDashboard()
  }, [])

  if (accountantDashboard?.loader.isLoading) {
    return <Loading height="100vh" />
  }

  return (
    <>
      <DashboardHeader
        settings={accountantDashboard?.settings}
        renderModal={(props: ModalProps) => (
          <AccountantSettingsModal {...props} />
        )}
      />
      <AccountantDashboardCards />
      <LoaderState loader={accountantDashboard?.loader} onlyError />
    </>
  )
}

export default observer(AccountantDashboard)

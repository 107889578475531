import { DateTime } from "luxon"
import {
  TaxesTypes,
  ServiceContractModel,
  PaymentStatuses,
  RentalTypes,
} from "kui-crm/types"
import {
  ContractInterface,
  ContractStatuses,
} from "../../../types/store/contract"
import UserLiteStore from "../../../store/templates/UserLite"
import ApartmentSimpleStore from "../../../store/templates/Apartment"
import NotesStore from "../../../store/notes/Notes"
import { RentPeriodTypes } from "../forms/CreateServiceContractForm/types"

class ServiceContractStore implements ContractInterface {
  id: number

  apartment: ApartmentSimpleStore | null

  number: string

  startDate: DateTime

  signDate: DateTime

  endDate: DateTime | null

  status: ContractStatuses

  landlord: UserLiteStore

  tenant: UserLiteStore | null

  type: RentalTypes

  rentPeriodType: RentPeriodTypes = []

  maintenanceCommission: string

  initialCommission: string

  inspectionIntervalMonth: number

  notifyPeriodMonth: number

  penaltyWithRental: string

  penaltyInPreparation: string

  minRentPrice: number

  maxRentPrice: number

  agentType: TaxesTypes

  autoProlongate: boolean

  paidStatus: PaymentStatuses

  notesStore: NotesStore

  constructor(contract: ServiceContractModel) {
    this.id = contract.id
    this.apartment = contract.apartment
      ? new ApartmentSimpleStore(contract.apartment)
      : null
    this.number = contract.number
    this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    this.tenant = contract.renter
      ? UserLiteStore.initFromLiteUserModel(contract.renter)
      : null
    this.startDate = DateTime.fromISO(contract.start_date)
    this.type = contract.rental_type
    this.signDate = DateTime.fromISO(contract.sign_date)
    this.endDate = contract.end_date
      ? DateTime.fromISO(contract.end_date)
      : null
    this.maintenanceCommission = contract.maintenance_commission
    this.initialCommission = contract.initial_commission
    this.inspectionIntervalMonth = contract.inspection_interval_month
    this.notifyPeriodMonth = contract.notify_period_month
    this.penaltyWithRental = contract.penalty_with_rental
    this.penaltyInPreparation = contract.penalty_in_preparation
    this.minRentPrice = contract.min_rent_price
    this.maxRentPrice = contract.max_rent_price
    this.agentType = contract.taxes_type
    this.autoProlongate = contract.auto_prolongate
    this.paidStatus = contract.agent_report_payment_status
    this.status = contract.status
    this.rentPeriodType = contract.long_term_type || []

    this.notesStore = new NotesStore("service-contracts", contract.id)
  }
}

export default ServiceContractStore

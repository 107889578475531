import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import { MeterVerificationModel } from "../../types/api/meters"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { MeterVerificationParams } from "../../types/cards/metersVerications"

class MetersVerificationsStore {
  value: number | null

  meters: MeterVerificationParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.meters = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchMetersVerifications = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getMetersVerifications(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.meters = this.paginator.getPageResponse(
          res,
          this.meters,
          MetersVerificationsStore.getMeterParams
        )
      })
    })
  }

  static getMeterParams = (meter: MeterVerificationModel) => ({
    id: meter.meter.id,
    apartment: ApartmentsStore.getApartmentLinkParams(meter.apartment),
    type: meter.meter.meter_type,
    resource: meter.meter.resource_type,
    date: meter.action_date ? DateTime.fromISO(meter.action_date) : null,
  })
}

export default MetersVerificationsStore

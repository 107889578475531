import { makeAutoObservable } from "mobx"
import { PaymentSummaryModel } from "../../types/api/expensesSummaryAPI"

class ApartmentBalanceSummaryStore {
  accruedPrice: number

  paidPrice: number

  constructor(summary: PaymentSummaryModel) {
    this.accruedPrice = summary?.accrued || 0
    this.paidPrice = summary?.paid || 0
    makeAutoObservable(this)
  }

  get paymentBalance() {
    return this.accruedPrice - this.paidPrice
  }
}

export default ApartmentBalanceSummaryStore

import React from "react"
import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { ComparableObjectFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/types"
import CopyButton from "../../../../../../../components/common/CopyButton"

const getComparableObjectFields = (
  index: number,
  link?: string
): InputParams<ComparableObjectFormFields>[] => [
  {
    label: "Link to advertisement",
    name: `comparableObjects.${index}.link`,
    endIcon: <CopyButton text={link || ""} />,
  },
  {
    label: "Advertisement screenshot",
    name: `comparableObjects.${index}.screenshot`,
    variant: "file",
  },
  {
    label: "Object price",
    name: `comparableObjects.${index}.price`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    cornerLabel: "₽",
  },
  {
    label: "Key object differences",
    name: `comparableObjects.${index}.differences`,
    isTextArea: true,
  },
]

export default getComparableObjectFields

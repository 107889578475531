import { makeAutoObservable } from "mobx"
import { getPhoneParamsFromString } from "kui-utils"
import { ContractLinkModel } from "kui-crm/types"
import { ClientLiteModel } from "../../../types/api/clients"
import { ClientRoles, PhoneParams } from "../../../types/common"
import { ClientContractParams } from "../../../types/store/user"

class ClientStore {
  id: number

  name: string

  email: string

  avatar: string | null

  phone: PhoneParams | null

  firstName: string

  lastName: string

  middleName: string

  contracts: ClientContractParams[]

  constructor(client: ClientLiteModel) {
    const rentalContracts =
      client.rental_contracts?.map((contract) =>
        ClientStore.getContractParams(contract, "tenant")
      ) || []
    const serviceContracts =
      client.service_contracts?.map((contract) =>
        ClientStore.getContractParams(contract, "landlord")
      ) || []

    this.id = client.id
    this.name = `${client.first_name} ${client.last_name}`
    this.email = client.email
    this.phone = client.phone ? getPhoneParamsFromString(client.phone) : null
    this.firstName = client.first_name
    this.lastName = client.last_name
    this.middleName = client.middle_name
    this.avatar = null
    this.contracts = [...rentalContracts, ...serviceContracts]
    makeAutoObservable(this)
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`
  }

  static getContractParams = (
    contract: ContractLinkModel,
    role: ClientRoles
  ) => ({
    id: contract.id,
    number: contract.number,
    role,
    apartment: {
      ...contract.apartment,
      address: contract.apartment.object_name || contract.apartment.address,
      mainImageURL: contract.apartment?.main_image?.image_small_url || null,
    },
  })
}

export default ClientStore

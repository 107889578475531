import styled from "@emotion/styled"
import AccordionWithSummary from "../AccordionWithSummary"

const AccordionRow = styled(AccordionWithSummary)`
  padding: 0 24px;
  .KUI-Accordion_Summary-Content {
    padding: 14px 0;
    border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
    justify-content: space-between;
  }
`
export default AccordionRow

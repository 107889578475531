import React from "react"
import { observer } from "mobx-react"
import { ModalProps } from "kui-crm"
import { RegistrySearchPanelProps } from "./types"
import TableSearchWithCreating from "../../../../../components/common/TableSearchWithCreating"
import useRegistriesStore from "../../../store"
import {
  ApartmentInsuranceModal,
  ApartmentProxyModal,
} from "../../modals/ApartmentDocumentModal"
import ApartmentServiceModal from "../../modals/ApartmentServiceModal"
import InspectionModal from "../../modals/InspectionModal"
import MeterReadingModal from "../../modals/MeterReadingModal"

const registriesModals = [
  ApartmentServiceModal,
  InspectionModal,
  ApartmentInsuranceModal,
  ApartmentProxyModal,
  MeterReadingModal,
]

const RegistrySearchPanel = (props: RegistrySearchPanelProps) => {
  const { activeTab } = props
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { filter, paginator } = activeRegistryStore
  const RegistryModal = registriesModals[activeTab]

  return (
    <TableSearchWithCreating filter={filter} paginator={paginator}>
      {(modalProps: ModalProps) => <RegistryModal {...modalProps} />}
    </TableSearchWithCreating>
  )
}

export default observer(RegistrySearchPanel)

import { DateTime } from "luxon"
import { MultistepForm } from "kui-utils"
import { CompanyAutocomplete } from "kui-crm"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import DurationBlock from "../DurationBlock"
import ServiceDocumentField from "../ServiceDocumentField"
import { ServiceEditingFields } from "../ServiceEditingForm/types"
import { ApartmentServiceFormStages } from "../../../../store/shared/apartmentService/ApartmentService"

const serviceMainInfoFields = (
  handlePurposeChange: () => void,
  handleDateChange: (newDate: Date) => void,
  setSubmitDisabled: (value: boolean) => void,
  formMaker: MultistepForm<
    ServiceEditingFields,
    typeof ApartmentServiceFormStages
  >,
  companyDisabled: boolean,
  setCompanyDisabled: (disabled: boolean) => void,
  lastOpenPeriod?: DateTime | null,
  withApartment?: boolean,
  apartmentId?: number | null
): InputParams<any>[] => [
  {
    variant: "custom",
    CustomInput: ServiceDocumentField,
    name: "file",
    size: 12,
    withApartment,
    formMaker,
    setCompanyDisabled,
    apartmentId,
  },
  {
    label: "Name",
    testId: "name",
    name: "name",
    size: 12,
    maxLength: 220,
  },
  {
    variant: "custom",
    name: "durationBlock",
    CustomInput: DurationBlock,
    handleDateChange,
    lastOpenPeriod,
    size: 12,
  },
  {
    label: "Company",
    testId: "company",
    size: 12,
    variant: "custom",
    name: "company",
    CustomInput: CompanyAutocomplete,
    forApartment: apartmentId,
    setSubmitDisabled,
    disabled: companyDisabled,
  },
  {
    label: "Payer code",
    name: "payerCode",
    size: 6,
    disabled: true,
  },
  {
    label: "Operational account number",
    name: "communalServicesAccount",
    size: 6,
    disabled: true,
  },
  {
    label: "Price",
    testId: "price",
    name: "price",
    size: 6,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
  },
  {
    label: "Document number",
    testId: "documentNumber",
    name: "documentNumber",
    size: 6,
  },
  {
    label: "Purpose",
    testId: "purpose",
    name: "purpose",
    size: 12,
    type: "number",
    isTextArea: true,
    onChange: handlePurposeChange,
    maxLength: 220,
  },
]

export default serviceMainInfoFields

import React, { SyntheticEvent } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Caption, Button, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { LoaderState, Link } from "kui-crm"
import {
  EditIcon,
  TrashIcon,
  CrossedOutEyeIcon,
  OpenEyeIcon,
  DocumentFilledIcon,
} from "kui-icon"
import { FileWithSettingsRowProps, FileWrapperStylesProps } from "./types"
import EditDocumentModal from "../EditDocumentModal"
import ConfirmationModal from "../ConfirmationModal"

const VisibilityVariants = {
  everybody: "Landlord, Tenant",
  tenant: "Tenant",
  landlord: "Landlord",
}

function FileWithSettingsRow(props: FileWithSettingsRowProps) {
  const {
    file,
    contractType,
    disabled,
    onDelete,
    onChange,
    index,
    requestParams,
    canBeEdited,
    ...otherProps
  } = props

  const [isOpenModal, handleModalOpen, handleModalClose] = useToggle()
  const [isOpenDeleteModal, handleDeleteModalOpen, handleDeleteModalClose] =
    useToggle()
  const isFileCreatedToday =
    Math.abs(Number(file.date?.diffNow(["hours"]).toObject().hours)) < 24
  const createdDate = isFileCreatedToday
    ? file.date?.setLocale("en").toRelative()
    : file.date?.toFormat("dd.MM.yyyy")
  const title = file.name.replace(/\.[^/.]+$/, "")

  const openModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleModalOpen()
  }

  const openConfirmModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (onDelete) onDelete(index)
    else handleDeleteModalOpen()
  }

  const handleDelete = () => {
    if (!onDelete) file.deleteDocument(requestParams)
  }

  return (
    <>
      <FileWrapper
        disabled={!!disabled}
        href={file.url}
        openInNewWindow
        {...otherProps}
      >
        <IconWrapper>
          <DocumentFilledIcon />
        </IconWrapper>
        <ContentWrapper>
          <Grid container alignItems="center" wrap="nowrap">
            <StyledTitle size="s" weight={500}>
              {title}
            </StyledTitle>
            <Grid item>
              {file.visibility && (
                <VisibilityWrapper>
                  {file.visibility === "nobody" ? (
                    <CrossedOutEyeIcon />
                  ) : (
                    <Grid container alignItems="center">
                      <OpenEyeIcon width={14} height={10} />
                      <VisibilityContent size="s" color="fiftyP">
                        {VisibilityVariants[file.visibility]}
                      </VisibilityContent>
                    </Grid>
                  )}
                </VisibilityWrapper>
              )}
            </Grid>
          </Grid>
          <Caption size="xs" color="fourty">
            {file.createdBy} {createdDate}
          </Caption>
        </ContentWrapper>
        {!disabled && canBeEdited && (
          <ButtonWrapper>
            <Button
              isCircle
              data-testid="edit_file_name_btn"
              size="xs"
              variant="transparentLight"
              onClick={openModal}
            >
              <EditIcon width={16} height={16} />
            </Button>
            <Button
              isCircle
              data-testid="delete_file_btn"
              size="xs"
              variant="transparentLight"
              onClick={openConfirmModal}
            >
              <TrashIcon width={17} height={17} />
            </Button>
          </ButtonWrapper>
        )}
      </FileWrapper>
      <EditDocumentModal
        contractType={contractType}
        document={file}
        open={isOpenModal}
        handleClose={handleModalClose}
        handleSubmit={onChange}
        index={index}
        requestParams={requestParams}
      />
      <ConfirmationModal
        open={isOpenDeleteModal}
        handleClose={handleDeleteModalClose}
        handleSubmit={handleDelete}
      />

      <LoaderState loader={file.loader} />
    </>
  )
}

FileWithSettingsRow.defaultProps = {
  canBeEdited: true,
}

export default observer(FileWithSettingsRow)

const FileWrapper = styled(Link)<FileWrapperStylesProps>`
  width: 100%;
  padding: 10px 8px 10px 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  border-top: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
  background: ${({ disabled, theme }) =>
    disabled ? theme.palette.background.light1 : theme.palette.grey.zero};
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  & + div {
    border-top: none;
  }
`

const ButtonWrapper = styled.div`
  margin-left: auto;
  padding-left: 8px;
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.grey.thirty};
    }
  }
`

const ContentWrapper = styled.div`
  width: calc(100% - 122px);
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const IconWrapper = styled.div`
  padding-right: 10px;
`

const VisibilityWrapper = styled.div`
  padding-left: 14px;
  width: max-content;
`

const VisibilityContent = styled(Caption)`
  padding-left: 5px;
`

const StyledTitle = styled(Caption)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { Loader, MultistepForm, resHandler } from "kui-utils"
import ApartmentServiceStore, {
  ApartmentServiceFormStages,
} from "../../../../../../store/shared/apartmentService/ApartmentService"
import ApartmentServicesAgent from "../../../../../../agent/ApartmentServices"
import { ApprovalServicesRequest } from "../../types/api/expensesServicesAPI"
import { ServiceCreationFields } from "../../../../../../components/forms/apartmentExpenses/ServiceCreationForm/types"
import { matchesServiceTypes } from "../../../../../../utils/content/matches"
import { ApartmentServicesVariants } from "../../../../types/store/apartment"
import { ServiceEditingFields } from "../../../../../../components/forms/apartmentExpenses/ServiceEditingForm/types"
import ApartmentExpensesStore from "../ApartmentExpenses"
import ApartmentLiteServicesStore from "../../../../../../store/shared/apartmentService/ApartmentServicesStore"
import {
  ServiceModel,
  ServicesAPITypes,
  ServicesResponse,
} from "../../../../../../types/api/apartmentExpenses"
import { uploadNewFile } from "../../../../../../utils/agent/uploadFiles"

class ApartmentServicesStore {
  openEndedServices: ApartmentServiceStore[]

  periodServices: ApartmentServiceStore[]

  onceServices: ApartmentServiceStore[]

  autoServices: ApartmentServiceStore[]

  ownServices: ApartmentServiceStore[]

  pendingServices: ApartmentServiceStore[]

  loader: Loader

  actionLoader: Loader

  creationForm: MultistepForm<
    ServiceEditingFields,
    typeof ApartmentServiceFormStages
  >

  expensesStore: ApartmentExpensesStore

  constructor(expensesStore: ApartmentExpensesStore) {
    this.openEndedServices = []
    this.periodServices = []
    this.onceServices = []
    this.autoServices = []
    this.ownServices = []
    this.pendingServices = []
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.creationForm = new MultistepForm<
      ServiceEditingFields,
      typeof ApartmentServiceFormStages
    >()
    this.expensesStore = expensesStore
    makeAutoObservable(this)
  }

  initServicesTab = async (
    apartmentId: number,
    contractId: number,
    periodId: number
  ) => {
    this.loader.startLoading()

    await this.fetchServices(apartmentId, contractId, periodId)
    if (!this.expensesStore.periodStore.isClosed) {
      await this.fetchPendingServices(apartmentId)
    }

    this.loader.endLoading()
  }

  fetchServices = async (
    apartmentId: number,
    contractId: number,
    periodId: number
  ) => {
    this.loader.cleanError()

    const [err, res] = await to<ServicesResponse>(
      ApartmentServicesAgent.getByApartmentId(apartmentId, contractId, periodId)
    )

    if (res && !err) {
      this.updateServices(res)
    } else {
      this.loader.setError("fetch services", err)
    }
  }

  actualizePendingServices = async (apartmentId: number) => {
    this.actionLoader.startLoading("pending services")

    await this.fetchPendingServices(apartmentId)

    this.actionLoader.endLoading()
  }

  fetchPendingServices = async (apartmentId: number) => {
    const response = await to<ServiceModel[]>(
      ApartmentServicesAgent.getPendingServices(apartmentId)
    )

    resHandler(response, this.loader, this.updatePendingServices)
  }

  approveServices = async (apartmentId: number, periodId: number) => {
    this.actionLoader.startLoading("services approval")

    const body: ApprovalServicesRequest = {
      approval_action: "approve",
    }

    const [err] = await to(
      ApartmentServicesAgent.approvalServices(apartmentId, periodId, body)
    )

    if (err) {
      this.actionLoader.setError("services approve", err)
    }
    this.actionLoader.endLoading()
  }

  createService = async (data: ServiceCreationFields) => {
    const apartmentId = this.expensesStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("service creation")

      const file = await uploadNewFile(this.actionLoader, data.file)
      const body = ApartmentLiteServicesStore.getPostServiceBody(data, file)

      const [err, res] = await to<ServiceModel>(
        ApartmentServicesAgent.create(apartmentId, body)
      )
      runInAction(() => {
        if (res && !err) {
          if (
            data.chargeFrom &&
            this.expensesStore.date?.toISODate() ===
              DateTime.fromJSDate(data.chargeFrom).toISODate()
          ) {
            this.addService(res, data)
          }
          this.actualizePendingServices(apartmentId)
        } else {
          this.actionLoader.setError("service creation", err)
        }
        this.actionLoader.endLoading()
      })
    }
  }

  duplicateService = async (apartmentId: number, serviceId: number) => {
    this.actionLoader.startLoading("service duplication")

    const response = await to<ServiceModel>(
      ApartmentServicesAgent.duplicateService(apartmentId, serviceId)
    )

    resHandler(response, this.actionLoader, (res) => {
      if (!this.expensesStore.periodStore.isClosed) {
        this.addService(res)
      }
    })
  }

  deleteService = (id: number, type: ServicesAPITypes) => {
    const servicesKey =
      `${matchesServiceTypes[type]}Services` as ApartmentServicesVariants
    this[servicesKey] =
      this[servicesKey]?.filter((service) => service.id !== id) || []
  }

  updateServices = (data: ServicesResponse) => {
    const apartmentId = this.expensesStore.overviewStore.id
    this.openEndedServices = data.open_ended.map(
      (service) => new ApartmentServiceStore(this, service, apartmentId)
    )
    this.periodServices = data.period.map(
      (service) => new ApartmentServiceStore(this, service, apartmentId)
    )
    this.onceServices = data.once.map(
      (service) => new ApartmentServiceStore(this, service, apartmentId)
    )
    this.autoServices = data.auto.map(
      (service) => new ApartmentServiceStore(this, service, apartmentId)
    )
    this.ownServices = data.operating.map(
      (service) => new ApartmentServiceStore(this, service, apartmentId)
    )
  }

  updatePendingServices = (services: ServiceModel[]) => {
    const apartmentId = this.expensesStore.overviewStore.id
    this.pendingServices = services.map(
      (service) =>
        new ApartmentServiceStore(this, service, apartmentId, "pending")
    )
  }

  addService = (res: ServiceModel, data?: ServiceCreationFields) => {
    const defaultApartmentId = this.expensesStore.overviewStore.id
    const servicesKey = `${
      matchesServiceTypes[res.line_type]
    }Services` as ApartmentServicesVariants
    const newService = new ApartmentServiceStore(this, res, defaultApartmentId)

    this[servicesKey] = [newService, ...this[servicesKey]]
  }

  cleanServicesTab = () => {
    this.openEndedServices = []
    this.periodServices = []
    this.onceServices = []
    this.creationForm = new MultistepForm<
      ServiceEditingFields,
      typeof ApartmentServiceFormStages
    >()
    this.loader.endLoading()
  }
}

export default ApartmentServicesStore

import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import ClientTaxFields from "../../components/tabs/ClientEmployment/ClientTaxFields"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import { UserEmploymentFields } from "./types"
import ClientEmploymentSchema from "./schema"
import useClientStore from "../../store"

function ClientEmploymentForm() {
  const { employmentStore, overviewStore, editor } = useClientStore()
  const form = useForm<UserEmploymentFields>({
    defaultValues: {
      ...employmentStore,
      startDate: employmentStore.startDate?.toJSDate(),
      documents: employmentStore.documentsStore.documents,
    },
    resolver: yupResolver(ClientEmploymentSchema),
  })

  const handleSubmit = (data: UserEmploymentFields) => {
    if (overviewStore?.id) {
      employmentStore.patchClientEmploymentInfo(overviewStore.id, data)
      editor?.endEditing()
    }
  }

  useEffect(() => {
    if (editor?.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor?.blockToSendForm)()
    }
  }, [editor?.isAllowedToSendForm])

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <ClientTaxFields form={form} />
      </Grid>
      <Grid item xs={4}>
        <DocumentsBlockWithForm
          form={form}
          disabled={!editor.isEditing}
          documents={employmentStore.documentsStore.documents}
          loader={employmentStore.documentsStore.loader}
        />
      </Grid>
    </Grid>
  )
}

export default observer(ClientEmploymentForm)

import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { MultistepForm, Loader } from "kui-utils"
import { MeterFeaturesFormFields } from "kui-crm"
import { WaterMeterParams, WaterMetersResponse } from "kui-crm/types"
import ApartmentMetersAgent from "../../../../../../agent/ApartmentMeters"
import WaterMetersStore from "../../../../../MetersPage/store/WaterMetersStore"
import { WaterMeterCreationFields } from "../../../../../../components/forms/meters/MeterCreationFormFields/types"
import { PostWaterMeterRequest } from "../../../../../../types/api/meters"
import SingleMeterStore from "./SingleMeterStore"
import ApartmentExpensesStore from "../ApartmentExpenses"

class ApartmentWaterMetersStore {
  waterMeters: SingleMeterStore[]

  selectedMeterCounter: SingleMeterStore | null

  creationForm: MultistepForm<any>

  maxMetersCount: number | null

  loader: Loader

  actionLoader: Loader

  expensesStore: ApartmentExpensesStore

  total: number | null

  constructor(expensesStore: ApartmentExpensesStore) {
    this.waterMeters = []
    this.selectedMeterCounter = null
    this.creationForm = new MultistepForm<any>()
    this.maxMetersCount = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore
    this.total = null
    makeAutoObservable(this)
  }

  getWaterMeters = async (
    apartment_id: number,
    date: DateTime,
    contractId?: number
  ) => {
    this.loader.startLoading()

    const [err, res] = await to<WaterMetersResponse>(
      ApartmentMetersAgent.getMeters(
        apartment_id,
        "water",
        date.year,
        date.month,
        contractId
      )
    )

    runInAction(() => {
      if (!err && res) {
        this.total = Number(res.total_cost)
        this.waterMeters = res.meters.map(
          (counter) => new SingleMeterStore(counter, "water", this)
        )
        if (res.sewerage) {
          this.waterMeters = [
            ...this.waterMeters,
            new SingleMeterStore(res.sewerage, "water", this),
          ]
        }
      } else {
        this.loader.setError("fetch water meters", err)
      }
      this.loader.endLoading()
    })
  }

  postMeter = async (apartmentId: number, data: MeterFeaturesFormFields) => {
    this.actionLoader.startLoading("water meter creation")

    const body = {
      ...WaterMetersStore.getCreationBody(data as WaterMeterCreationFields),
      apartment: apartmentId,
    } as PostWaterMeterRequest

    const [err, res] = await to<WaterMeterParams>(
      ApartmentMetersAgent.postWaterMeter(body)
    )

    runInAction(() => {
      if (!err && res) {
        const defaultValues = SingleMeterStore.getDefaultValues(res, data)
        this.waterMeters.unshift(
          new SingleMeterStore(defaultValues, "water", this)
        )
      } else {
        this.actionLoader.setError("water meter creation", err)
      }
      this.actionLoader.endLoading()
    })
  }

  updateTotalPrice = (
    total: number,
    seweragePrice?: number,
    sewerageConsumption?: number
  ) => {
    this.total = total
    if (seweragePrice && sewerageConsumption) {
      this.waterMeters
        .find((meter) => meter.type === "sewerage")
        ?.updateTotalPrice(seweragePrice, sewerageConsumption)
    }
  }

  archiveById = (id: number) => {
    this.waterMeters.find((counter) => counter.id === id)?.setArchived()
  }

  getWaterMeterById = async (id: number) => {
    this.selectedMeterCounter =
      this.waterMeters.find((counter) => counter.id === id) || null
  }

  updateCountersCount = (count: number | null) => {
    this.maxMetersCount = count
  }

  updateOperatingNumber = (operatingNumber: string) => {
    this.waterMeters.forEach((meter) => {
      meter.updateOperatingNumber(operatingNumber)
    })
  }

  get activeWaterMeters() {
    return this.waterMeters.filter((counter) => !counter.isArchived)
  }

  get archiveWaterMeters() {
    return this.waterMeters.filter((counter) => counter.isArchived)
  }

  static getDefaultValues = (
    res: WaterMeterParams,
    data: MeterFeaturesFormFields
  ) => ({
    ...res,
    previous_value: data.initialValue!.toString(),
    initial_value: data.initialValue!.toString(),
    edit_date: DateTime.now().toISODate() || "",
    counter_value: data.initialValue!.toString(),
    consumption: 0,
    payer: {
      ...res.payer,
      payer_type: data.meterPayer?.mainPayer,
    },
  })
}

export default ApartmentWaterMetersStore

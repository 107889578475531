import React from "react"
import { useForm } from "react-hook-form"
import { Box } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { DateTime } from "luxon"
import { FormWrapper, InputFileWithVisibility } from "kui-crm"
import { EditContractFormFields, EditContractFormProps } from "./types"
import EditContractFormSchema from "./schema"
import InputWithDatePicker from "../../../ui/InputWithDatepicker"

const EditContractForm = (props: EditContractFormProps) => {
  const { handleSubmit, usedDates, lastOpenPeriod } = props
  const form = useForm<EditContractFormFields>({
    resolver: yupResolver(EditContractFormSchema),
  })

  const handleDateChange = (date: DateTime) => {
    form.clearErrors("date")
    const dateNotValid = !!usedDates.find(
      (usedDate) =>
        DateTime.fromJSDate(usedDate).toFormat("MM.yyyy") ===
        date.toFormat("MM.yyyy")
    )
    if (dateNotValid) {
      form.setError("date", {
        message: "A change has already been created for this period",
      })
    }
  }

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Start editing">
      <Box mb={2}>
        <InputWithDatePicker
          label="Date"
          name="date"
          form={form}
          onChange={handleDateChange}
          datePickerProps={{
            minDate: lastOpenPeriod?.toJSDate() || new Date(),
          }}
        />
      </Box>
      <InputFileWithVisibility label="Document" name="file" form={form} />
    </FormWrapper>
  )
}

export default EditContractForm

import React from "react"
import styled from "@emotion/styled"
import { GreyColors, Button } from "kui-basic"
import { OpenIcon } from "kui-icon"
import { Link } from "kui-crm"
import { LinkButtonProps, LinkButtonStylesProps } from "./types"

function LinkButton({ children, href, labelColor, ...other }: LinkButtonProps) {
  return (
    <Link href={href}>
      <StyledButton
        size="xs"
        variant="backgroundWithBorder"
        endIcon={<OpenIcon />}
        labelColor={labelColor}
        {...other}
      >
        {children}
      </StyledButton>
    </Link>
  )
}

LinkButton.defaultProps = {
  labelColor: "seventy" as const,
}

export default LinkButton

const StyledButton = styled(Button)<LinkButtonStylesProps>`
  min-width: 104px !important;
  padding: 0 8px 0 12px !important;
  justify-content: space-between;
  p {
    color: ${({ theme, labelColor }) =>
      theme.palette.grey[labelColor as keyof GreyColors]} !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    font-weight: 500;
  }
  span {
    margin-left: 4px;
    padding-right: 4px;
  }
`

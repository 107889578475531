import React from "react"
import { Grid, IconButton } from "kui-basic"
import styled from "@emotion/styled"
import { CloseIcon } from "kui-icon"
import { Link, PreviewContent } from "kui-crm"
import { DashboardListRowProps } from "./types"

const DashboardListRow = (props: DashboardListRowProps) => {
  const { logo, label, description, link, icon, onClick, className } = props

  return (
    <StyledWrapper
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
      className={className}
    >
      <StyledLink href={link}>
        <StyledPreviewContent
          imageSrc={logo}
          description={label}
          label={description}
        />
      </StyledLink>
      <IconButton onClick={onClick}>
        {icon || <CloseIcon width={12} height={12} />}
      </IconButton>
    </StyledWrapper>
  )
}

export default DashboardListRow

const StyledWrapper = styled(Grid)`
  padding: 8px 4px 8px 0;
  &:not(&:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledLink = styled(Link)`
  max-width: calc(100% - 32px);
  > div {
    width: auto;
  }
`

const StyledPreviewContent = styled(PreviewContent)`
  p {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

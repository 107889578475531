import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { callPromises, Loader } from "kui-utils"
import { ImageBodyRequest } from "kui-crm/types"
import { RegularInspectionModel } from "../../types/api/regularInspection"
import { RoomImagesParams } from "../../types/store/inspection"
import InspectionPageStore from "../InspectionPageStore"
import { PatchRoomImagesParams } from "../../types/api/common"
import { RegularInspectionFormFields } from "../../variants/RegularInspectionInfo/types"
import { InspectionPartialMetersInfoParams } from "../../types/store/transferInspection"
import TransferInspectionStore from "./TransferInspectionStore"

class RegularInspectionStore {
  id: number

  date: DateTime | null

  dropboxLink: string

  roomsImages: RoomImagesParams[]

  metersInfo: InspectionPartialMetersInfoParams

  loader: Loader

  inspectionStore: InspectionPageStore

  impairmentsInspectionId: number | null

  appraisalInspectionId: number | null

  constructor(
    inspection: RegularInspectionModel,
    inspectionStore: InspectionPageStore
  ) {
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.roomsImages = InspectionPageStore.getObjectRoomsImages(
      inspection.photo_groups
    )
    this.metersInfo = TransferInspectionStore.getTransferMetersInfo(
      inspection.regular.meters
    )
    this.loader = new Loader()
    this.inspectionStore = inspectionStore
    this.dropboxLink = inspection.dropbox_link || ""
    this.impairmentsInspectionId = inspection.impairment_id || null
    this.appraisalInspectionId = inspection.appraisal_id || null
    makeAutoObservable(this)
  }

  patchInspection = async (data: RegularInspectionFormFields) => {
    const metersImages = data.meters?.map((meter) => meter.images || []) || []
    const files = await callPromises([
      this.inspectionStore.actualizeImages(metersImages),
      this.inspectionStore.actualizeRoomsImages(data.roomsImages),
    ])

    return RegularInspectionStore.getPatchInspectionBody(
      data,
      files[0],
      files[1]
    )
  }

  static getPatchInspectionBody = (
    inspection: RegularInspectionFormFields,
    roomsImages: PatchRoomImagesParams[],
    metersImages: ImageBodyRequest[][]
  ) => ({
    dropbox_link: inspection.dropboxLink,
    photo_groups: roomsImages,
    regular: TransferInspectionStore.getInspectionMetersPatchFields(
      inspection.meters,
      metersImages
    ),
  })
}

export default RegularInspectionStore

import * as React from "react"
import { useEffect, useRef } from "react"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { LockerParams } from "kui-crm/types"
import InputWithAutocomplete from "../../ui/InputWithAutocomplete"
import { LockerAutocompleteProps } from "./types"
import AutocompleteOption from "../../common/AutocompleteOption"
import LockersStore from "../../../store/lites/LockersStore"
import LockerModal from "./LockerModal"

function LockerAutocomplete<TFormValues extends object>(
  props: LockerAutocompleteProps<TFormValues>
) {
  const { form, name, label, ...otherProps } = props
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const selectedLocker = useRef<LockerParams | null>()
  const lockersStoreRef = useRef(new LockersStore())
  const inputRef = useRef(null)

  const { lockers, fetchLockers, loader } = lockersStoreRef.current
  const { isLoading } = loader

  const onSelectItem = (item: LockerParams | null) => {
    selectedLocker.current = item
    if (item?.apartment) handleModalOpen()
    else changeLocker()
  }

  const changeLocker = () => {
    form.setValue(`${name}.id`, selectedLocker.current?.id)
    form.setValue(`${name}.name`, selectedLocker.current?.name, {
      shouldValidate: true,
    })
  }

  useEffect(() => {
    fetchLockers()
  }, [])

  return (
    <>
      <InputWithAutocomplete
        renderOption={(option: LockerParams) => (
          <AutocompleteOption label={option.name} />
        )}
        isLoading={isLoading}
        options={lockers}
        label={label}
        form={form}
        name={`${name}.name`}
        hideName={`${name}.id`}
        onSelectItem={onSelectItem}
        inputRef={inputRef}
        {...otherProps}
      />
      <LockerModal
        open={isModalOpen}
        handleClose={handleModalClose}
        handleSubmit={changeLocker}
        apartment={selectedLocker.current?.apartment}
      />
    </>
  )
}

LockerAutocomplete.defaultProps = {
  label: "Locker",
  name: "locker",
}

export default observer(LockerAutocomplete)

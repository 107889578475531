import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, Editor } from "kui-utils"
import { ResourceTypes } from "kui-crm/types"
import TariffAgent from "../../../agent/Tariff"
import HostStore from "../../../store/Root"
import TariffOverviewStore from "./TariffOverviewStore"
import TariffObjectsStore from "./TariffObjectsStore"
import TariffCompaniesStore from "./TariffCompaniesStore"

class TariffPageStore {
  loader: Loader

  actionLoader: Loader

  editor: Editor

  overviewStore: TariffOverviewStore

  objectsStore: TariffObjectsStore

  companiesStore: TariffCompaniesStore

  resource: ResourceTypes | null

  private hostStore: HostStore

  constructor(hostStore: HostStore) {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = new TariffOverviewStore()
    this.objectsStore = new TariffObjectsStore()
    this.companiesStore = new TariffCompaniesStore()
    this.hostStore = hostStore
    this.resource = null
    makeAutoObservable(this)
  }

  fetchTariffById = async (id: number, resource: ResourceTypes) => {
    this.resource = resource

    this.loader.startLoading()

    const [err, res] = await to(TariffAgent.getById(id, resource))

    if (res && !err) {
      this.overviewStore.updateTariffOverview(res, resource)
    } else {
      this.loader.setError("fetch tariff", err)
    }
    this.loader.endLoading()
  }

  deleteTariff = async (id: number) => {
    this.actionLoader.startLoading("tariff removal")

    const [err] = await to(TariffAgent.delete(id, this.resource!))

    if (err) {
      this.actionLoader.setError("tariff removal", err)
    }

    this.actionLoader.endLoading()
  }

  updateTariffStore = () => {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = new TariffOverviewStore()
    this.objectsStore = new TariffObjectsStore()
    this.companiesStore = new TariffCompaniesStore()
    this.resource = null
  }
}

export default TariffPageStore

import * as React from "react"
import ClientPersonalInfoTab from "../ClientPersonalInfoTab"
import ClientCompanyInfoTab from "../ClientCompanyInfoTab"
import ClientEmployment from "../ClientEmployment"
import ClientBankAcc from "../ClientBankAcc"

const clientDataTabContent = {
  personal: {
    paths: [
      `?tab=data&subtab=info`,
      `?tab=data&subtab=employment`,
      `?tab=data&subtab=bank`,
    ],
    tabs: ["Personal", "Employment", "Bank Acc"],
    tabPanels: [
      <ClientPersonalInfoTab />,
      <ClientEmployment />,
      <ClientBankAcc />,
    ],
  },
  company: {
    paths: [`?tab=data&subtab=info`, `?tab=data&subtab=bank`],
    tabs: ["Contact", "Bank Acc"],
    tabPanels: [<ClientCompanyInfoTab />, <ClientBankAcc />],
  },
}

export default clientDataTabContent

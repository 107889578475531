import React from "react"
import { observer } from "mobx-react"
import { ApartmentInspectionsMapProps } from "./types"
import MapWrapper from "../../../../../components/common/MapWrapper"
import InspectionsMap from "./InspectionsMap"

const ApartmentInspectionsMap = (props: ApartmentInspectionsMapProps) => (
  <MapWrapper>
    <InspectionsMap {...props} />
  </MapWrapper>
)

export default observer(ApartmentInspectionsMap)

import React from "react"
import { Button, Caption, Grid, IconButton } from "kui-basic"
import styled from "@emotion/styled"
import { CloseIcon, HalfArrowDownIcon } from "kui-icon"
import { ChangeLineSummaryProps, ExpandButtonStylesProps } from "./types"
import FileLink from "../../../FileLink"

const ChangeLineSummary = (props: ChangeLineSummaryProps) => {
  const { expanded, changesGroup, variant, onDelete } = props

  const handleDelete = () => {
    if (onDelete) onDelete(changesGroup.id)
  }

  return (
    <StyledLine container alignItems="center" wrap="nowrap">
      <Grid container direction="column">
        <Grid container alignItems="center">
          <StyledCaption size="s" weight={600}>
            {changesGroup.dateOfChange?.toFormat("MM.yyyy")}
          </StyledCaption>
          {changesGroup.file && (
            <>
              <StyledCaption size="s" weight={500}>
                based on
              </StyledCaption>
              <StyledFileLink
                label={changesGroup.file.name}
                link={changesGroup.file.url}
                color="sixty"
                size="s"
              />
            </>
          )}
          <StyledExpandButton expanded={!!expanded}>
            <HalfArrowDownIcon width={14} height={16} />
          </StyledExpandButton>
        </Grid>

        <Caption size="xs" weight={500} color="fiftyP">
          Created{" "}
          <StyledAccent>
            {changesGroup.createdDate?.toFormat("dd.MM.yyyy")}
          </StyledAccent>{" "}
          by <StyledAccent>{changesGroup.createdBy}</StyledAccent>
        </Caption>
      </Grid>

      {variant === "pending" && (
        <StyledDeleteButton
          isCircle
          size="xs"
          variant="transparent"
          onClick={handleDelete}
        >
          <CloseIcon />
        </StyledDeleteButton>
      )}
    </StyledLine>
  )
}

export default ChangeLineSummary

const StyledCaption = styled(Caption)`
  margin-right: 6px;
`

const StyledExpandButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<ExpandButtonStylesProps>`
  margin-left: 20px;
  svg {
    transition: all ease-out 0.3s;
    transform: rotate(${({ expanded }) => (expanded ? "180deg" : "0deg")});
  }
`

const StyledDeleteButton = styled(Button)`
  margin-left: auto;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledFileLink = styled(FileLink)`
  p {
    max-width: 598px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledLine = styled(Grid)`
  height: 60px;
`

const StyledAccent = styled.span`
  color: ${({ theme }) => theme.palette.grey.sixty};
`

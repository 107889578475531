import { useEffect, useState } from "react"

const useDashboardFilter = (filter: string, fetchAll?: () => Promise<void>) => {
  const [isFirstMount, setIsFirstMount] = useState(true)

  useEffect(() => {
    setIsFirstMount(false)
  }, [filter])

  useEffect(() => {
    if (!isFirstMount && fetchAll) fetchAll()
  }, [filter])
}

export default useDashboardFilter

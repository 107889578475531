import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { ApartmentDocumentModalProps } from "./types"
import ApartmentDocumentForm from "../../forms/ApartmentDocumentForm"
import { ApartmentDocumentFormFields } from "../../forms/ApartmentDocumentForm/types"
import useRegistriesStore from "../../../store"
import ApartmentDocumentsRegistryStore from "../../../store/variants/ApartmentDocumentsRegistryStore"

const ApartmentDocumentModal = (props: ApartmentDocumentModalProps) => {
  const { open, handleClose, variant } = props
  const { activeRegistryStore } = useRegistriesStore()
  const documentsStore = activeRegistryStore as ApartmentDocumentsRegistryStore

  const handleSubmit = (data: ApartmentDocumentFormFields) => {
    documentsStore.createApartmentDocument(data)
    handleClose()
  }

  return (
    <Modal title={`New ${variant}`} open={open} handleClose={handleClose}>
      <ApartmentDocumentForm variant={variant} handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(ApartmentDocumentModal)

export const ApartmentInsuranceModal = (
  props: Omit<ApartmentDocumentModalProps, "variant">
) => <ApartmentDocumentModal variant="insurance" {...props} />

export const ApartmentProxyModal = (
  props: Omit<ApartmentDocumentModalProps, "variant">
) => <ApartmentDocumentModal variant="proxy" {...props} />

import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import { callPromises } from "kui-utils"
import { FileBodyRequest } from "kui-crm/types"
import {
  AppraisalOfferParams,
  ComparableObjectParams,
  StatisticalEvaluationParams,
} from "../../types/store/appraisalInspection"
import {
  AppraisalInspectionFields,
  AppraisalInspectionModel,
  ComparableObjectModel,
  StatisticalEvaluationModel,
} from "../../types/api/appraisalInspection"
import { InspectionBasedOnParams } from "../../../../types/store/apartmentInspections"
import FileStore from "../../../../../../store/templates/File"
import { AppraisalInspectionFormFields } from "../../variants/AppraisalInspectionInfo/types"
import InspectionPageStore from "../InspectionPageStore"
import ApartmentInspectionsLiteStore from "../../../../../../store/lites/ApartmentInspectionsLiteStore"

class AppraisalInspectionStore {
  id: number

  date: DateTime | null

  basedOn: InspectionBasedOnParams | null

  dropboxLink: string

  comparableObjects: ComparableObjectParams[]

  statisticalEvaluations: StatisticalEvaluationParams[]

  appraisalOffer: AppraisalOfferParams

  inspectionStore: InspectionPageStore

  constructor(
    inspection: AppraisalInspectionModel,
    inspectionStore: InspectionPageStore
  ) {
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.basedOn = InspectionPageStore.getBasedOnInspection(inspection.based_on)
    this.comparableObjects = AppraisalInspectionStore.getComparableObjects(
      inspection.appraisal.comparable_objects
    )
    this.statisticalEvaluations =
      AppraisalInspectionStore.getStatisticalEvaluations(
        inspection.appraisal.statistical_valuations
      )
    this.appraisalOffer = AppraisalInspectionStore.getAppraisalOffer(
      inspection.appraisal
    )
    this.inspectionStore = inspectionStore
    this.dropboxLink = inspection.dropbox_link || ""
    makeAutoObservable(this)
  }

  updateInspectionInfo = (inspection: AppraisalInspectionModel) => {
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.basedOn = InspectionPageStore.getBasedOnInspection(inspection.based_on)
    this.comparableObjects = AppraisalInspectionStore.getComparableObjects(
      inspection.appraisal.comparable_objects
    )
    this.statisticalEvaluations =
      AppraisalInspectionStore.getStatisticalEvaluations(
        inspection.appraisal.statistical_valuations
      )
    this.appraisalOffer = AppraisalInspectionStore.getAppraisalOffer(
      inspection.appraisal
    )
  }

  patchInspection = async (data: AppraisalInspectionFormFields) => {
    const objectFiles = data.comparableObjects.map(
      (object) => object.screenshot || null
    )
    const valuationsFiles = data.statisticalValuations.map(
      (valuation) => valuation.screenshot || null
    )
    const files = await callPromises([
      this.inspectionStore.actualizeFiles(objectFiles),
      this.inspectionStore.actualizeFiles(valuationsFiles),
    ])

    return AppraisalInspectionStore.getPatchInspectionBody(
      data,
      files[0],
      files[1]
    )
  }

  static getPatchInspectionBody = (
    inspection: AppraisalInspectionFormFields,
    objectsFiles: (FileBodyRequest | null)[],
    valuationsFiles: (FileBodyRequest | null)[]
  ) => ({
    dropbox_link: inspection.dropboxLink,
    appraisal: {
      free_of_charge: inspection.managerValuation.freeServices,
      examples_of_tours: inspection.managerValuation.examplesOfTours,
      ...ApartmentInspectionsLiteStore.getAppraisalInspectionFields(
        { ...inspection, ...inspection.managerValuation },
        objectsFiles,
        valuationsFiles
      ),
    },
  })

  static getComparableObjects = (
    objects: ComparableObjectModel[]
  ): ComparableObjectParams[] =>
    objects?.map((object) => ({
      id: object.id,
      link: object.advertisement_link,
      differences: object.key_object_differences,
      price: Number(object.object_price),
      screenshot: object.advertisement_screenshot
        ? FileStore.initFromFileModel(object.advertisement_screenshot)
        : null,
    })) || []

  static getStatisticalEvaluations = (
    evaluations: StatisticalEvaluationModel[]
  ): StatisticalEvaluationParams[] =>
    evaluations?.map((evaluation) => ({
      ...evaluation,
      minPrice: Number(evaluation.min_price),
      maxPrice: Number(evaluation.max_price),
      dataSourceName: evaluation.source_name,
      screenshot: evaluation.screenshot
        ? FileStore.initFromFileModel(evaluation.screenshot)
        : null,
    })) || []

  static getAppraisalOffer = (
    inspection: AppraisalInspectionFields
  ): AppraisalOfferParams => ({
    ...inspection,
    minPrice: Number(inspection.min_price),
    maxPrice: Number(inspection.max_price),
    advertisingPeriod: Number(inspection.advertising_period),
    ownerWishes: inspection.owner_wishes,
    freeServices: inspection.free_of_charge,
    examplesOfTours: inspection.examples_of_tours,
  })
}

export default AppraisalInspectionStore

import { ObjectType } from "kui-crm/types"
import { useLocation } from "react-router"
import { EntityInterface, TypeWithId } from "../../types/common"

export const jestLocaleString = (num: number) =>
  num.toLocaleString().replace(" ", " ")

export const callCRUDBasedOnFormActions = async (
  initialData: EntityInterface[],
  formData: TypeWithId[],
  create: (elemFields: any, ...params: any) => Promise<any>,
  ...params: any
) => {
  await Promise.allSettled([
    ...initialData.map((elemStore) => {
      const elemFields = formData.find((elem) => elem.id === elemStore.id)

      if (!elemFields) return elemStore.delete(...params)
      return elemStore.patch(elemFields, ...params)
    }),
    ...formData.map((elemFields) => {
      if (!elemFields.id) {
        return create(elemFields, ...params)
      }
      return null
    }),
  ])
}

export const getQueryParam = (key: string) => {
  useLocation()
  return new URLSearchParams(
    window.history.state?.route || document.location.search
  ).get(key)
}

export const updateQueryParams = (newLink: string) => {
  const searchQuery = new URLSearchParams(window.location.search)
  const hrefLinkParams = newLink.split(/\?|&/)
  const hrefParams = Object.fromEntries(
    new URLSearchParams(hrefLinkParams.slice(1).join("&")).entries()
  )
  const searchParams = Object.fromEntries(searchQuery.entries())
  let route = searchQuery.size ? hrefLinkParams[0] : window.location.pathname

  Object.keys(searchParams).forEach((key, index) => {
    if (key in hrefParams) {
      searchParams[key] = hrefParams[key]
      delete hrefParams[key]
    }
    if (key !== "id") {
      route += `${index === 0 ? "?" : "&"}${key}=${searchParams[key]}`
    }
  })

  Object.keys(hrefParams).forEach((key) => {
    route += `${searchQuery.size ? "&" : "?"}${key}=${hrefParams[key]}`
  })

  window.history.pushState({ route }, "", route)
}

export const normalizeObject = (object: ObjectType) => {
  const result: ObjectType = {}

  Object.keys(object).forEach((key) => {
    const keyParts = key.split(".")
    let current = result

    if (keyParts.length > 1) {
      keyParts.forEach((keyPart, index) => {
        const isArray = !Number.isNaN(Number(keyParts[index + 1]))

        if (isArray) {
          if (!Array.isArray(current[keyPart])) current[keyPart] = []
          current = current[keyPart]
        } else if (index === keyParts.length - 1) {
          current[keyPart] = object[key]
        } else {
          current[keyPart] = current[keyPart] || {}
          current = current[keyPart]
        }
      })
    } else {
      result[key] = object[key]
    }
  })

  return result
}

export const mergeObjectsWithSameKeys = (input: ObjectType[]) => {
  const map: ObjectType = {}

  input.forEach((object) => {
    const objectKey = Object.keys(object)[0]
    if (map[objectKey]) {
      if (typeof map[objectKey] === "object") {
        map[objectKey] = Object.assign(map[objectKey], object[objectKey])
      } else {
        map[objectKey] = object[objectKey]
      }
    } else {
      map[objectKey] = object[objectKey]
    }
  })

  const result: ObjectType[] = []
  Object.keys(map).forEach((key) => {
    const obj: ObjectType = {}
    obj[key] = map[key]
    result.push(obj)
  })
  return result
}

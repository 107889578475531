import React from "react"
import { Tooltip } from "kui-crm"
import { PopupWithCheckListProps } from "./types"
import CheckList from "./CheckList"

const PopupWithCheckList = (props: PopupWithCheckListProps) => {
  const { button, popupActive, ...other } = props

  if (!popupActive) return button

  return (
    <Tooltip placement="bottom" content={<CheckList {...other} />}>
      {button}
    </Tooltip>
  )
}

export default PopupWithCheckList

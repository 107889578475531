import { DateTime } from "luxon"
import { callPromises } from "kui-utils"
import {
  inspectionsRequestBody,
  TransferInspectionTypes,
  InspectionMeterFields,
} from "kui-crm_actions"
import { makeAutoObservable } from "mobx"
import { FileBodyRequest, ImageBodyRequest, ResourceTypes } from "kui-crm/types"
import {
  TransferInspectionMeterModel,
  TransferInspectionModel,
} from "../../types/api/transferInspection"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import { ApartmentDescriptionParams } from "../../../../../../types/store/apartments"
import { InspectionPartialMetersInfoParams } from "../../types/store/transferInspection"
import { RoomImagesParams } from "../../types/store/inspection"
import InspectionPageStore from "../InspectionPageStore"
import InventoryInspectionStore from "./InventoryInspectionStore"
import { TransferInspectionInfoFields } from "../../variants/TransferInspectionInfo/types"
import { PatchRoomImagesParams } from "../../types/api/common"
import { FillingParams } from "../../../../../../components/forms/apartmentFilling/FillingList/ApartmentFillingCard/types"
import { clearNotValidFields } from "../../../../../../utils/service/mapper"
import ImageStore from "../../../../../../store/templates/ImageStore"
import { InventoryInspectionMeterModel } from "../../types/api/inventoryInspection"
import ApartmentInspectionsLiteStore from "../../../../../../store/lites/ApartmentInspectionsLiteStore"

class TransferInspectionStore {
  id: number

  type: TransferInspectionTypes

  date: DateTime | null

  dropboxLink: string

  descriptionFields: ApartmentDescriptionParams | null

  metersInfo: InspectionPartialMetersInfoParams

  apartmentStore: ApartmentPageStore

  roomsImages: RoomImagesParams[]

  fillingList: FillingParams[]

  inspectionStore: InspectionPageStore

  impairmentsInspectionId: number | null

  appraisalInspectionId: number | null

  constructor(
    inspection: TransferInspectionModel,
    inspectionStore: InspectionPageStore,
    apartmentStore: ApartmentPageStore
  ) {
    const inspectionFields = inspection.transfer
    this.apartmentStore = apartmentStore
    this.descriptionFields =
      this.apartmentStore.getApartmentDescriptionInfo(inspectionFields)
    this.metersInfo = TransferInspectionStore.getTransferMetersInfo(
      inspectionFields.meters
    )
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.roomsImages = InspectionPageStore.getObjectRoomsImages(
      inspection.photo_groups
    )
    this.fillingList =
      inspectionFields?.filling.map((filling) =>
        InventoryInspectionStore.getInspectionFillingParams(filling)
      ) || []
    this.inspectionStore = inspectionStore
    this.type = inspectionFields.transfer_type
    this.dropboxLink = inspection.dropbox_link || ""
    this.impairmentsInspectionId = inspection.impairment_id || null
    this.appraisalInspectionId = inspection.appraisal_id || null
    makeAutoObservable(this)
  }

  patchInspection = async (data: TransferInspectionInfoFields) => {
    const fillingFiles =
      data.fillingList?.map((filling) => filling.instruction) || []
    const fillingImages =
      data.fillingList?.map((filling) => filling.images || []) || []
    const metersImages = data.meters?.map((meter) => meter.images || []) || []

    const files = await callPromises([
      this.inspectionStore.actualizeFiles(fillingFiles),
      this.inspectionStore.actualizeImages(fillingImages),
      this.inspectionStore.actualizeImages(metersImages),
      this.inspectionStore.actualizeRoomsImages(data.roomsImages),
    ])

    return TransferInspectionStore.getPatchInspectionBody(
      data,
      files[0],
      files[1],
      files[2],
      files[3]
    )
  }

  static getPatchInspectionBody = (
    inspection: TransferInspectionInfoFields,
    fillingFiles: FileBodyRequest[],
    fillingImages: ImageBodyRequest[][],
    metersImages: ImageBodyRequest[][],
    roomsImages: PatchRoomImagesParams[]
  ) => ({
    dropbox_link: inspection.dropboxLink,
    photo_groups: roomsImages,
    transfer: {
      ...ApartmentPageStore.getPatchApartmentBody(inspection),
      ...TransferInspectionStore.getInspectionMetersPatchFields(
        inspection.meters,
        metersImages
      ),
      filling: InventoryInspectionStore.getInspectionFillingBody(
        inspection.fillingList,
        fillingFiles,
        fillingImages
      ),
    },
  })

  static getTransferMetersInfo = (
    meters: TransferInspectionMeterModel[]
  ): InspectionPartialMetersInfoParams => ({
    meters: meters.map((meter) => ({
      id: meter.id,
      type: meter.meter_type,
      number: meter.passport_number,
      resource: meter.resource_type,
      images:
        meter.photos?.map((photo) => ImageStore.initFromImageModel(photo)) ||
        [],
      ...TransferInspectionStore.getMeterValues(meter, meter.resource_type),
    })),
  })

  static getMeterValues = (
    meter: TransferInspectionMeterModel | InventoryInspectionMeterModel,
    resource: ResourceTypes,
    fieldName?: string
  ) => {
    if (resource === "electricity") {
      const prefix = fieldName || ""
      return {
        [`${prefix}T1`]: Number(meter.values.T1),
        ...(meter.meter_type !== "T1" && {
          [`${prefix}T2`]: Number(meter.values.T2),
        }),
        ...(meter.meter_type === "T3" && {
          [`${prefix}T3`]: Number(meter.values.T3),
        }),
      }
    }
    const valueField = fieldName || "value"
    if (resource === "water") {
      if (meter.meter_type === "cold") {
        return { [`${valueField}`]: Number(meter.values.cold) }
      }
      return { [`${valueField}`]: Number(meter.values.hot) }
    }
    return {
      [`${valueField}`]: Number(meter.values[resource]),
    }
  }

  static getInspectionMeterValues = (meter: InspectionMeterFields) => ({
    values: clearNotValidFields({
      ...ApartmentInspectionsLiteStore.getMeterValues(
        meter.initialValue,
        meter.lastValue
      ),
      T1: ApartmentInspectionsLiteStore.getMeterValues(
        meter.initialValueT1,
        meter.lastValueT1
      ),
      T2: ApartmentInspectionsLiteStore.getMeterValues(
        meter.initialValueT2,
        meter.lastValueT2
      ),
      T3: ApartmentInspectionsLiteStore.getMeterValues(
        meter.initialValueT3,
        meter.lastValueT3
      ),
    }),
  })

  static getInspectionMetersPatchFields = (
    meters: InspectionMeterFields[],
    metersImages: ImageBodyRequest[][]
  ) => ({
    meters: meters.map((meter, index) => ({
      ...inspectionsRequestBody.getMeterCommonFields(meter),
      photos: metersImages[index],
      values: clearNotValidFields(
        inspectionsRequestBody.getInspectionMeterValues(meter)
      ),
    })),
  })
}

export default TransferInspectionStore

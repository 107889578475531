import React from "react"
import { UploadFillingPhotoProps } from "./types"
import UploadFilesForm from "../../../../../components/forms/common/UploadFilesForm"

function UploadFillingPhoto(props: UploadFillingPhotoProps) {
  const { formMaker, handleSubmit } = props

  return (
    <UploadFilesForm
      name="images"
      label="Add filling photo"
      onPrev={formMaker?.prevStep}
      handleSubmit={handleSubmit}
      defaultValues={formMaker?.fields?.images || []}
      required={false}
      variant="images"
    />
  )
}

export default UploadFillingPhoto
